export const environment = {    
    production: false,
    staging: false,
    urlApiBase: "https://fashion.clusterdev.app2.one/",
    urlApiAuth: "https://api.fashion.clusterdev.app2.one/a2o-fashion-api-auth/",
    urlApiInventory: "https://api.fashion.clusterdev.app2.one/a2o-fashion-api-inventory/",
    //urlApiInventory: "https://localhost:49246/a2o-fashion-api-inventory/",
    urlApiOrchestratorCheckout: "https://api.fashion.clusterdev.app2.one/a2o-fashion-api-orchestrator-checkout/",
    urlApiBusinessUser: "https://api.fashion.clusterdev.app2.one/a2o-fashion-api-business-user/",
    //urlApiBusinessUser: "https://localhost:56694/",
    urlApiCheckout: "https://api.fashion.clusterdev.app2.one/a2o-fashion-api-checkout/",
    //urlApiCheckout: "https://localhost:62581/"
  };