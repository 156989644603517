<app-menu></app-menu>
<app-search-bar></app-search-bar>
<div class="container">
    <div class="row">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">       
            <div class="row">            
                <h1 class="col-12 col-md-6 title-default">usuário</h1>                
                <div class = "col-12 col-md-6 title-buttons">   
                    @if (isFirstLoading == false) {
                        <button type="submit" mat-flat-button class="btn-primary">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Salvar usuário
                            }
                        </button>
                        @if(_idUser){
                            <button type="button" mat-flat-button class="btn-secundary" (click)="openModalToConfirmDelete()">
                                @if (isLoading == true) {
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                }
                                @else{
                                    Excluir usuário
                                }
                            </button>
                        }
                        <button type="button" mat-flat-button class="btn-secundary" (click)="backToUserList()">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Voltar
                            }
                        </button>
                       
                    }                    
                </div>                
            </div>
            @if (isFirstLoading == false) {
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="name" type="text">
                            @if (model.get('name')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }  
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                            @if (model.get('cpf')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }                        
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email">
                            @if (model.get('email')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }                        
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                            @if (model.get('phone')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }                        
                        </mat-form-field>
                    </div>                    
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Senha</mat-label>
                            <mat-icon matSuffix (click)="_hidePassword = !_hidePassword">{{_hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <input matInput [type]="_hidePassword ? 'password' :'text'" formControlName="temporaryPassword">
                            @if (model.get('temporaryPassword')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            } 
                        </mat-form-field>
                    </div>
                </div>
            }
            @else {
                <div class="row">
                    @for (item of _arrayLoadingSearch; track $index) {
                        <div class="col-12 col-sm-6">
                            <app-loading-box [height]="'25'" [width]="'100%'" [marginTop]="'0'"></app-loading-box>
                        </div>    
                    }                                    
                </div>
            }  
            <div class="row">
                <h1 class="col-12 title-default">Perfis de acesso</h1>        
                @if(isFirstLoading == false) {
                    <div class="row" formArrayName="profiles">
                        @for (profile of model.get('profiles')?.value; track $index) {
                            <div class="col-12" [formGroupName]="$index">
                                <mat-checkbox formControlName="isChecked">
                                    <div class="check-box-text">
                                        <p class="check-box-text-primary">{{ profile.profileName }}</p>     
                                        <p class="check-box-text-secondary">{{ profile.profileDescription }}</p>     
                                    </div>
                                </mat-checkbox>                              
                            </div>
                        }
                    </div> 
                }
                @else {
                    <div class="row">
                        @for (item of _arrayLoadingSearch; track $index) {
                            <div class="col-12">
                                <app-loading-box [height]="'25'" [width]="'100%'" [marginTop]="'0'"></app-loading-box>
                            </div>    
                        }                                    
                    </div>
                }
            </div>                    
        </form>
    </div>  
</div>