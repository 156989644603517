import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { FactoryConsultorResponse } from '../../responses/business-user/factory-consultor.response';
import { ReturnStruct } from '../../structs/return.struct';
import { SearchConsultorsByFactoryRequest } from '../../requests/business-user/search-consultors-by-factory.request';
import { SearchConsultorsByFactoryResponse } from '../../responses/business-user/search-consultors-by-factory.esponse';
import { CreateInviteFactoryToConsultorRequest } from '../../requests/business-user/create-invite-factory-to-consultor.request';
import { UpdateInviteFactoryConsultorRequest } from '../../requests/business-user/update-invite-factory-consultor.request';
import { InviteFactoryConsultorConnectionResponse } from '../../responses/business-user/invite-factory-consultor-connection.response';
import { CreateAndUpdateUserRequest } from '../../requests/business-user/create-and-update-user.request';
import { ResponseToInviteFactoryConsultorConnectionRequest } from '../../requests/business-user/response-to-invite-factory-consultor-connection.request';

@Injectable({
  providedIn: 'root'
})
export class BusinessConsultorService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public getFactoryConsultor(idFactoryConsultor: number): Observable<FactoryConsultorResponse> {
    let url: string = `BusinessConsultor/GetFactoryConsultor/idFactoryConsultor/${idFactoryConsultor}`;

    return this.httpClient.get<FactoryConsultorResponse>(environment.urlApiBusinessUser + url, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAllInvitesReceivedByConsultor(): Observable<InviteFactoryConsultorConnectionResponse> {
    let url: string = `BusinessConsultor/GetAllInvitesReceivedByConsultor`;

    return this.httpClient.get<InviteFactoryConsultorConnectionResponse>(environment.urlApiBusinessUser + url, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchPagedForConsultorsByFactory(request: SearchConsultorsByFactoryRequest): Observable<SearchConsultorsByFactoryResponse> {
    return this.httpClient.post<SearchConsultorsByFactoryResponse>(environment.urlApiBusinessUser + "BusinessConsultor/SearchPagedForConsultorsByFactory", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createInviteFactoryToConsultor(request: CreateInviteFactoryToConsultorRequest): Observable<FactoryConsultorResponse> {
    return this.httpClient.post<FactoryConsultorResponse>(environment.urlApiBusinessUser + "BusinessConsultor/CreateInviteFactoryToConsultor", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public sendConsultorResponseToInviteFactoryConsultor(request: ResponseToInviteFactoryConsultorConnectionRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessUser + "BusinessConsultor/SendConsultorResponseToInviteFactoryConsultor", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateInviteFactoryConsultor(request: UpdateInviteFactoryConsultorRequest): Observable<FactoryConsultorResponse> {
    return this.httpClient.put<FactoryConsultorResponse>(environment.urlApiBusinessUser + "BusinessConsultor/updateInviteFactoryConsultor", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteFactoryConsultor(idFactoryConsultor: number): Observable<ReturnStruct> {
    let url: string = `BusinessConsultor/DeleteFactoryConsultor/idFactoryConsultor/${idFactoryConsultor}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBusinessUser + url, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}
