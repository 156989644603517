import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { LoadingBoxComponent } from '../../../../shared/component/loading-box/loading-box.component';
import { EmptyListComponent } from '../../../../shared/component/empty-list/empty-list.component';
import { MatSelectModule } from '@angular/material/select';
import { BusinessCheckoutStatusService } from '../../../../shared/services/API/checkout/business-checkout-status.service';
import { BusinessCheckoutStatus } from '../../../../shared/services/models/checkout/business-checkout-status.model';
import { GetLookupBusinessCheckoutStatusResponse } from '../../../../shared/services/responses/checkout/get-lookup-business-checkout-status.response';
import { SearchBusinessCheckoutPaginatedByFactoryAndConsultorRequest } from '../../../../shared/services/requests/checkout/search-business-checkout-paginated-by-factory-and-consultor.request';
import { SearchBusinessCheckoutPaginatedByFactoryAndConsultorResponse } from '../../../../shared/services/responses/checkout/search-business-checkout-paginated-by-factory-and-consultor.response';
import { BusinessCheckoutToCardListPageStruct } from '../../../../shared/services/structs/checkout/business-checkout-to-card-list-page.struct';
import { BusinessCheckoutService } from '../../../../shared/services/API/checkout/business-checkout.service';

@Component({
  selector: 'app-business-factory-checkout-history',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
    MatSelectModule,
  ],
  templateUrl: './business-factory-checkout-history.component.html',
  styleUrl: './business-factory-checkout-history.component.css'
})

export class BusinessFactoryCheckoutHistoryComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private businessCheckoutService: BusinessCheckoutService,
    public dialog: MatDialog,
    public authService: AuthService,
    public businessCheckoutStatusService: BusinessCheckoutStatusService) {
  }

  public model: FormGroup;
  public isLoading: boolean = false;
  public _page: number = 0;
  public _listBusinessCheckoutStatus: BusinessCheckoutStatus[] = [];
  public _listBusinessCheckoutToCardListPageStruct: BusinessCheckoutToCardListPageStruct[];
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1];
  public _isLastPage: boolean = false;
  public _idBusinessFactory: number;

  ngOnInit(): void {
    this.createDefaultInstanceOfSearchForm();

    this.tryGetUrlIdBusinessFactory();

    this.getAllBusinessCheckoutStatus();

    this.clickSearch();

  }

  createDefaultInstanceOfSearchForm(): void {
    this.model = this.formBuilder.group({
      businessStoreName: [''],
      idBusinessCheckoutStatus: [null],
      idCheckout: [null],
    });
  }

  tryGetUrlIdBusinessFactory(): void {
    let idBusinessFactoryUrl: string = this.activatedRoute.snapshot.params["idBusinessFactory"];

    if (idBusinessFactoryUrl)
      this._idBusinessFactory = Number(idBusinessFactoryUrl);
  }

  getAllBusinessCheckoutStatus() {
    this.businessCheckoutStatusService.GetAllBusinessCheckoutStatus().subscribe({
      next: (response: GetLookupBusinessCheckoutStatusResponse) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        if (response.listBusinessCheckoutStatus?.length > 0)
          this._listBusinessCheckoutStatus = [...response.listBusinessCheckoutStatus]

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  search() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: SearchBusinessCheckoutPaginatedByFactoryAndConsultorRequest = new SearchBusinessCheckoutPaginatedByFactoryAndConsultorRequest();
    request.idCheckout = Number(this.model.get('idCheckout')?.value);
    request.idBusinessCheckoutStatus = this.model.get('idBusinessCheckoutStatus')?.value;
    request.businessStoreName = this.model.get('businessStoreName')?.value;
    request.idBusinessFactory = this._idBusinessFactory;
    request.page = this._page;

    this.businessCheckoutService.SearchBusinessCheckoutPaginatedByFactoryAndConsultor(request).subscribe({
      next: (response: SearchBusinessCheckoutPaginatedByFactoryAndConsultorResponse) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        this._isLastPage = true;

        if (this._page == 0) {
          this._listBusinessCheckoutToCardListPageStruct = [];
        }

        if (response.listBusinessCheckoutToCardListPageStruct?.length > 0) {
          this._listBusinessCheckoutToCardListPageStruct.push(...response.listBusinessCheckoutToCardListPageStruct);
          this._isLastPage = false;
        }

        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickSearch() {
    this._page = 0;
    this.search();
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }

}

