import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FactoryInventoryProductResponse } from '../../../../shared/services/responses/inventory/factory-inventory-product.response';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmModalComponent } from '../../../../shared/component/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EmptyListComponent } from "../../../../shared/component/empty-list/empty-list.component";
import { ProductImageService } from '../../../../shared/services/API/inventory/product-image.service';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { NewProductImageRequest } from '../../../../shared/services/requests/inventory/new-product-image-request';
import { ProductImageRequestStruct } from '../../../../shared/services/structs/inventory/product-image-request.struct';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductImageStruct } from '../../../../shared/services/structs/inventory/product-image.struct';

@Component({
  selector: 'app-section-product-images',
  standalone: true,
  imports: [
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    EmptyListComponent,
    MatProgressSpinnerModule
],
  templateUrl: './section-product-images.component.html',
  styleUrl: './section-product-images.component.css'
})
export class SectionProductImagesComponent {
  @Input() _factoryInventoryProductResponse:FactoryInventoryProductResponse;
  @Output() reloadProduct =  new EventEmitter<any>()
  public isLoading:boolean = false; 

  constructor(public dialog: MatDialog,
    public productImageService:ProductImageService,
    private alertService:AlertService
  ){
  }

  deleteImg(productImageStruct:ProductImageStruct){
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Atenção",
        description: "Deseja realmente excluir essa imagem? Essa ação será realizada agora e será irreversível.",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm){
          productImageStruct.isLoading = true;
          this.productImageService.delete(productImageStruct.idProductImage).subscribe({
            next:(returnStruct:ReturnStruct)=>{
              productImageStruct.isLoading = false;
              if (returnStruct.isError) { 
                this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
                return;
              }

              this.reloadProduct.emit();
                
            },
            error: (error)=>{
              productImageStruct.isLoading = false;
              this.alertService.show("Erro inesperado", error, AlertType.error);
            }
          });
        }
      }
    })
  }

  onFileSelected(event:any) {
    if(!event.target.files || this.isLoading)
      return;

    this.isLoading = true;

    let  newProductImageRequest:NewProductImageRequest = new NewProductImageRequest();
    newProductImageRequest.listProductImage = [];
    newProductImageRequest.idProduct = this._factoryInventoryProductResponse.idProduct;
   
    for (let index = 0; index < event.target.files.length; index++) {
      
      const file:File = event.target.files[index];
      let fileExtension = file.name.split(".")?.pop()?.toLowerCase();

      if(fileExtension != "jpg" 
        && fileExtension != "png"
        && fileExtension != "jpeg"
        && fileExtension != "gif"
        && fileExtension != "bmp"
        && fileExtension != "tif"){
          this.alertService.show("Erro no upload", "Um ou mais arquivos possuem uma extenção não valida.", AlertType.error);
          this.isLoading = false;
          return;
        }

      let productImageRequestStruct:ProductImageRequestStruct = new ProductImageRequestStruct();    
      
      const reader = new FileReader();
      reader.onload = () => {
        productImageRequestStruct.base64 = (reader.result as string).split("base64,", 2)[1];
        newProductImageRequest.listProductImage.push(productImageRequestStruct);
      }
      reader.readAsDataURL(file);
    }
    console.log(newProductImageRequest);
      setTimeout(() => {
        this.productImageService.post(newProductImageRequest).subscribe({
          next:(returnStruct:ReturnStruct)=>{
            this.isLoading = false;
            if (returnStruct.isError) { 
              this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
              return;
            }

            this.reloadProduct.emit();
          },
          error: (error)=>{
            this.isLoading = false;
            this.alertService.show("Erro inesperado", error, AlertType.error);
          }
        });
      }, 1000);
    }

  openModalAddImg(){

  }
}
