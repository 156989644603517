<div class="container container-auth"> 
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <form class="form" [formGroup]="model"  (ngSubmit)="updateUserForFirstLogin()">
                <img class="logo-auth" src="assets/images/fashion.app2one.png">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput formControlName="name" type="string">
                    @if (model.get('name')?.invalid) {
                        <mat-error>Informe o seu nome</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Nome da sua loja</mat-label>
                    <input matInput formControlName="businessStoreName" type="string">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="login" type="email">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Crie uma senha</mat-label>
                    <input matInput [type]="hidePassword() ? 'password' : 'text'" formControlName="password" >
                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="clickEventHidePassword($event)"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hidePassword()"
                        >
                        <mat-icon>{{hidePassword() ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    @if (model.get('password')?.invalid) {
                        <mat-error>Informe a sua senha</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Repita a senha</mat-label>
                    <input matInput [type]="hidePasswordConfirm() ? 'password' : 'text'" formControlName="passwordConfirm" >
                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="clickEventHidePasswordConfirm($event)"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hidePasswordConfirm()"
                        >
                        <mat-icon>{{hidePasswordConfirm() ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    @if (model.get('password')?.invalid) {
                        <mat-error>Confirm a sua senha</mat-error>
                    }
                </mat-form-field>
                <button type="submit" mat-flat-button class="btn-primary button-big button-login">
                    @if (isLoading == true) {
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                        Criar senha
                    }
                </button>
                <button type="button" mat-button class="button-big button-login" (click)="backToAuthLoginPage()">Voltar para o login</button>
            </form>
        </div>
    </div>
  </div>