import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { InviteBusinessStoreRequest } from '../../requests/business-user/invite-business-store.request';
import { InviteBusinessStoreResponse } from '../../responses/business-user/invite-business-store.response';
import { SearchUserStructToPageListRequest } from '../../requests/business-user/search-user-struct-to-page-list.request';
import { UserToPageListResponse } from '../../responses/business-user/user-to-page-list.response';
import { UserResponse } from '../../responses/business-user/user.response';
import { ReturnStruct } from '../../structs/return.struct';
import { CreateAndUpdateUserRequest } from '../../requests/business-user/create-and-update-user.request';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public inviteBusinessStore(inviteBusinessStoreRequest:InviteBusinessStoreRequest): Observable<InviteBusinessStoreResponse> {
    return this.httpClient.post<InviteBusinessStoreResponse>(environment.urlApiBusinessUser + "User/InviteBusinessStore", inviteBusinessStoreRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public searchUsersToPagedCardList(searchUserStructToPageListRequest:SearchUserStructToPageListRequest): Observable<UserToPageListResponse> {
    return this.httpClient.post<UserToPageListResponse>(environment.urlApiBusinessUser + "User/searchUsersToPagedCardList", searchUserStructToPageListRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getUserToRegisterPage(idUser:number): Observable<UserResponse> {
    return this.httpClient.get<UserResponse>(environment.urlApiBusinessUser + "User/getUserToRegisterPage/idUser/" + idUser, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public postUser(request: CreateAndUpdateUserRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessUser + "User/createUser", request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public putUser(request: CreateAndUpdateUserRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessUser + "User/updateUser", request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteUser(idUser:number): Observable<ReturnStruct> {
    let url: string = `User/idUser/${idUser}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBusinessUser + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
