
export class ProductBaseRequest {
  public idProduct: number | null;
  public productName: string;
  public productCode: string;
  public htmlDescription: string;
  public basePrice: number | null;
  public isImmediateDelivery: boolean;
  public idCollection: number | null;
  public idCategory: number | null;
}
