<app-menu></app-menu>
<app-search-bar></app-search-bar>
<div class="container">
    <h1 class="title-default">Meus usuarios</h1>

    <a mat-button class="btn-primary" [routerLink]="['/factory/user/register']">
        <span class="material-symbols-outlined">add</span>
        Novo usuário
    </a>

    <div class="search-area">
        <form class="form" [formGroup]="model" (ngSubmit)="clickSearch()" class="text-center see-more">
            <div class="row search-area-itens">
                <div class="col-12 col-sm-6 col-md-5">
                    <mat-form-field appearance="outline">
                        <mat-label>Pesquisar usuário</mat-label>
                        <input matInput formControlName="searchText" type="text">
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-4 col-md-3">
                    <button type="submit" mat-flat-button class="btn-secundary">
                        @if (isLoading == true) {
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                            <span class="material-symbols-outlined">search</span>
                            Encontrar usuário
                        }
                    </button>
                </div>

            </div>
        </form>
    </div>

    <div class="card-list">
        @if(_listUserToPagedCardListStruct != undefined) {
            <div class="row">
                @for (item of _listUserToPagedCardListStruct; track $index) {
                    <div class="col-12 col-md-6">
                        <div class="card-item">
                            <div class="infos">
                                <span class="title"> {{item.name}}</span>
                                <span class="title-4"><b>E-mail: </b> {{item.email}}</span>
                                <span class="title-4"><b>Telefone: </b>{{item.phone}}
                                    @if(item.phone &&
                                    item.phone.trim() != ""){
                                        <img src="assets/images/whatsAppIcon.png" />
                                    }
                                </span>
                            </div>

                            <div class="actions">
                                <a [routerLink]="['/factory/user/register', {idUser: item.idUser}]">
                                    <span class="material-symbols-outlined" aria-hidden="false" aria-label="Editar">
                                        arrow_forward
                                    </span>
                                </a>
                            </div>
                            <div class="tags">
                                @for (itemTag of item.listProfileName; track $index) {                               
                                    <div class="tag-gray">
                                        {{itemTag}} 
                                    </div>
                                } 
                            </div>                                                      
                        </div>
                    </div>
                }

                @if(_isLastPage == false && _listUserToPagedCardListStruct.length > 0) {                    
                    <div class="col-12 text-center see-more">
                        <button mat-button class="btn-primary" (click)="nextPage()">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                <span class="material-symbols-outlined">keyboard_arrow_down</span>
                                Ver mais
                            }
                        </button>
                    </div>
                }

                @if( _listUserToPagedCardListStruct.length == 0){
                    <app-empty-list [title]="'Não encontramos nenhum usuário.'"
                        [subTitle]="'Clique em novo usuário e crie um novo.'"></app-empty-list>
                }
            </div>            
        }
        @else {
            <div class="row">
                @for (item of _arrayLoadingSearch; track $index) {
                    <div class="col-12 col-sm-6">
                        <div class="card-item">
                            <div class="infos">
                                <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'0'"></app-loading-box>
                                <app-loading-box [height]="'20'" [width]="'250'" [marginTop]="'10'"></app-loading-box>
                                <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
                            </div>
                            <div class="actions">

                            </div>
                            <div class="tags">
                                <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'8'"></app-loading-box>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }        
    </div>
</div>