@if(showActionInvite){
    <div class="container-item">     
        <div class="card-list">
            @if(_listInviteFactoryConsultorConnectionStruct != undefined) {
                @for (item of _listInviteFactoryConsultorConnectionStruct; track $index) {
                    <div class="card-item">
                        <div class="infos">
                            <span class="title"> {{item.name}}</span>
                            <span class="title-4"><b>Comissão: </b> {{ (item.consultorComission * 100) | number:'1.2-2' }}% de comissão</span>
                            <span class="title-4"><b>Endereço: </b> {{item.street}}, {{item.addressNumber}}</span>
                            <span class="title-4"><b>Cidade: </b> {{item.city}}/{{item.state}}</span>
                            <span class="title-4"><b>Telefone: </b>{{item.phone}}</span>
                        </div>
                        <div class="actions">
                            <button type="button" mat-flat-button class="btn-primary" (click)="openModalToConfirmTheConnectionResponse(true, item)">                                
                                Aceitar                               
                            </button>
                            <button type="button" mat-flat-button class="btn-secundary" (click)="openModalToConfirmTheConnectionResponse(false, item)">                                
                                Recusar                                
                            </button>
                        </div>
                    </div>
                }

                @if( _listInviteFactoryConsultorConnectionStruct.length == 0){
                   <div class="empty">
                    <img src="assets/images/images-notificacao.png">
                        <h1>Você não possui nenhuma notificação agora.</h1>
                    </div>
                }           
            }
            @else {
                <div class="row">
                    @for (item of _arrayLoadingSearch; track $index) {
                        <div class="col-12">
                            <div class="card-item">
                                <div class="infos">
                                    <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'0'"></app-loading-box>
                                    <app-loading-box [height]="'20'" [width]="'250'" [marginTop]="'10'"></app-loading-box>
                                    <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }        
        </div>
    </div>
    <div class="container-out" (click)="clickContainerOut()"></div>
}
