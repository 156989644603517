<app-menu></app-menu>
<div class="container container-done"> 
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <div class="row">
                <div class="col-sm-6">
                    <div class="vertical-aling-out">
                        <div class="vertical-aling-inner">
                            <img class="logo-auth" src="assets/images/fashion.app2one.png">
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                   <h1>Uhuu!</h1>
                   <h2>Seu pedido #{{idCheckout}} foi enviado para a Margarete da silva</h2>
                   <p>A Margarete ja recebeu o link no seu WhatsApp e e-mail, você ja pode comunicar ela sobre suas escolha de compra. </p>
                   <a routerLink="/consult/checkout-details/{{idCheckout}}">Clique aqui para acompanhar seu pedido</a>
                </div>
            </div>
        </div>
    </div>
  </div>