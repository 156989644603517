import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { MyCheckoutService } from '../../../shared/services/API/checkout/my-checkout.service';
import { SearchCheckoutRequest } from '../../../shared/services/requests/checkout/search-checkout.request';
import { MyCheckoutSearchResponse } from '../../../shared/services/responses/checkout/my-checkout-search.response';
import { LoadingBoxComponent } from "../../../shared/component/loading-box/loading-box.component";
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from "../../../shared/component/empty-list/empty-list.component";
import { ModalAddressComponent } from '../search-resume-checkout/modal-address/modal-address.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-checkout-list',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterModule
],
  templateUrl: './checkout-list.component.html',
  styleUrl: './checkout-list.component.css'
})
export class CheckoutListComponent implements OnInit{
  constructor(  
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private myCheckoutService:MyCheckoutService,
    public dialog: MatDialog,){
  }

  public model: FormGroup;
  public isLoading:boolean = false;
  public _page:number = 0;
  public _myCheckoutSearchResponse:MyCheckoutSearchResponse;
  public _arrayLoadingSearch:number[] =  [1, 1, 1, 1, 1, 1];
  public _isLastPage:boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idCheckout: [''],
      storeName: [''],
      storeOwnerName: [''],
      idCheckoutStatus: [''],
    });

    this.clickSearch();

  }

  search(){
    let searchCheckoutRequest:SearchCheckoutRequest = new SearchCheckoutRequest();

    searchCheckoutRequest.idCheckout = Number(this.model.get('idCheckout')?.value)
    searchCheckoutRequest.idCheckoutStatus =  Number(this.model.get('idCheckoutStatus')?.value)
    searchCheckoutRequest.storeName = this.model.get('storeName')?.value
    searchCheckoutRequest.storeOwnerName = this.model.get('storeOwnerName')?.value
    searchCheckoutRequest.page = this._page;

    if(searchCheckoutRequest.idCheckout == 0)
      searchCheckoutRequest.idCheckout = null;

    if(searchCheckoutRequest.idCheckoutStatus == 0)
      searchCheckoutRequest.idCheckoutStatus = null

    this.myCheckoutService.search(searchCheckoutRequest).subscribe({
      next:(myCheckoutSearchResponse:MyCheckoutSearchResponse)=>{
        if (myCheckoutSearchResponse.isError) {
          this.alertService.show("Erro inesperado", myCheckoutSearchResponse.errorDescription, AlertType.error);
          return;
        }

        //Se for a primeira pesquisa
        if(this._myCheckoutSearchResponse == undefined)
          this._myCheckoutSearchResponse = myCheckoutSearchResponse;
        else{
          //As demais paginas
          if(myCheckoutSearchResponse.listMyCheckoutSearch.length > 0)
            this._myCheckoutSearchResponse.listMyCheckoutSearch.push(...myCheckoutSearchResponse.listMyCheckoutSearch);
          else
            this._isLastPage = true;
        }
          
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickSearch(){
    this._page = 0;
    this.search();
  }

  nextPage(){
    this._page = this._page + 1;
    this.search();
  }
}
