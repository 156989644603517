import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CheckoutItemResumeComponent } from "./modal-order-checkout-item-resume/modal-order-checkout-item-resume.component";
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { FactoryOrderService } from '../../../../shared/services/API/orchestator-checkout/factory-order.service';
import { FactoryOrderDetailsResponse } from '../../../../shared/services/responses/orchestrator-checkout/factory-order-details.response';
import { LoadingBoxComponent } from "../../../../shared/component/loading-box/loading-box.component";
import { CommonModule } from '@angular/common';
import { OrderStatusBulletComponent } from '../order-status-bullet/order-status-bullet.component';
import { BusinessCheckoutStatusEnum } from '../../../../shared/enum/business-checkout-status.enum';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CheckoutBusinessStatusService } from '../../../../shared/services/API/checkout/checkout-business-status.service';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';

@Component({
  selector: 'app-modal-order',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    CheckoutItemResumeComponent,
    LoadingBoxComponent,
    CommonModule,
    OrderStatusBulletComponent,
    MatProgressSpinnerModule
],
  templateUrl: './modal-order.component.html',
  styleUrl: './modal-order.component.css'
})
export class ModalOrderComponent implements OnInit{
  
  public _factoryOrderDetailsResponse:FactoryOrderDetailsResponse | undefined;
  public _idBusinessCheckout:number;
  public _isLoading:boolean = false;
  public _isLoadingCancel:boolean = false;
  public businessCheckoutStatusEnumAguardandoSeparacao:number = BusinessCheckoutStatusEnum.AguardandoSeparacao;
  public businessCheckoutStatusEnumEmSeparacao:number = BusinessCheckoutStatusEnum.EmSeparacao; 
  public businessCheckoutStatusEnumProntoParaColeta:number = BusinessCheckoutStatusEnum.ProntoParaColeta;
  public businessCheckoutStatusEnumCancelado:number = BusinessCheckoutStatusEnum.Cancelado;

  constructor(
    public factoryOrderService:FactoryOrderService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ModalOrderComponent>,
    public checkoutBusinessStatusService:CheckoutBusinessStatusService,
    @Inject(MAT_DIALOG_DATA) public data: any){
      this._idBusinessCheckout = data.idBusinessCheckout;
  }

  ngOnInit(): void {
    this.data
    this.getOrder();  
  }

  getOrder(){
    this._factoryOrderDetailsResponse = undefined;
    this.factoryOrderService.details(this._idBusinessCheckout).subscribe({

      next:(factoryOrderDetailsResponse:FactoryOrderDetailsResponse)=>{
        if (factoryOrderDetailsResponse.isError) {
          this.alertService.show("Erro inesperado", factoryOrderDetailsResponse.errorDescription, AlertType.error);
          return;
        }
        
        this._factoryOrderDetailsResponse = factoryOrderDetailsResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  changeStatus(idBusinessCheckoutStatus:number){
    if(this._isLoadingCancel || this._isLoading)
      return;
    
    if(idBusinessCheckoutStatus == this.businessCheckoutStatusEnumCancelado){
      if(!confirm('Você realmente deseja cancelar este pedido?')){
        return;
      }
      this._isLoadingCancel = true;
    }
    else{
      this._isLoading = true;
    }

    if(this._factoryOrderDetailsResponse == undefined)
      return;

    this.checkoutBusinessStatusService.put(this._factoryOrderDetailsResponse.idBusinessCheckout, idBusinessCheckoutStatus).subscribe({
      next:(returnStruct:ReturnStruct)=>{
        this._isLoading = false;
        this._isLoadingCancel = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
          return;
        }
        
        this.dialogRef.close({
          reload:true
        });

      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }
}
