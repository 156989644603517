<div class="container">
    <div class="row">
        <form class="form" [formGroup]="model">
            <div class="row">
                <h1 class="col-12 col-md-6 title-default">Consultor</h1>
                <div class="col-12 col-md-6 title-buttons">
                    @if (isFirstLoading == false) {
                    <button type="button" mat-flat-button class="btn-secundary" (click)="openModalToInsertComissionAndUpdateInvite()">
                        @if (isLoading == true) {
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                        Personalizar comissão
                        }
                    </button>
                    <button type="button" mat-flat-button class="btn-secundary" (click)="openModalToConfirmTheCancelingConnection()">
                        @if (isLoading == true) {
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                        Cancelar conexão
                        }
                    </button>
                    <button type="button" mat-flat-button class="btn-primary"
                        (click)="backToBusinessConsultorByFactoryList()">
                        @if (isLoading == true) {
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                            Voltar
                        }
                    </button>
                    }
                </div>
            </div>
            @if (isFirstLoading == false) {
            <div class="row">
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome do consultor</mat-label>
                        <input readonly matInput formControlName="businessName" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>CNPJ</mat-label>
                        <input readonly matInput type="text" formControlName="cnpj" [mask]="masks.cnpj">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone</mat-label>
                        <input readonly matInput type="text" formControlName="businessPhone" [mask]="masks.phone">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input readonly matInput type="email" formControlName="businessEmail">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Comissão</mat-label>
                        <input readonly matInput type="text" formControlName="consultorComission">
                    </mat-form-field>
                </div>
            </div>
            }
            @else {
            <div class="row">
                @for (item of _arrayLoadingSearch; track $index) {
                <div class="col-12 col-sm-6">
                    <app-loading-box [height]="'25'" [width]="'100%'" [marginTop]="'0'"></app-loading-box>
                </div>
                }
            </div>
            }
            <div class="row">
                <h1 class="col-12 col-md-6 title-default">Endereço</h1>
            </div>
            @if (isFirstLoading == false) {
            <div class="row">
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>CEP</mat-label>
                        <input readonly matInput formControlName="zipcode" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>País</mat-label>
                        <input readonly matInput formControlName="country" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Estado</mat-label>
                        <input readonly matInput formControlName="state" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Cidade</mat-label>
                        <input readonly matInput formControlName="city" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Bairro</mat-label>
                        <input readonly matInput formControlName="neighborhood" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Logradouro</mat-label>
                        <input readonly matInput formControlName="street" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Número</mat-label>
                        <input readonly matInput formControlName="addressNumber" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Complemento</mat-label>
                        <input readonly matInput formControlName="complement" type="text">
                    </mat-form-field>
                </div>
            </div>
            }
            @else {
            <div class="row">
                @for (item of _arrayLoadingSearch; track $index) {
                <div class="col-12 col-sm-6">
                    <app-loading-box [height]="'25'" [width]="'100%'" [marginTop]="'0'"></app-loading-box>
                </div>
                }
            </div>
            }
        </form>
    </div>
</div>