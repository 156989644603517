import { ProductImageStruct } from "../../structs/inventory/product-image.struct";
import { ProductGridStruct } from "../../structs/inventory/productgrid.struct";
import { ReturnStruct } from "../../structs/return.struct";

export class FactoryInventoryProductResponse extends ReturnStruct {
  public idProduct: number;
  public productName: string;
  public productCode: string;
  public htmlDescription: string;
  public basePrice: number | null;
  public isImmediateDelivery: boolean;
  public isIntegration: boolean;
  public idCollection: number | null;
  public idCategory: number | null;
  public datetimeLastUpdate: Date | null;
  public listProductImageStruct: ProductImageStruct[];
  public listProductGridStruct: ProductGridStruct[];
}
