<h2 mat-dialog-title>Cadastrar transportadora deste pedido</h2>


<mat-dialog-content>
    <form class="form" [formGroup]="model"  (ngSubmit)="post()" style="margin-top: 15px;">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Transportadora</mat-label>
                    <mat-select matNativeControl  formControlName="idDeliveryProvider">
                        <!-- @for (item of _listCategoryModel; track $index) {
                            <mat-option value="{{item.idCategory}}">{{item.categoryName}}</mat-option>    
                        }    -->
                        <mat-option value="1">Correios - PAC</mat-option>
                        <mat-option value="2">Correios - Sedex</mat-option>
                        <mat-option value="3">Loggi</mat-option>
                        <mat-option value="4">Azul Delivery / Cargo</mat-option>
                        <mat-option value="5">Jadlog</mat-option>
                        <mat-option value="6">TNT</mat-option>
                        <mat-option value="7">LalaMove</mat-option>
                        <mat-option value="8">Braspress</mat-option>
                        <mat-option value="9">DHL</mat-option>
                        <mat-option value="10">FedEx</mat-option>
                        <mat-option value="11">Total Express</mat-option>
                        <mat-option value="12">Jamef</mat-option>
                        <mat-option value="999999">Outros</mat-option>
                    </mat-select>
                    @if (model.get('idDeliveryProvider')?.invalid) {
                        <mat-error>Informe a transportadora</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Preço da trasnportadora</mat-label>
                    <input matInput formControlName="deliveryPrice" type="number">
                    @if (model.get('deliveryPrice')?.invalid) {
                        <mat-error>Informe o preço da trasnportadora</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>URL de pesquisa</mat-label>
                    <input matInput formControlName="trackingUrl" type="text">
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Nome da entrega</mat-label>
                    <input matInput formControlName="deliveryName" type="text">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Código de rastreio</mat-label>
                    <input matInput formControlName="trackingCode" type="text">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Data estimada de chegada</mat-label>
                    <input matInput formControlName="estimatedDeliveryDateTime" type="text" [matDatepicker]="picker">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    @if (model.get('estimatedDeliveryDateTime')?.invalid) {
                        <mat-error>Informe a data estimada de chegada</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>


        <button mat-button class="btn-primary btn-send-link" type="submit">
            @if (isLoading == true) {
                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
            }
            @else{
                Cadastrar transportadora
            }
        </button>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    
</mat-dialog-actions>
