<app-menu></app-menu>
<div class="container">
    @if(_checkoutResponse != undefined){
        <div class="row">
        <div class="infos col-12 col-sm-8">
            <h1 class="title-default">Pedido #{{_checkoutResponse.checkout.idCheckout}}</h1>
            <span class="info-1">{{_checkoutResponse.totalPrice | currency:'BRL'}}</span>
            <span class="info-2">{{_checkoutResponse.checkoutStatusName}}</span>

        </div>
            @if(this.statusCheckout3 == null && this.statusCheckout4 == null && this.statusCheckout5 == null &&  this.statusCheckout6 == null && this.statusCheckout7 == null && this.statusCheckout8 == null)
            {
                <button mat-flat-button class="btn-primary button-big  col-12 col-sm-4" (click)="GoToResume()">Ir para pagamento</button>
            }
            @if(this.hasPixNotPayed)
            {
                <button mat-flat-button class="btn-primary button-big  col-12 col-sm-4" (click)="GoToPix()">Ver codigo pix</button>
            }
        </div>
        <div class="list-itens">
            @if(_checkoutResponse != undefined){
                <mat-accordion>
                    @for (businessCheckoutStruct of _checkoutResponse.listBusinessCheckoutStruct; track $index) {
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                            <mat-panel-title> {{businessCheckoutStruct.productBusinessName}} </mat-panel-title>
                            <mat-panel-description> 
                               
                                @if(businessCheckoutStruct.businessCheckoutDiscount){
                                    <span class="text-discount-strike">{{businessCheckoutStruct.totalPrice | currency:'BRL'}}</span>
                                    <span class="text-discount">{{businessCheckoutStruct.totalPrice - businessCheckoutStruct.businessCheckoutDiscount.discountValue | currency:'BRL'}}</span>
                                }
                                @else {
                                    {{businessCheckoutStruct.totalPrice | currency:'BRL'}}
                                }
                            </mat-panel-description>
                            </mat-expansion-panel-header>
                            @if(businessCheckoutStruct.businessCheckoutDiscount){
                                <p class="info-discount">
                                    Desconto de {{businessCheckoutStruct.businessCheckoutDiscount.discountValue | currency: 'BRL'}} aplicado
                                    <br />
                                    {{businessCheckoutStruct.businessCheckoutDiscount.discountMotive}}
                                </p>
                            }    
                            
                            @for (productCheckoutStruct of businessCheckoutStruct.listProductCheckoutStruct; track $index) {
                                <div class="checkout-item">
                                    <div class="row">
                                        <div class="col-3">
                                            <img class="image-item" src="{{productCheckoutStruct.productImageUrl}}" />
                                        </div>
                                        <div class="col-7">
                                            <label class="business-iten-price">{{productCheckoutStruct.productCheckout.totalPrice | currency: "BRL"}}</label>
                                            <label class="business-name">{{businessCheckoutStruct.productBusinessName}}</label>
                                            <label class="business-iten-name">{{productCheckoutStruct.productCheckout.productName}}</label>
                                            <label class="business-iten-count">{{productCheckoutStruct.productCheckout.totalQuantity}} peças</label>
                                            <label class="business-iten-count-description">2 cores escolhidas em 3 grades de tamanho</label>
                                        </div>
                                        <div class="col-2 text-center">
                                            <div class="vertical-aling-out">
                                                <div class="vertical-aling-inner">
                                                    <button mat-mini-fab class="fab-action orange" aria-label="Ver grade" (click)="openDalogDetails(productCheckoutStruct.productCheckout.idProduct)" >
                                                        <mat-icon>arrow_forward</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </mat-expansion-panel>
                    }
                </mat-accordion>
            }
            @else {
                <mat-accordion>
                    @for (item of returnArraySize(5); track $index) {
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>  
                                <app-loading-box [height]="'15'" [width]="'120'"></app-loading-box> 
                                </mat-panel-title>
                            <mat-panel-description>  
                                <app-loading-box [height]="'15'" [width]="'120'"></app-loading-box>
                            </mat-panel-description>
                            <mat-action-row>
                                <app-loading-box [height]="'25'" [width]="'120'"></app-loading-box>
                            </mat-action-row>
                            </mat-expansion-panel-header>
                            <p class="info-payment">
                                @for (avaliablePaymentMethod of item.listAvaliablePaymentMethod; track $index) {
                                    {{avaliablePaymentMethod.paymentMethodName}} 
                                    
                                    @if(avaliablePaymentMethod.maxInstallments){
                                        {{avaliablePaymentMethod.maxInstallments}}X
                                        
                                        @if(avaliablePaymentMethod.installmentsFees){
                                            ({{avaliablePaymentMethod.installmentsFees}}% a.m.)
                                        }
                                    }

                                    @if(avaliablePaymentMethod.paymentMethodDiscount){
                                        desconto de {{avaliablePaymentMethod.paymentMethodDiscount | number:'1.2-2'}}% 
                                    }

                                    @if(item.listAvaliablePaymentMethod.length-1 != $index){
                                        - 
                                    }
                                }
                                <!-- Cartão 6x (2,5% a.m.) PIX 6x (5% a.m.) -->
                            </p>
                        </mat-expansion-panel>
                    }
                </mat-accordion>
                
            }
        </div>

        <h1 class="title-default">Informações do pedido</h1>
        @if(_checkoutHistoryResponse != undefined){
            <app-checkout-status-to-store [currentStatus]="_checkoutResponse.checkout.idCheckoutStatus==1" [statusName]="'Pedido iniciado'" [statusDetails]="'Pedido iniciado'" [idStatusCheckoout]="1" [dateTimeInclusionStatus]="_checkoutResponse.checkout.datetimeInclusion" [isActive]="true"> </app-checkout-status-to-store>
            <app-checkout-status-to-store [currentStatus]="_checkoutResponse.checkout.idCheckoutStatus==2" [statusName]="'Aguardando Pagamento'" [statusDetails]="'Aguardando o pagamento'" [idStatusCheckoout]="2" [dateTimeInclusionStatus]="statusCheckout2?.dateTimeInclusion" [isActive]="statusCheckout2 != undefined"> </app-checkout-status-to-store>
            <app-checkout-status-to-store [currentStatus]="_checkoutResponse.checkout.idCheckoutStatus==3" [statusName]="'Processando pagamento'" [statusDetails]="'Processando o pagamento do pedido'" [idStatusCheckoout]="3" [dateTimeInclusionStatus]="statusCheckout3?.dateTimeInclusion" [isActive]="statusCheckout3 != undefined"> </app-checkout-status-to-store>
            <app-checkout-status-to-store [currentStatus]="_checkoutResponse.checkout.idCheckoutStatus==4" [statusName]="'Pagamento Aprovado'" [statusDetails]="'Todos os pagamentos deste pedido foram aprovados'" [idStatusCheckoout]="4" [dateTimeInclusionStatus]="statusCheckout4?.dateTimeInclusion" [isActive]="statusCheckout4 != undefined"> </app-checkout-status-to-store>
            <app-checkout-status-to-store [currentStatus]="_checkoutResponse.checkout.idCheckoutStatus==5" [statusName]="'Em separação'" [statusDetails]="'As confecções e a logística fashion.app2.One estão trabalhando para coletar todos os itens'" [idStatusCheckoout]="5" [dateTimeInclusionStatus]="statusCheckout5?.dateTimeInclusion" [isActive]="statusCheckout5 != undefined"> </app-checkout-status-to-store>
            <app-checkout-status-to-store [currentStatus]="_checkoutResponse.checkout.idCheckoutStatus==6" [statusName]="'Orçamento do frete'" [statusDetails]="'Aguardando orçamento do frete'" [idStatusCheckoout]="6" [dateTimeInclusionStatus]="statusCheckout6?.dateTimeInclusion" [isActive]="statusCheckout6 != undefined"> </app-checkout-status-to-store>
            <app-checkout-status-to-store [currentStatus]="_checkoutResponse.checkout.idCheckoutStatus==7" [statusName]="'Enviado'" [statusDetails]="'Pedido a caminho'" [idStatusCheckoout]="7" [dateTimeInclusionStatus]="statusCheckout7?.dateTimeInclusion" [isActive]="statusCheckout7 != undefined"> </app-checkout-status-to-store>
            <app-checkout-status-to-store [currentStatus]="_checkoutResponse.checkout.idCheckoutStatus==8" [statusName]="'Entregue'" [statusDetails]="'Pedido entregue ao destinatário'" [idStatusCheckoout]="8" [dateTimeInclusionStatus]="statusCheckout8?.dateTimeInclusion" [isActive]="statusCheckout8 != undefined"> </app-checkout-status-to-store>
        }

    } 
    @else { 
        <div class="infos">
            <app-loading-box [height]="'33'" [width]="'310'" [marginTop]="'10'"></app-loading-box>
            <app-loading-box [height]="'23'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
            <app-loading-box [height]="'23'" [width]="'210'" [marginTop]="'10'"></app-loading-box>
            <app-loading-box [height]="'23'" [width]="'120'" [marginTop]="'10'"></app-loading-box>

            <app-loading-box [height]="'23'" [width]="'500'" [marginTop]="'35'"></app-loading-box>
            <app-loading-box [height]="'23'" [width]="'500'" [marginTop]="'5'"></app-loading-box>
            <app-loading-box [height]="'23'" [width]="'500'" [marginTop]="'5'"></app-loading-box>
            <app-loading-box [height]="'23'" [width]="'500'" [marginTop]="'5'"></app-loading-box>

            <app-loading-box [height]="'33'" [width]="'310'" [marginTop]="'40'"></app-loading-box>
        
        </div>
    }

</div>
