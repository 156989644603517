import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CheckoutService } from '../../../../shared/services/API/checkout/checkout.service';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { UtilService } from '../../../../shared/services/util.service';
import { BusinessCheckoutStruct } from '../../../../shared/services/structs/orchestrator-checkout/business-checkout.struct';
import { CheckoutDiscountRequest } from '../../../../shared/services/requests/checkout/checkout-discount.request';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { get } from 'http';
import { BusinessCheckoutDiscount } from '../../../../shared/services/models/orchestrator-checkout/cusiness-checkout-discount.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-discount',
  standalone: true,
  imports: [MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    CommonModule],
  providers:[
    provideNgxMask()
  ],
  templateUrl: './modal-discount.component.html',
  styleUrl: './modal-discount.component.css'
})
export class ModalDiscountComponent implements OnInit{
  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private checkoutService:CheckoutService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ModalDiscountComponent>,
    public utilService:UtilService){

  }

  public model: FormGroup;
  public isLoading:boolean = false;
  public _idCheckout:number;
  public _totalPrice:number;
  public _businessCheckoutStruct:BusinessCheckoutStruct;

  ngOnInit(): void {
    this._idCheckout = this.data.idCheckout;
    this._businessCheckoutStruct = this.data.businessCheckoutStruct

    this.model = this.formBuilder.group({
      discountValue: [this._businessCheckoutStruct.businessCheckoutDiscount?.discountValue, [Validators.required]],
      discountMotive: [this._businessCheckoutStruct.businessCheckoutDiscount?.discountMotive, [Validators.required]],
      idBusinessChekout: [''],
    });
  }

  post(){
    if (this.model.invalid  || this.isLoading)
      return;

    let checkoutDiscountRequest:CheckoutDiscountRequest = new CheckoutDiscountRequest(); 
    checkoutDiscountRequest.discountMotive = this.model.get('discountMotive')?.value;
    checkoutDiscountRequest.discountValue = this.model.get('discountValue')?.value;
    checkoutDiscountRequest.idBusinessCheckout = this._businessCheckoutStruct.businessCheckout.idBusinessCheckout;
    checkoutDiscountRequest.idCheckout = this._idCheckout;

    this.isLoading = true;
    this.checkoutService.discount(checkoutDiscountRequest).subscribe({
      next:(returnStruct:ReturnStruct)=>{
        this.isLoading = false;

        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Disconto aplicado com sucesso", AlertType.success);
        this.dialogRef.close();
      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }
}
