<app-menu></app-menu>
<app-search-bar></app-search-bar>
<div class="container">
    <h1 class="title-default">Meus pedidos</h1>
    <a mat-button class="btn-primary">
        <span class="material-symbols-outlined">add</span>
        Novo pedido
    </a>
    <div class="search-area">
        <form class="form" [formGroup]="model"  (ngSubmit)="clickSearch()" class="text-center">
            <div class="row">            
                <div class="col-sm-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Nº do pedido</mat-label>
                        <input matInput formControlName="idCheckout" type="number">
                    </mat-form-field>
                </div>

                <div class="col-sm-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome da loja</mat-label>
                        <input matInput formControlName="storeName" type="text">
                    </mat-form-field>
                </div>

                <div class="col-sm-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome do lojista</mat-label>
                        <input matInput formControlName="storeOwnerName" type="text">
                    </mat-form-field>
                </div>

                <div class="col-sm-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <input matInput formControlName="idCheckoutStatus" type="number">
                    </mat-form-field>
                </div>

                <div class="col-sm-2">
                    <button type="submit" mat-flat-button class="btn-secundary">
                        @if (isLoading == true) {
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                            Buscar
                        }
                    </button>
                </div>
               
            </div>
        </form>
    </div>

    <div class="card-list">
        <div class="row">
            @if(_myCheckoutSearchResponse != undefined){
                @for (myCheckoutSearch of _myCheckoutSearchResponse.listMyCheckoutSearch; track $index) {
                    <div class="col-12 col-sm-6">
                        <div class="card-item">
                            <div class="infos">
                                <span class="title">#{{myCheckoutSearch.idCheckout}} {{myCheckoutSearch.BusinessStoreOwnerName}}</span>
                                <span class="title-2">Minha comissão {{myCheckoutSearch.totalComission | currency:'BRL'}}</span>
                                <span class="title-3">{{myCheckoutSearch.totalPrice | currency:'BRL'}}</span>
                                <span style="margin: 10px 0px;">{{myCheckoutSearch.checkoutStatusName}}</span>
                                <span>Concluido em 7 set. 2024</span>  
                            </div>
                            <div class="actions">
                                <a mat-mini-fab class="fab-action orange" aria-label="Ver mais sobre este pedido" routerLink="/consult/checkout-details/{{myCheckoutSearch.idCheckout}}"><mat-icon>arrow_forward</mat-icon></a>
                            </div>
                            <div class="tags">
                                <div class="tag-gray">Total {{myCheckoutSearch.totalItems}} peças</div>
                                <div class="tag-gray">Total {{myCheckoutSearch.totalFactories}} diferentes confecções</div>
                                @if(myCheckoutSearch.isApp2OneDelivery){
                                    <div class="tag-orange">Logística fashion.app2.one</div>
                                }
                            </div>
                        </div>
                    </div>
                }

                @if( _myCheckoutSearchResponse.listMyCheckoutSearch.length == 0){
                    <app-empty-list [title]="'Não encontramos nenhum pedido.'"
                    [subTitle]="'Clique em novo pedido e comece a criar um pedido de sucesso para seu lojista'"></app-empty-list>
                }
            }
            @else {
                @for (item of _arrayLoadingSearch; track $index) {
                    <div class="col-12 col-sm-6" >
                        <div class="card-item">
                            <div class="infos">
                                 <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'0'"></app-loading-box>
                                 <app-loading-box [height]="'20'" [width]="'250'" [marginTop]="'10'"></app-loading-box>
                                 <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
                                 <app-loading-box [height]="'8'" [width]="'80'" [marginTop]="'20'"></app-loading-box>
                                 <app-loading-box [height]="'8'" [width]="'100'" [marginTop]="'20'"></app-loading-box>
                            </div>
                            <div class="actions">
    
                            </div>
                            <div class="tags">
                                <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'8'"></app-loading-box>
                            </div>
                        </div>
                        
                    </div>
                }
            }

            @if(_isLastPage == false && _myCheckoutSearchResponse != undefined &&  _myCheckoutSearchResponse.listMyCheckoutSearch.length > 0){
                <div class="col-12 text-center see-more">
                    <button mat-button class="btn-primary" (click)="nextPage()">
                        <span class="material-symbols-outlined">keyboard_arrow_down</span>
                        Ver mais
                    </button>
                </div>
            }
        </div>
       
    </div>
</div>