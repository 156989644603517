import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { BusinessFactoryToConectListComponent } from './business-factory-to-conect-list/business-factory-to-conect-list.component';
import { BusinessFactoryConectedListComponent } from './business-factory-conected-list/business-factory-conected-list.component';

@Component({
  selector: 'app-business-factory-list',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    BusinessFactoryConectedListComponent,
    BusinessFactoryToConectListComponent
  ],
  templateUrl: './business-factory-list.component.html',
  styleUrl: './business-factory-list.component.css'
})
export class BusinessFactoryListComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {    

  }

}
