<form [formGroup]="model" novalidate (ngSubmit)="submit()">
    <div formArrayName="listProductGridStruct">
        <div *ngFor="let productGridStruct of getListProductGridStruct(model); let i = index">
            <div class="" [formGroupName]="i">
                
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome da cor</mat-label>
                            <input matInput formControlName="colorName" type="text">
                            @if (getListProductGridStruct(model)[i].get('colorName')?.invalid) {
                                <mat-error>Informe o nome da cor</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Cor</mat-label>
                            <input matInput formControlName="colorHex" type="color">
                            <mat-icon matSuffix>palette</mat-icon>
                            @if (getListProductGridStruct(model)[i].get('colorHex')?.invalid) {
                                <mat-error>Informe o código da cor</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        @if(getListProductGridStruct(model).length > 1){
                            <button mat-fab class="btn-remove" (click)="removeProductGridStructList(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        }
                        <button mat-fab class="btn-add" (click)="addListProductGridStruct(null, i)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>

             
                <div formArrayName="listNewProductGridSizeStruct">
                    <div [formGroupName]="j" *ngFor="let newProductGridSizeStruct of getListNewProductGridSizeStruct(productGridStruct); let j = index">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Tamanho</mat-label>
                                    <input matInput formControlName="sizeName" type="text">
                                    @if (getListNewProductGridSizeStruct(productGridStruct)[j].get('sizeName')?.invalid) {
                                        <mat-error>Informe o tamanho</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Preço</mat-label>
                                    <input matInput formControlName="currentPrice" type="number">
                                    <mat-icon matSuffix>attach_money</mat-icon>
                                    @if (getListNewProductGridSizeStruct(productGridStruct)[j].get('currentPrice')?.invalid) {
                                        <mat-error>Informe o preço</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Qtde em estoque</mat-label>
                                    <input matInput formControlName="currentQuantity" type="number">
                                    @if (getListNewProductGridSizeStruct(productGridStruct)[j].get('currentQuantity')?.invalid) {
                                        <mat-error>Informe o quantidade atual no estoque</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                          
                            <div class="col-md-2">
                                @if(getListNewProductGridSizeStruct(productGridStruct).length > 1){
                                    <button mat-fab class="btn-remove" (click)="removeNewProductGridSizeStruct(i, j)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                }
                               
                                <button mat-fab class="btn-add" (click)="addListNewProductGridSizeStruct(i, null, j)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>          
        </div>        
    </div>
    <button type="submit" mat-flat-button class="btn-primary button-big">
        @if (isLoading == true) {
            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
        }
        @else{
            Salvar grade
        }
    </button>
    <br />
    <br />
    <br />
</form>