import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';

@Component({
  selector: 'app-empty-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './empty-list.component.html',
  styleUrl: './empty-list.component.css'
})
export class EmptyListComponent {
  @Input() title:string;
  @Input() subTitle:string;
  @Input() smallImg:boolean;
}
