import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { MyCheckoutSearchResponse } from '../../responses/checkout/my-checkout-search.response';
import { SearchCheckoutRequest } from '../../requests/checkout/search-checkout.request';
import { CheckoutDeliveryResponse } from '../../responses/checkout/checkout-delivery.response';
import { CheckoutDeliveryRequest } from '../../requests/checkout/checkout-delivery.request';
import { ReturnStruct } from '../../structs/return.struct';

@Injectable({
  providedIn: 'root'
})
export class CheckoutDeliveryService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(idCheckout:number): Observable<CheckoutDeliveryResponse> {
    return this.httpClient.get<CheckoutDeliveryResponse>(environment.urlApiCheckout + "CheckoutDelivery/idCheckout/" + idCheckout, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public approveDelivery(idCheckout:number): Observable<CheckoutDeliveryResponse> {
    return this.httpClient.put<CheckoutDeliveryResponse>(environment.urlApiCheckout + "CheckoutDelivery/ApproveDelivery/idCheckout/" + idCheckout, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createCheckoutDelivery(checkoutDeliveryRequest:CheckoutDeliveryRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiCheckout + "CheckoutDelivery", checkoutDeliveryRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
