
@if(showFilter == true){
<div class="container-item">
    <form class="form" [formGroup]="model"  (ngSubmit)="post()" class="text-center">
        <h4 class="text-left">
            Filtros

            <a mat-flat-button (click)="clear()">Limpar</a>
        </h4>
        <mat-form-field appearance="outline">
            <mat-label>Número do pedido</mat-label>
            <input matInput formControlName="idCheckout" type="number">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Nome do consultor</mat-label>
            <input matInput formControlName="searchConsultorName" type="text">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Nome do lojista</mat-label>
            <input matInput formControlName="searchStoreOwnerName" type="text">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Data de periodo do pedido</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Data de inicio" formControlName="startDate">
              <input matEndDate placeholder="Data de fim" formControlName="endDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <button type="submit" mat-flat-button class="btn-primary">
            <mat-icon>filter_list</mat-icon>
            Filtrar
        </button>
    </form>
</div>

<div class="container-out" (click)="clickContainerOut()"></div>
}