import { Component, OnInit } from '@angular/core';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { AuthService } from '../../../shared/services/auth.service';
import { FactoryInventorySearchService } from '../../../shared/services/API/inventory/factory-inventory-search.service';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { FactoryInventorySearchResponse } from '../../../shared/services/responses/inventory/factory-inventory-search.response';
import { FactoryInventorySearchRequest } from '../../../shared/services/requests/inventory/factory-inventory-search.request';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { LoadingBoxComponent } from '../../../shared/component/loading-box/loading-box.component';
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from '../../../shared/component/empty-list/empty-list.component';
import { RouterLink } from '@angular/router';
import { InventoryItemComponent } from "./inventory-item/inventory-item.component";

@Component({
  selector: 'app-inventory-list',
  standalone: true,
  imports: [
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
    InventoryItemComponent
],
  templateUrl: './inventory-list.component.html',
  styleUrl: './inventory-list.component.css'
})
export class InventoryListComponent implements OnInit{
  
  constructor(public authService:AuthService,
    public factoryInventorySearchService:FactoryInventorySearchService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ){

  }

  public _factoryInventorySearchResponse:FactoryInventorySearchResponse
  public isLoading:boolean = false;
  public model: FormGroup;
  public _page:number = 0;
  public _isLastPage:boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      productName: [''],
      productCode: [''],
    });

    this.search(); 
  }

  search(){
    if(this.isLoading || this.model.invalid)
      return;

    let factoryInventorySearchRequest:FactoryInventorySearchRequest = new FactoryInventorySearchRequest();

    factoryInventorySearchRequest.page = this._page;
    factoryInventorySearchRequest.productName = this.model.get('productName')?.value;
    factoryInventorySearchRequest.productCode = this.model.get('productCode')?.value;

     
    this.isLoading = true;
    this.factoryInventorySearchService.search(factoryInventorySearchRequest).subscribe({
      next:(factoryInventorySearchResponse:FactoryInventorySearchResponse)=>{
        this.isLoading = false;
        if (factoryInventorySearchResponse.isError) { 
          this.alertService.show("Erro inesperado", factoryInventorySearchResponse.errorDescription, AlertType.error);
          return;
        }

        this._isLastPage = true;

        if (this._page == 0) {
          this._factoryInventorySearchResponse = new FactoryInventorySearchResponse();
        }        

        if (factoryInventorySearchResponse.listFactoryInventoryProductStruct?.length > 0) {
          this._factoryInventorySearchResponse.listFactoryInventoryProductStruct.push(...factoryInventorySearchResponse.listFactoryInventoryProductStruct);
          this._isLastPage = false;
        }        
      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickSearch() {
    this._page = 0;
    this.search();
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }
}
