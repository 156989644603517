<div class="checkout-stats">  
    <div class="checkout-stats-row">
       <div class="row">
            <div class="col-2 col-sm-1">
                <div class="vertical-line"></div>
                <div class="vertical-aling-out">
                     <div class="vertical-aling-inner">
                        <div class="circle" [ngClass]="{'disable': isActive == false, 'current-status': currentStatus == true}">
                            <span class="material-symbols-outlined">check</span>
                        </div>
                     </div>
                </div>
            </div>
            <div class="col-10 col-sm-11">
                <div class="checkout-stats-container">
                    <span class="title">
                        {{statusName}}
                        <div class="date">{{dateTimeInclusionStatus | date:'dd/MM/yyyy'}}</div>
                    </span>
                    @if(isActive){
                        <span class="light">{{statusDetails}}</span>
                    }

                    @if(currentStatus == true && idStatusCheckoout == 4){
                        @if(_checkoutDeliveryResponse == undefined || _checkoutDeliveryResponse.checkoutDeliveryStruct == null){
                            <span>Cadestre a transportadora que irá entregar o pedido. Seu cliente irá usar essas informações para acompanhar a entrega</span>
                            <button mat-flat-button class="btn-primary button-big" (click)="openModalDelivery()">Cadastrar transportadora</button>
                        }
                        @else if(_checkoutDeliveryResponse.checkoutDeliveryStruct.isApproved == false){
                            <a (click)="aprove()"><span class="light">Clique aqui para aprovar orçamento de {{_checkoutDeliveryResponse.checkoutDeliveryStruct.deliveryPrice | currency: 'BRL'}} via {{_checkoutDeliveryResponse.checkoutDeliveryStruct.deliveryProviderName}}</span></a>
                        }
                        
                    }
                </div>
            </div>
        </div>
    </div>
</div>