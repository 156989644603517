import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from '../../structs/return.struct';
import { CheckoutProductRequest } from '../../requests/orchestator-checkout/checkout-product.request';
import { CheckoutResponse } from '../../responses/orchestrator-checkout/checkout.response';
import { CheckoutAddressRequest } from '../../requests/orchestator-checkout/checkout-address.request';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public postCheckout(checkoutProductRequest:CheckoutProductRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorCheckout + "Checkout/CheckoutProduct", checkoutProductRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }  

  public listCheckout(idBusinessStore:number): Observable<CheckoutResponse> {
    return this.httpClient.get<CheckoutResponse>(environment.urlApiOrchestratorCheckout + "Checkout/idBusinessStore/" + idBusinessStore, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }  

  public listCheckoutByIdCheckout(idCheckout:number): Observable<CheckoutResponse> {
    return this.httpClient.get<CheckoutResponse>(environment.urlApiOrchestratorCheckout + "Checkout/idCheckout/" + idCheckout, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  } 

  public getCheckoutToStoreById(idCheckout:number): Observable<CheckoutResponse> {
    return this.httpClient.get<CheckoutResponse>(environment.urlApiOrchestratorCheckout + "Checkout/GetCheckoutToStoreById/idCheckout/" + idCheckout, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  } 

  public delteItemCheckout(idProductCheckout:number, idCheckout:number): Observable<CheckoutResponse> {
    return this.httpClient.delete<CheckoutResponse>(environment.urlApiOrchestratorCheckout + `Checkout/DeleteProductCheckout/idProductCheckout/${idProductCheckout}/idCheckout/${idCheckout}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }  

  public put(checkoutAddressRequest:CheckoutAddressRequest): Observable<CheckoutResponse> {
    return this.httpClient.put<CheckoutResponse>(environment.urlApiOrchestratorCheckout + `Checkout/Address`, checkoutAddressRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }  
}
