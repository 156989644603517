import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { TokenAuthResponse } from '../../responses/business-user/token-auth.response';

@Injectable({
  providedIn: 'root'
})
export class TokenAuthService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public post(idBusiness:number): Observable<TokenAuthResponse> {
    var body = {
      idBusiness: idBusiness
    }
    return this.httpClient.post<TokenAuthResponse>(environment.urlApiBusinessUser + "TokenAuth", body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
