
export class CreateAndUpdateUserRequest {
    public idUser: number;
    public name: string;
    public email: string;
    public password: string;
    public phone: string;
    public cpf: string;
    public listIdUserProfile: number[];
}
