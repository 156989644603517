import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { CheckoutDeliveredListComponent } from './checkout-delivered-list/checkout-delivered-list.component';
import { CheckoutNotDeliveredListComponent } from './checkout-not-delivered-list/checkout-not-delivered-list.component';

@Component({
  selector: 'app-checkout-history-list',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    CheckoutDeliveredListComponent,
    CheckoutNotDeliveredListComponent
  ],
  templateUrl: './checkout-history-list.component.html',
  styleUrl: './checkout-history-list.component.css'
})
export class CheckoutHistoryListComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {    

  }

}
