import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { BusinessResponse } from '../../responses/business-user/business.response';
import { BusinessStoreFactoryResponse } from '../../responses/business-user/business-store-factory.response';
import { Business } from '../../models/business-user/business.model';
import { ReturnStruct } from '../../structs/return.struct';

@Injectable({
  providedIn: 'root'
})
export class BusinessStoreService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public GetBusinessStore(idBusinessStore:number): Observable<BusinessResponse> {
    return this.httpClient.get<BusinessResponse>(environment.urlApiBusinessUser + "BusinessStore/GetBusinessStore/idBusinessStore/" + idBusinessStore, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public GetStoreFactories(idBusinessStore:number): Observable<BusinessStoreFactoryResponse> {
    return this.httpClient.get<BusinessStoreFactoryResponse>(environment.urlApiBusinessUser + "BusinessStore/GetStoreFactories/idBusinessStore/" + idBusinessStore, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public PostBusinessStore(request:Business): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessUser + "BusinessStore/createBusinessStore", request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public PutBusinessStore(request:Business): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessUser + "BusinessStore/updateBusinessStore", request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public DeleteBusinessStore(idBusinessStore:number): Observable<ReturnStruct> {
    let url: string = `BusinessStore/DeleteBusinessStore/idBusinessStore/${idBusinessStore}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBusinessUser + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
