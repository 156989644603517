@if (factoryOrderSearchItemStruct != undefined) {
    <div class="order-card">
        <div class="card-col-1">
            <span class="title-card">#{{factoryOrderSearchItemStruct.idCheckout}} - {{factoryOrderSearchItemStruct.businessConsultorName}}</span>
            <span class="subtitle-card">Lojista: {{factoryOrderSearchItemStruct.businessStoreName}}</span>
            <span class="date">Pedido realizado {{factoryOrderSearchItemStruct.checkoutDateTimeInclusion | date:'dd MMM y, hh:mm'}} </span>
         
            <app-order-status-bullet [checkoutStatusName]="factoryOrderSearchItemStruct.businessCheckoutStatusName" [idCheckoutStatus]="factoryOrderSearchItemStruct.idBusinessCheckoutStatus" ></app-order-status-bullet>
    
            <div class="tags">
                <div class="tag">Total de {{factoryOrderSearchItemStruct.totalItems}} peças</div>
                <div class="tag">{{factoryOrderSearchItemStruct.paymentMethod}}</div>
            </div>
        </div>
        <div class="card-col-2">
            <span class="price">{{factoryOrderSearchItemStruct.totalPrice | currency:'BRL'}}</span>
            <button mat-mini-fab class="fab-action orange" aria-label="Ver mais sobre este pedido" (click)="openModalCheckout()">
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </div>
    </div>
} @else {
    <div class="order-card">
        <div class="card-col-1">
            <span class="title-card"><app-loading-box [height]="'20'" [width]="'30'" [display]="'inline-block'"></app-loading-box> &nbsp; <app-loading-box [height]="'20'" [width]="'100'" [display]="'inline-block'"></app-loading-box></span>
            <span class="subtitle-card"><app-loading-box [height]="'15'" [width]="'100'" [marginTop]="'10'"></app-loading-box></span>
            <span class="date"> <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box></span>
        
            <div class="status">
                <app-order-status-bullet></app-order-status-bullet>
            </div>
    
            <div class="tags">
                
                <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'" [display]="'inline-block'"></app-loading-box>
                &nbsp;
                <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'" [display]="'inline-block'"></app-loading-box>
            </div>
        </div>
        <div class="card-col-2">
            <span class="price"> <app-loading-box [height]="'20'" [width]="'80'"></app-loading-box></span>
        </div>
    </div>
}
