<app-menu></app-menu>
<app-search-bar></app-search-bar>
<div class="container-search">
    
    <div class="container">
        <div class="header-infos">
            @if(_isBusinessStoreSelected){
                <label class="header-infos-business-name">{{_businessStoreResponse.business.businessName}}</label>
                <a class="header-infos-user-name" (click)="openDialogSelectClient()">
                    {{_businessStoreResponse.business.ownerName}}
                    <mat-icon>sync</mat-icon>
                </a>
            }
            @if(!_isBusinessStoreSelected){
                <button mat-button type="button" class="header-infos-business-select" (click)="openDialogSelectClient()">Selecione um lojista</button>
            }
        </div>
    </div>

    <div class="container-itens">
        @if(_productSearchResponse != undefined){
        
            @if(_productSearchResponse.listProductResponseStruct != null && _productSearchResponse.listProductResponseStruct != undefined && _productSearchResponse.listProductResponseStruct.length > 0){
                @for (item of _productSearchResponse.listProductResponseStruct; track $index) {
                    <app-search-item (clickAddItem)="openDialogAddItem($event)" [productResponseStruct]="item"></app-search-item>
                }
                <button mat-stroked-button class="btn-see-more">
                    Ver mais  
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
            } 
            @else{
                <div class="empty-search">
                    <img src="assets/images/empty-search-products.png" />
                    <p>Não encontramos nenhum produto com os filtros selecionados.</p>
                </div>
            }
           
        }
        @if(_productSearchResponse == undefined){
            <div class="loading">
                <app-search-item></app-search-item>
                <app-search-item></app-search-item>
                <app-search-item></app-search-item>
                <app-search-item></app-search-item>
                <app-search-item></app-search-item>
            </div>
        }
    </div>
    
    <div class="container-checkout">
        @if(_isBusinessStoreSelected == true){
            <app-checkout [checkoutResponse]="_checkoutResponse" (deleteCheckoutItem)="clickDeleteCheckoutItem($event)" (closeCheckout)="clickCloseCheckout()" (editCheckoutItem)="clickEditCheckoutItem($event)"></app-checkout>
        }
        @if(_isBusinessStoreSelected == false){
            <button mat-button type="button" class="header-infos-business-select" (click)="openDialogSelectClient()">Selecione um lojista</button>
        }
        
    </div>
</div>