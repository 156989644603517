<h2 mat-dialog-title>Pedido para Margarete</h2>
@if(_productUserDetailsResponse == undefined){
    <mat-dialog-content>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-dialog-content>
}
@if(_productUserDetailsResponse != undefined){
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <mat-dialog-content>
            <div class="row">
                <div class="col-sm-5">
                    <div class="item-images">
                        <c-carousel [interval]="5000000" transition="slide">
                            <c-carousel-indicators></c-carousel-indicators>
                            <c-carousel-inner>
                                @if(_productUserDetailsResponse.listProductImage != null && _productUserDetailsResponse.listProductImage.length > 0){
                                    @for (item of _productUserDetailsResponse.listProductImage; track $index) {
                                        <c-carousel-item>
                                            <img [src]="item.url" alt="'Image'" class="d-block w-100" loading="lazy" />
                                        </c-carousel-item>
                                    }
                                }

                                @if(_productUserDetailsResponse.listProductImage == null || _productUserDetailsResponse.listProductImage.length == 0){
                                    <c-carousel-item>
                                        <img [src]="'assets/images/clothing-default-2.jpg'" alt="'Image'" class="d-block w-100" loading="lazy" />
                                    </c-carousel-item>
                                }                       
                            </c-carousel-inner>
                            <c-carousel-control [routerLink] caption="Previous" direction="prev"></c-carousel-control>
                            <c-carousel-control [routerLink] caption="Next" direction="next"></c-carousel-control>
                        </c-carousel>
                    </div>
                </div>
                <div class="col-sm-7">
                    <h1 class="business-name">DBZ</h1>
                    <h3 class="product-name">{{_productUserDetailsResponse.product.productName}}</h3>
                    <div class="color-grade">
                        @for (item of _productUserDetailsResponse.listProductGridColor; track $index) {
                            <span class="color-grade-item" [ngStyle]="{'background-color': item.colorHex}">{{item.colorName}}</span>
                        }
                    </div>
                    <div class="item-content" [innerHtml]="_productUserDetailsResponse.product.htmlDescription"></div>
                </div>
            </div>
            <div class="grade">
                
                <table class="grade-table">
                    <thead>
                        <tr>
                            <th></th>
                            @for (item of _productUserDetailsResponse.listProductGridColor; track $index) {
                                <th><span class="grade-color" [ngStyle]="{'background-color': item.colorHex}">{{item.colorName}}</span></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        @for (size of _allSizesGrid; track $index) {
                            <tr>
                                <td><label class="label-size">{{size}}</label></td>
                                @for (color of _productUserDetailsResponse.listProductGridColor; track $index) {
                                    <div formArrayName="listProductGridSize" *ngFor="let item of getControls(); let indexFormArray = index" class="table-td">
                                        @if(item.get("sizeName")?.value == size && item.get("idProductGridColor")?.value == color.idProductGridColor)
                                        {
                                            <td [formGroupName]="indexFormArray">
                                                <div class="containt-td">
                                                    <label class="price-item">{{item.get("currentPrice")?.value | currency: 'BRL'}}</label>
                                                    <input type="text" formControlName="selectedQuantity" (input)="changeInputGrid(size, color.idProductGridColor, $event)"/>
                                                    <label class="label-unid">Unid.</label>
                                                </div>
                                            </td>
                                        }                  
                                    </div>

                                    @if(isNotExist(size, color.idProductGridColor)){
                                        <td></td>
                                    }
                                }
                            </tr>
                        }
                    </tbody>
                </table>
            </div>    
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="price-content">
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <label class="price">Total: {{_totalPrice | currency: 'BRL'}}</label>
                        <label class="commission">Minha comissão {{_totalcomission | currency: 'BRL'}} </label>
                    </div>
                    <div class="col-sm-6 col-12">
                        <button mat-button class="btn-primary btn-add">
                            @if (_isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Adicionar grade
                            }
                        </button>
                    </div>
                </div>
            
            </div>
        </mat-dialog-actions>
    </form>
}
<!-- <mat-dialog-actions>
  <button mat-button >No Thanks</button>
  <button mat-button [mat-dialog-close]="" cdkFocusInitial>Ok</button>
</mat-dialog-actions> -->