<h2 mat-dialog-title>{{_title}}</h2>

<mat-dialog-content>
    <p>{{_description}}</p>
    <form class="form" [formGroup]="_model">
        <mat-form-field appearance="outline">
            <mat-label>Comissão em porcentagem</mat-label>
            <input matInput formControlName="consultorComission" type="number" min="1" max="100" step="0.01">
        </mat-form-field>
    </form>

</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
    <button mat-flat-button (click)="close()">{{_buttonClose}}</button>
    <button mat-flat-button class="btn-primary" (click)="confirm()">{{_buttonConfirm}}</button>
</mat-dialog-actions>