<!-- <h2 mat-dialog-title>#4567 Consultoa fulana</h2> -->
    
<mat-dialog-content>
    @if(_factoryOrderDetailsResponse != undefined)
    {
        <div class="header-infos">
            <div class="row">
                <div class="col-sm-7">
                    <span class="title">#{{_factoryOrderDetailsResponse.idCheckout}} {{_factoryOrderDetailsResponse.businessConsultorName}}</span>
                    <span class="info">{{_factoryOrderDetailsResponse.businessStoreOwnerName}}</span>
                    <span class="info">Pedido realizado em {{_factoryOrderDetailsResponse.checkoutDateTimeInclusion | date:'dd MMM y, hh:mm'}}</span>
                    <app-order-status-bullet [checkoutStatusName]="_factoryOrderDetailsResponse.businessCheckoutStatusName" [idCheckoutStatus]="_factoryOrderDetailsResponse.idBusinessCheckoutStatus" ></app-order-status-bullet>
                    <div class="div-price">
                        <span class="value">{{(_factoryOrderDetailsResponse.totalPrice - _factoryOrderDetailsResponse.discountValue) | currency:'BRL'}}</span>
                        <span class="description">({{_factoryOrderDetailsResponse.totalPrice | currency:'BRL'}} - desconto de {{_factoryOrderDetailsResponse.discountValue | currency:'BRL'}})</span>
                    </div>
                    <div class="payment-info">{{_factoryOrderDetailsResponse.paymentMethod}}</div>
                </div>
                <div class="col-sm-5">
                    <div class="actions">
                        @if(_factoryOrderDetailsResponse.idBusinessCheckoutStatus == businessCheckoutStatusEnumAguardandoSeparacao){
                            <button mat-button type="button" class="btn-primary" (click)="changeStatus(businessCheckoutStatusEnumEmSeparacao)">
                                @if (_isLoading == true) {
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                }
                                @else{
                                    Seguir para separação
                                    <mat-icon>arrow_forward</mat-icon>
                                }                               
                            </button>
        
                            <button mat-button type="button" class="btn-secundary" (click)="changeStatus(businessCheckoutStatusEnumCancelado)">
                                @if (_isLoadingCancel == true) {
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                }
                                @else{
                                    Cancelar pedido
                                } 
                            </button>
                        }
                        @else if (_factoryOrderDetailsResponse.idBusinessCheckoutStatus == businessCheckoutStatusEnumEmSeparacao){
                            <button mat-button type="button" class="btn-primary" (click)="changeStatus(businessCheckoutStatusEnumProntoParaColeta)">
                                @if (_isLoading == true) {
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                }
                                @else{
                                    Seguir para pronto para coleta
                                    <mat-icon>arrow_forward</mat-icon>
                                }   
                            </button>
        
                            <button mat-button type="button" class="btn-secundary" (click)="changeStatus(businessCheckoutStatusEnumCancelado)">
                                @if (_isLoadingCancel == true) {
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                }
                                @else{
                                    Cancelar pedido
                                } 
                            </button>
                        }
                    
                    </div>
                  
                </div>
            </div> 
        </div>
    
        <div class="body-infos">
            @for (factoryOrderDetailsItemStruct of _factoryOrderDetailsResponse.listFactoryOrderDetailsItemStruct; track $index) {
                <app-modal-order-checkout-item-resume [factoryOrderDetailsItemStruct]="factoryOrderDetailsItemStruct"></app-modal-order-checkout-item-resume>
            }
        </div>

        <div class="footer-infos">
            @for (businessCheckoutHistoryStruct of _factoryOrderDetailsResponse.listBusinessCheckoutHistoryStruct; track $index) {
                <p>{{businessCheckoutHistoryStruct.userName}} alterou este pedido para <b>{{businessCheckoutHistoryStruct.businessCheckoutStatusName}}</b> em {{businessCheckoutHistoryStruct.datetimeInclusion | date:'dd MMM y, hh:mm'}}</p>
            }
        </div>

    } @else{
        <div class="header-infos">
            <div class="row">
                <div class="col-sm-7">
                    <span class="title"><app-loading-box [height]="'20'" [width]="'230'"></app-loading-box></span>
                    <span class="info"> <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'15'"></app-loading-box></span>
                    <span class="info"> <app-loading-box [height]="'15'" [width]="'200'" [marginTop]="'15'"></app-loading-box></span>
                    <div class="div-price">
                        <span class="value"> <app-loading-box [height]="'20'" [width]="'120'" [marginTop]="'15'"></app-loading-box></span>
                    </div>
                </div>
            </div> 
        </div>

        <div class="body-infos">
            <app-modal-order-checkout-item-resume></app-modal-order-checkout-item-resume>
        </div>
    }
    
</mat-dialog-content>

<mat-dialog-actions>    
</mat-dialog-actions>

