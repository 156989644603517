<h2 mat-dialog-title>Selecione o método de pagamento</h2>

@if (!showNewCreditCardForm) {
    <form class="form" [formGroup]="paymentMethodForm" (ngSubmit)="submitPaymentMethod()">
        <mat-dialog-content>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Método de pagamento</mat-label>
                        <mat-select formControlName="selectedPaymentMethod" (selectionChange)="onPaymentMethodChange($event.value)">
                            @for (method of listPaymentMethods; track method.idPaymentMethod) {
                                <mat-option [value]="method.idPaymentMethod">
                                    {{ method.paymentMethodName }}
                                </mat-option>
                            }
                        </mat-select>
                        @if (paymentMethodForm.get('selectedPaymentMethod')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>

                @if (showCreditCardFlow) {
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Cartão de crédito</mat-label>
                            <mat-select formControlName="selectedCreditCard">
                                @for (card of listBusinessCreditCard; track card.idBusinessCreditCard) {
                                    <mat-option [value]="card.idBusinessCreditCard">
                                        **** **** **** {{ card.lastFourDigits }} - {{ card.paymentProcessor }}
                                    </mat-option>
                                }
                                <mat-option value="new" [disabled]="true">Nenhum</mat-option>
                            </mat-select>
                            @if (paymentMethodForm.get('selectedCreditCard')?.invalid) {
                                <mat-error>Campo obrigatório</mat-error>
                            }
                        </mat-form-field>
                    </div>

                    @if (listInstallmentsToCreditCard.length > 0) {
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Prestações</mat-label>
                                <mat-select formControlName="selectedInstallment">
                                    @for (installment of listInstallmentsToCreditCard; track installment.numInstallments) {
                                        <mat-option [value]="installment.numInstallments">
                                            {{ installment.numInstallments }}x de {{ installment.installmentValue | currency:'BRL' }}
                                        </mat-option>
                                    }
                                </mat-select>
                                @if (paymentMethodForm.get('selectedInstallment')?.invalid) {
                                    <mat-error>Campo obrigatório</mat-error>
                                }
                            </mat-form-field>
                        </div>
                    }

                    <div class="col-md-12">
                        <button class="btn-secundary" mat-button (click)="clickNewCreditCardButton()" type="button">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Adicionar novo cartão
                            }                              
                        </button>
                    </div>
                }
            </div>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button class="btn-secundary" mat-button (click)="closeModal()" type="button">Cancelar</button>
            <button class="btn-primary" mat-button type="submit">Salvar</button>
        </mat-dialog-actions>
    </form>
}

@else if (showNewCreditCardForm) {
    <form class="form" [formGroup]="creditCardForm" (ngSubmit)="saveCreditCard()">
        <mat-dialog-content>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Número do cartão de crédito</mat-label>
                        <input matInput formControlName="creditCardNumber" [mask]="masks.creditCard">
                        @if (creditCardForm.get('creditCardNumber')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>CCV</mat-label>
                        <input matInput formControlName="securityCCVCode" [mask]="masks.ccvCreditCard">
                        @if (creditCardForm.get('securityCCVCode')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Mês de Expiração</mat-label>
                        <input matInput formControlName="expiryMonth" [mask]="masks.month" placeholder="MM">
                        @if (creditCardForm.get('expiryMonth')?.invalid) {
                            <mat-error>Mês inválido (01-12)</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Ano de Expiração</mat-label>
                        <input matInput formControlName="expiryYear" [mask]="masks.year" placeholder="AAAA">
                        @if (creditCardForm.get('expiryYear')?.invalid) {
                            <mat-error>Ano inválido</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome no cartão</mat-label>
                        <input matInput formControlName="cardHolderName">
                        @if (creditCardForm.get('cardHolderName')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="userName">
                        @if (creditCardForm.get('userName')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="userEmail" type="email">
                        @if (creditCardForm.get('userEmail')?.invalid) {
                            <mat-error>Email inválido</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>CPF/CNPJ</mat-label>
                        <input matInput formControlName="userCpfCnpj" [mask]="masks.cpfCnpj">
                        @if (creditCardForm.get('userCpfCnpj')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>CEP</mat-label>
                        <input matInput formControlName="userPostalCode" [mask]="masks.cep">
                        @if (creditCardForm.get('userPostalCode')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Número</mat-label>
                        <input matInput formControlName="userAddressNumber">
                        @if (creditCardForm.get('userAddressNumber')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Complemento do endereço</mat-label>
                        <input matInput formControlName="userAddressComplement">
                        @if (creditCardForm.get('userAddressComplement')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone</mat-label>
                        <input matInput formControlName="userPhone" [mask]="masks.phone">
                        @if (creditCardForm.get('userPhone')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Celular</mat-label>
                        <input matInput formControlName="userMobilePhone" [mask]="masks.phone">
                        @if (creditCardForm.get('userMobilePhone')?.invalid) {
                            <mat-error>Campo obrigatório</mat-error>
                        }
                    </mat-form-field>
                </div>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button class="btn-primary" mat-button type="submit">
                @if (isLoading == true) {
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                }
                @else{
                    Salvar cartão de crédito
                }               
            </button>
            <button class="btn-secundary" mat-button (click)="showNewCreditCardForm = !showNewCreditCardForm" type="button">
                @if (isLoading == true) {
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                }
                @else{
                    Cancelar novo cartão
                }                
            </button>
        </mat-dialog-actions>
    </form>
}