import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBoxComponent } from '../../../../shared/component/loading-box/loading-box.component';
import { EmptyListComponent } from '../../../../shared/component/empty-list/empty-list.component';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { Masks, MaskService } from '../../../../shared/services/mask.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { FactoryConsultorResponse } from '../../../../shared/services/responses/business-user/factory-consultor.response';
import { ConfirmModalComponent } from '../../../../shared/component/confirm-modal/confirm-modal.component';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { BusinessConsultorService } from '../../../../shared/services/API/business-user/business-consultor.service';
import { InputConsultorComissionModalComponent } from '../../../../shared/component/input-consultor-comission/input-consultor-comission-modal.component';
import { UpdateInviteFactoryConsultorRequest } from '../../../../shared/services/requests/business-user/update-invite-factory-consultor.request';

@Component({
  selector: 'app-business-consultor-registration-data',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
    NgxMaskDirective
  ],
  providers: [
    provideNgxMask()
  ],
  templateUrl: './business-consultor-registration-data.component.html',
  styleUrl: './business-consultor-registration-data.component.css'
})
export class BusinessConsultorRegistrationDataComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private maskService: MaskService,
    public dialog: MatDialog,
    public businessConsultorService: BusinessConsultorService,
    private activatedRoute: ActivatedRoute,) {
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading: boolean = false;
  public isFirstLoading: boolean = true;
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1, 1];
  public _idFactoryConsultor: number;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    this.createDefaultInstanceOfForm();

    this.tryGetUrlIdFactoryConsultor();

    this.getFactoryConsultor();

  }

  createDefaultInstanceOfForm(): void {
    this.model = this.formBuilder.group({
      businessName: [''],
      cnpj: [''],
      ownerName: [''],
      businessPhone: [''],
      businessEmail: [''],
      zipcode: [''],
      country: [''],
      state: [''],
      city: [''],
      neighborhood: [''],
      street: [''],
      addressNumber: [''],
      complement: [''],
      consultorComission: ['']
    });
  }

  tryGetUrlIdFactoryConsultor(): void {
    let idFactoryConsultorUrl: string = this.activatedRoute.snapshot.params["idFactoryConsultor"];

    if (idFactoryConsultorUrl)
      this._idFactoryConsultor = Number(idFactoryConsultorUrl);
  }

  getFactoryConsultor(): void {
    if (!this._idFactoryConsultor) {
      this.backToBusinessConsultorByFactoryList();
      return;
    }

    this.businessConsultorService.getFactoryConsultor(this._idFactoryConsultor).subscribe({
      next: (response: FactoryConsultorResponse) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }

        this.populateFactoryDataForm(response);
        this.isFirstLoading = false;

      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isFirstLoading = false;
      }
    });
  }

  populateFactoryDataForm(factoryConsultorResponse: FactoryConsultorResponse): void {
    if (!factoryConsultorResponse?.businessConsultor || !factoryConsultorResponse.factoryConsultor)
      return;

    this.model.get('businessName')?.setValue(factoryConsultorResponse.businessConsultor.businessName);
    this.model.get('cnpj')?.setValue(factoryConsultorResponse.businessConsultor.cnpj);
    this.model.get('ownerName')?.setValue(factoryConsultorResponse.businessConsultor.ownerName);
    this.model.get('businessPhone')?.setValue(factoryConsultorResponse.businessConsultor.businessPhone);
    this.model.get('businessEmail')?.setValue(factoryConsultorResponse.businessConsultor.businessEmail);
    this.model.get('zipcode')?.setValue(factoryConsultorResponse.businessConsultor.zipcode);
    this.model.get('country')?.setValue(factoryConsultorResponse.businessConsultor.country);
    this.model.get('state')?.setValue(factoryConsultorResponse.businessConsultor.state);
    this.model.get('city')?.setValue(factoryConsultorResponse.businessConsultor.city);
    this.model.get('neighborhood')?.setValue(factoryConsultorResponse.businessConsultor.neighborhood);
    this.model.get('street')?.setValue(factoryConsultorResponse.businessConsultor.street);
    this.model.get('addressNumber')?.setValue(factoryConsultorResponse.businessConsultor.addressNumber);
    this.model.get('complement')?.setValue(factoryConsultorResponse.businessConsultor.complement);

    const comissionPercent: string = this.formatConsultorComissonDecimalToPercentString(factoryConsultorResponse.factoryConsultor.consultorComission);
    this.model.get('consultorComission')?.setValue(comissionPercent);
  }

  formatConsultorComissonDecimalToPercentString(consultorComissionInDecimal: number): string {
    let comissionPercent: string = "0%"

    if (!isNaN(consultorComissionInDecimal) && isFinite(consultorComissionInDecimal))
      comissionPercent = (consultorComissionInDecimal * 100).toFixed(2) + '%';

    return comissionPercent;
  }

  openModalToConfirmTheCancelingConnection(): void {
    if (this.isLoading)
      return;

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Alerta",
        description: "Deseja cancelar a conexão com esse consultor? Essa ação será irreversível e um e-mail informando o cancelamento será enviado para o consultor.",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm)
          this.cancelConecctionFactoryConsultor();
      }
    });
  }

  cancelConecctionFactoryConsultor(): void {
    if (this.isLoading || !this._idFactoryConsultor)
      return;

    this.isLoading = true;

    this.businessConsultorService.deleteFactoryConsultor(this._idFactoryConsultor).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show("Sucesso", "Cancelado com sucesso!", AlertType.success);
        this.backToBusinessConsultorByFactoryList();

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  openModalToInsertComissionAndUpdateInvite(): void{
    const dialogRef = this.dialog.open(InputConsultorComissionModalComponent, {
      data: {
        title: "Confirme a ação",
        description: "Insira o valor em porcentagem da comissão do consultor para concluir a nova solicitação de conexão. Será enviado um e-mail para o consultor para que o mesmo aceite a nova comissão",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.updateInviteToConsultor(result.consultorComission);
      }
    });
  }

  updateInviteToConsultor(consultorComissionInDecimal: number): void{
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: UpdateInviteFactoryConsultorRequest = new UpdateInviteFactoryConsultorRequest();
    request.idFactoryConsultor = this._idFactoryConsultor;
    request.consultorComission = consultorComissionInDecimal;

    this.businessConsultorService.updateInviteFactoryConsultor(request).subscribe({
      next: (response: FactoryConsultorResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show("Sucesso", "Ação executada com sucesso!", AlertType.success);       

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  backToBusinessConsultorByFactoryList(): void {
    if (this.isLoading)
      return;

    this.router.navigate(['/factory/business-consultor']);
  }
}
