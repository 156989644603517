import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { FactoryInventoryProductResponse } from '../../responses/inventory/factory-inventory-product.response';

@Injectable({
  providedIn: 'root'
})
export class FactoryInventoryProductService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(idProduct:number): Observable<FactoryInventoryProductResponse> {
    return this.httpClient.get<FactoryInventoryProductResponse>(environment.urlApiInventory + "FactoryInventoryProduct/idProduct/" + idProduct, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
