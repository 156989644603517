
export class CheckoutDeliveryRequest {
  public idCheckout:number;
  public idDeliveryProvider:number;
  public deliveryPrice:number;
  public trackingUrl:string;
  public deliveryName:number;
  public trackingCode:number;
  public estimatedDeliveryDateTime:Date;
}
