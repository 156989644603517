<app-menu></app-menu>
<div class="container">
    @if(_factoryInventoryProductResponse != undefined){
        @if(_idProduct != undefined){
            <h1 class="title-default">#{{_factoryInventoryProductResponse.idProduct}} {{_factoryInventoryProductResponse.productName}}</h1>
        }@else {
            <h1 class="title-default">Nova peça no seu estoque</h1>
        }  

        <h2 class="subtitle-default">Dados basicos</h2>
        <form class="form" [formGroup]="model"  (ngSubmit)="saveProductBase()">
            <div class="row">
                <div class="col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome do produto</mat-label>
                        <input matInput formControlName="productName" type="text">
                        @if (model.get('productName')?.invalid) {
                            <mat-error>Informe o nome do produto</mat-error>
                        }
                    </mat-form-field>
                </div>    
                <div class="col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Código do produto</mat-label>
                        <input matInput formControlName="productCode" type="text">
                        @if (model.get('productCode')?.invalid) {
                            <mat-error>Informe o código do produto</mat-error>
                        }
                    </mat-form-field>
                </div>    
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <mat-slide-toggle formControlName="isImmediateDelivery">Pode ser comercializado agora na fashion.app2.one</mat-slide-toggle>
                </div>       
            </div>
        
            <div class="NgxEditor__Wrapper">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                <ngx-editor [editor]="editor" formControlName="htmlDescription" 
                    [disabled]="false" 
                    [spellcheck]="true" 
                    [placeholder]="'Escreva aqui a melhor descrição para o seu produto! \nLEMBRE-SE: Uma boa descrição é capaz de vender melhor o seu produto'">
                </ngx-editor>
                </div>
        
            <div class="row">
                <div class="col-sm-6">
                    <mat-form-field appearance="outline" class="mat-form-field-business">
                        <mat-label>Coleção</mat-label>
                        <mat-select matNativeControl formControlName="idCollection">
                            <mat-option value="">Não informar</mat-option>    
                            @for (item of _listCollectionModel; track $index) {
                                <mat-option value="{{item.idCollection}}">{{item.collectionName}}</mat-option>    
                            }  
                        </mat-select>
                    </mat-form-field>
                </div>    
                <div class="col-sm-6">
                    <mat-form-field appearance="outline" class="mat-form-field-business">
                        <mat-label>Categoria</mat-label>
                        <mat-select matNativeControl formControlName="idCategory">
                            <mat-option value="">Não informar</mat-option>    
                            @for (item of _listCategoryModel; track $index) {
                                <mat-option value="{{item.idCategory}}">{{item.categoryName}}</mat-option>    
                            }   
                        </mat-select>
                    </mat-form-field>
                </div>    
            </div>
            <div class="row">
                <button type="submit" mat-flat-button class="btn-primary button-big">
                    @if (isLoading == true) {
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                        Salvar dados basicos
                    }
                </button>
            </div>
        </form> 

        @if(_idProduct != undefined){
            <div class="section">
                <h2 class="subtitle-default">Imagens</h2>
                <app-section-product-images (reloadProduct)="loadProduct()" [_factoryInventoryProductResponse]="_factoryInventoryProductResponse"></app-section-product-images>
            </div>
        
            <div class="section">
                <h2 class="subtitle-default">Grade</h2>
                <app-section-product-grid (reloadProduct)="loadProduct()" [_factoryInventoryProductResponse]="_factoryInventoryProductResponse"></app-section-product-grid>
            </div>
        }
        @else {
            <div class="section section-disable">
                <h2 class="subtitle-default">Imagens</h2>
                <p>Salve os dados basios antes para você poder incluir as imagens do seu produto</p>
            </div>
        
            <div class="section section-disable">
                <h2 class="subtitle-default">Grade</h2>
                <p>Salve os dados basios antes para você poder incluir as grades do seu produto</p>
            </div>
        }
    }
    
    
    
</div>