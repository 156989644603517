import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { SearchBusinessCheckoutPaginatedByFactoryAndConsultorRequest } from '../../requests/checkout/search-business-checkout-paginated-by-factory-and-consultor.request';
import { SearchBusinessCheckoutPaginatedByFactoryAndConsultorResponse } from '../../responses/checkout/search-business-checkout-paginated-by-factory-and-consultor.response';

@Injectable({
  providedIn: 'root'
})
export class BusinessCheckoutService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public SearchBusinessCheckoutPaginatedByFactoryAndConsultor(searchCheckoutRequest:SearchBusinessCheckoutPaginatedByFactoryAndConsultorRequest): Observable<SearchBusinessCheckoutPaginatedByFactoryAndConsultorResponse> {
    let url: string = "BusinessCheckout/SearchBusinessCheckoutPaginatedByFactoryAndConsultor";
    
    return this.httpClient.post<SearchBusinessCheckoutPaginatedByFactoryAndConsultorResponse>(environment.urlApiCheckout + url, searchCheckoutRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
