import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaskService {

  constructor() { }

  getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000||(00) 0000-0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    return masks;
  }

  static getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000||(00) 0000-0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    return masks;
  }
}

export class Masks {
  public phone: string;
  public cpf: string;
  public cnpj: string;
}