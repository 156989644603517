@if(showActionNav){
    <div class="container-item">
        @if(userLoginResponse.idBusinessType == 2){
            <a class="btn-nav">
                <div class="icon" routerLink="/consult/home">
                    <span class="material-symbols-outlined">home</span>
                </div>
                <label>Home</label>
           </a>
           <a class="btn-nav" routerLink="/consult/checkout">
                <div class="icon">
                    <span class="material-symbols-outlined">shopping_bag</span>
                </div>
                <label>Pedidos</label>
           </a>
           <a class="btn-nav">
                <div class="icon">
                    <span class="material-symbols-outlined">wallet</span>
                </div>
                <label>Carteira</label>
            </a>
    
            <a class="btn-nav" routerLink="/consult/business-store">
                <div class="icon">
                    <span class="material-symbols-outlined">storefront</span>
                </div>
                <label>Lojistas</label>
            </a>
            <a class="btn-nav" routerLink="/consult/business-factory">
                <div class="icon">
                    <span class="material-symbols-outlined">domain</span>
                </div>
                <label>Confecções</label>
            </a>
        }
        @else if(userLoginResponse.idBusinessType == 1){
            <a class="btn-nav">
                <div class="icon" routerLink="/factory/home">
                    <span class="material-symbols-outlined">shopping_bag</span>
                </div>
                <label>Pedidos</label>
           </a>
           <a class="btn-nav">
                <div class="icon">
                    <span class="material-symbols-outlined">wallet</span>
                </div>
                <label>Carteira</label>
            </a>
            <a class="btn-nav">
                <div class="icon" routerLink="/factory/inventory">
                    <span class="material-symbols-outlined">inventory_2</span>
                </div>
                <label>Estoque</label>
            </a>

            <a class="btn-nav">
                <div class="icon" routerLink="/factory/business-consultor">
                    <span class="material-symbols-outlined">person</span>
                </div>
                <label>Consultores</label>
           </a>

           <a class="btn-nav">
                <div class="icon" routerLink="/factory/home">
                    <span class="material-symbols-outlined">sell</span>
                </div>
                <label>Promoções</label>
            </a>

            <a class="btn-nav">
                <div class="icon" routerLink="/factory/user">
                    <span class="material-symbols-outlined">group</span>
                </div>
                <label>Usuários</label>
            </a>
        }
        @else if(userLoginResponse.idBusinessType == 3){
            <a class="btn-nav">
                <div class="icon" routerLink="/store/checkout">
                    <span class="material-symbols-outlined">shopping_bag</span>
                </div>
                <label>Pedidos</label>
           </a>
          
        }
    
    </div>
    <div class="container-out" (click)="clickContainerOut()"></div>
}
