import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { BusinessFactoryRegistrationDataComponent } from './business-factory-registration-data/business-factory-registration-data.component';
import { BusinessFactoryCheckoutHistoryComponent } from './business-factory-checkout-history/business-factory-checkout-history.component';

@Component({
  selector: 'app-business-factory-register',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    BusinessFactoryRegistrationDataComponent,
    BusinessFactoryCheckoutHistoryComponent
  ],
  templateUrl: './business-factory-register.component.html',
  styleUrl: './business-factory-register.component.css'
})
export class BusinessFactoryRegisterComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {    

  }

}
