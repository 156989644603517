import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogTitle, MatDialogContent,  MatDialogActions, MatDialogClose, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CarouselComponent, CarouselControlComponent, CarouselIndicatorsComponent, CarouselInnerComponent, CarouselItemComponent, ThemeDirective } from '@coreui/angular';
import { CommonModule, NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ProductUserDetailsResponse } from '../../../../shared/services/responses/orchestrator-checkout/product-user-details.response';
import { ProductGridSize } from '../../../../shared/services/models/inventory/product-grid-size.model';
import { ProductService } from '../../../../shared/services/API/orchestator-checkout/product.service';
import { CheckoutService } from '../../../../shared/services/API/orchestator-checkout/checkout.service';
import { CheckoutProductRequest } from '../../../../shared/services/requests/orchestator-checkout/checkout-product.request';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { CheckoutProductStruct } from '../../../../shared/services/structs/orchestrator-checkout/checkout-product.struct';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductGridSizeQuantity } from '../../../../shared/services/structs/inventory/product-grid-size-quantity.struct';

@Component({
  selector: 'app-modal-add-item',
  standalone: true,
  imports: [    
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ThemeDirective, 
    CarouselComponent, 
    CarouselIndicatorsComponent, 
    CarouselInnerComponent, 
    NgFor, 
    CarouselItemComponent, 
    CarouselControlComponent, 
    RouterLink,
    MatProgressBarModule,
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './modal-add-item.component.html',
  styleUrl: './modal-add-item.component.css'
})
export class ModalAddItemComponent implements OnInit {
  
  private _idProduct:number;
  private _idCheckout:number;
  public _productUserDetailsResponse:ProductUserDetailsResponse;
  public _allSizesGrid:string[];
  public model: FormGroup;
  public _totalPrice:number = 0;
  public _totalcomission:number = 0;
  public _idBusinessStore:number;
  public _isLoading:boolean = false;
  public _disable:boolean = false;

  constructor( public dialogRef: MatDialogRef<ModalAddItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public productService:ProductService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private checkoutService:CheckoutService){
      this._idProduct = data.idProduct;
      this._idCheckout = data.idCheckout;
      this._idBusinessStore = data.idBusinessStore;
      this._disable = data.disable;
  }

  ngOnInit(): void {
    this.getProduct();

    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      listProductGridSize: this.formBuilder.array([]),
    });
  }

  getProduct(){
    this.productService.getProduct(this._idProduct, this._idCheckout).subscribe({
      
      next:(productUserDetailsResponse:ProductUserDetailsResponse)=>{
        if (productUserDetailsResponse.isError) {
          this.alertService.show("Erro", productUserDetailsResponse.errorDescription, AlertType.error);
          return;
        }

        this._productUserDetailsResponse = productUserDetailsResponse;
        
        let allSizesGrid = productUserDetailsResponse.listProductGridSize.map(c=> c.sizeName);
        this._allSizesGrid = Array.from(new Set(allSizesGrid));

        productUserDetailsResponse.listProductGridSize.forEach(x => {
          let productGridSizeQuantity = productUserDetailsResponse.listProductGridSizeQuantity.find(c=> c.idProductGridSize == x.idProductGridSize);
         
          (this.model.controls['listProductGridSize'] as FormArray).push(
            this.formBuilder.group({
              idProductGridSize: [x.idProductGridSize],
              sizeName: [x.sizeName],
              currentPrice: [x.currentPrice],
              currentQuantity: [x.currentQuantity],
              idProduct: [x.idProduct],
              idProductGridColor: [x.idProductGridColor],
              quantity: ['0'],
              selectedQuantity: [{value: productGridSizeQuantity == undefined? 0 : productGridSizeQuantity.selectedQuantity, disabled: false}]
            }));
        });     
        
          console.log(this.model.controls['listProductGridSize'] )
          this.calculatePrice();
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  getControls() {
    return (this.model.get('listProductGridSize') as FormArray).controls;
  }

  isNotExist(sizeName:string, idProductGridColor:number):boolean{
    if(this._productUserDetailsResponse.listProductGridSize.filter(c=> c.idProductGridColor == idProductGridColor && c.sizeName == sizeName).length == 0)
      return true;
    else 
      return false;
  }

  changeInputGrid(sizeName:string, idProductGridColor:number, newValueEvent:any){
    this.calculatePrice();
  }

  calculatePrice(){
    let totalPrice = 0;
    this.model.get('listProductGridSize')?.value.forEach((x:any) => {
      totalPrice = totalPrice + (x.selectedQuantity * x.currentPrice);
    });

    this._totalPrice = totalPrice;
    this._totalcomission = totalPrice * this._productUserDetailsResponse.commissionValue;
  }

  submit(){
    this._isLoading = true; 
    let checkoutProductRequest:CheckoutProductRequest = new CheckoutProductRequest();
    checkoutProductRequest.idBusinessStore = this._idBusinessStore;
    checkoutProductRequest.listProductCheckout = [];
    this.model.get('listProductGridSize')?.value.forEach((x:any) => {
      if(x.selectedQuantity > 0 ){
        let checkoutProductStruct:CheckoutProductStruct = new CheckoutProductStruct();
        checkoutProductStruct.idProductGridSize = x.idProductGridSize;
        checkoutProductStruct.selectedQuant = x.selectedQuantity;
        checkoutProductRequest.listProductCheckout.push(checkoutProductStruct); 
      }
    });

    this.checkoutService.postCheckout(checkoutProductRequest).subscribe({
      next: (returnStruct:ReturnStruct) =>{
        this._isLoading = false;

        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.dialogRef.close();
      }
      ,error: (error)=>{
        this._isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    })
  }

}

export class keyValueGrid{
  constructor(key:number, value:string){
    this.key = key;
    this.value = value;
  }
  public key:number;
  public value:string;
}
