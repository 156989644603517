<app-menu></app-menu>
<div class="container-board">
    <div class="row">
        <div class="col-sm-8">
            <h1 class="title-default">Meus pedidos da {{userLoginResponse.businessName}}</h1>
        </div>
        <div class="col-sm-4">
            <button mat-button type="button" class="btn-filter" (click)="showFilter = true" >
                <mat-icon>filter_list</mat-icon>
                Filtrar
            </button>
            <app-oder-filter [factoryOrderSearchRequest]="factoryOrderSearchRequest" [showFilter]="showFilter" (showFilterFalse)="showFilter = false" (search)="search()"></app-oder-filter>
        </div>
    </div>
    

    <div class="grid" cdkDropListGroup>
        <div class="grid-col" >
            <div class="grid-header">
                <div class="row">
                    <div class="col-7">
                        <span class="col-title">Aguardando aprovação</span>
                        <span class="col-count-cards">{{_factoryOrderSearchResponse?.waitingTotalLateOrders}} pedidos</span>
                    </div>
                    <div class="col-5">
                        <span class="col-value-label">Valor neste momento</span>
                        <span class="col-value">{{_factoryOrderSearchResponse?.waitingTotalPrice | currency:'BRL'}}</span>
                    </div>
                </div>
            </div>

            <div class="grid-inner">
                @if(_factoryOrderSearchResponse != undefined){
                    @if(_factoryOrderSearchResponse.listWaitingFactoryOrderSearchItemStruct.length > 0){
                        <div
                        cdkDropList
                        #todoList="cdkDropList"
                        [cdkDropListData]="_factoryOrderSearchResponse.listWaitingFactoryOrderSearchItemStruct"
                        (cdkDropListDropped)="dropItem($event)">
                        @for (item of _factoryOrderSearchResponse.listWaitingFactoryOrderSearchItemStruct; track item) {
                          <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="item"></app-order-card>
                        }
                      </div>
                    }
                    @else {
                        <app-empty-col></app-empty-col>
                    }
                  
                }
                @else {
                    <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="undefined"></app-order-card>
                    <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="undefined"></app-order-card>
                }
               
               
            </div>  
            
        </div>
        <div class="grid-col">
            <div class="grid-header">
                <div class="row">
                    <div class="col-7">
                        <span class="col-title">Em separação</span>
                        <span class="col-count-cards">{{_factoryOrderSearchResponse?.separationTotalOrders}} pedidos</span>
                    </div>
                    <div class="col-5">
                        <span class="col-value-label">Valor neste momento</span>
                        <span class="col-value">{{_factoryOrderSearchResponse?.separationTotalPrice | currency:'BRL'}}</span>
                    </div>
                </div>
            </div>

            <div class="grid-inner">
                @if(_factoryOrderSearchResponse != undefined){
                    @if(_factoryOrderSearchResponse.listSeparationFactoryOrderSearchItemStruct.length > 0){
                        <div
                        cdkDropList
                        #todoList="cdkDropList"
                        [cdkDropListData]="_factoryOrderSearchResponse.listSeparationFactoryOrderSearchItemStruct"
                        (cdkDropListDropped)="dropItem($event)">
                        @for (item of _factoryOrderSearchResponse.listSeparationFactoryOrderSearchItemStruct; track item) {
                          <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="item"></app-order-card>
                        }
                      </div>
                    }
                    @else {
                        <app-empty-col></app-empty-col>
                    }
                  
                }
                @else {
                    <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="undefined"></app-order-card>
                }
            </div>  
        </div>
        <div class="grid-col">
            <div class="grid-header">
                <div class="row">
                    <div class="col-7">
                        <span class="col-title">Concluído</span>
                        <span class="col-count-cards">{{_factoryOrderSearchResponse?.closedTotalOrders}} pedidos</span>
                    </div>
                    <div class="col-5">
                        <span class="col-value-label">Já vendido</span>
                        <span class="col-value">{{_factoryOrderSearchResponse?.closedTotalPrice | currency:'BRL'}}</span>
                    </div>
                </div>
            </div>

            <div class="grid-inner">
                @if(_factoryOrderSearchResponse != undefined){
                    @if(_factoryOrderSearchResponse.listClosedFactoryOrderSearchItemStruct.length > 0){
                        <div
                        cdkDropList
                        #todoList="cdkDropList"
                        [cdkDropListData]="_factoryOrderSearchResponse.listClosedFactoryOrderSearchItemStruct"
                        (cdkDropListDropped)="dropItem($event)">
                        @for (item of _factoryOrderSearchResponse.listClosedFactoryOrderSearchItemStruct; track item) {
                          <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="item"></app-order-card>
                        }
                      </div>
                    }
                    @else {
                        <app-empty-col></app-empty-col>
                    }
                  
                }
                @else {
                    <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="undefined"></app-order-card>
                    <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="undefined"></app-order-card>
                    <app-order-card cdkDrag (clickOpenModalCheckout)="clickOpenModalCheckout($event)" [factoryOrderSearchItemStruct]="undefined"></app-order-card>
                }
            </div>  
        </div>
    </div>
</div>
