<div class="container">
    <h1 class="title-default">Minhas confecções</h1>

    <div class="search-area">
        <form class="form" [formGroup]="model" (ngSubmit)="clickSearch()" class="text-center see-more">
            <div class="row search-area-itens">
                <div class="col-12 col-sm-7 col-md-5">
                    <mat-form-field appearance="outline">
                        <mat-label>Pesquisar confecção</mat-label>
                        <input matInput formControlName="searchText" type="text">
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-5 col-md-4">
                    <button type="submit" mat-flat-button class="btn-secundary search-button">
                        @if (isLoading == true) {
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                            <span class="material-symbols-outlined">search</span>
                            Encontrar em minhas confecções
                        }
                    </button>
                </div>

            </div>
        </form>
    </div>

    <div class="card-list">
        @if(_listBusinessFactoryByConsultorStruct != undefined) {
            <div class="row">
                @for (item of _listBusinessFactoryByConsultorStruct; track $index) {
                    <div class="col-12 col-md-6">
                        <div class="card-item">
                            <div class="infos">
                                <span class="title"> {{item.businessFactoryName}}</span>
                                <span class="title-4"><b>CNPJ: </b> {{item.businessFactoryCnpj}}</span>
                                <span class="title-4"><b>Telefone: </b>{{item.businessFactoryPhone}}
                                    @if(item.businessFactoryPhone &&
                                    item.businessFactoryPhone.trim() != ""){
                                        <img src="assets/images/whatsAppIcon.png" />
                                    }
                                </span>
                            </div>

                            <div class="actions">
                                <a [routerLink]="['/consult/business-factory/register', {idFactoryConsultor: item.idFactoryConsultor, idBusinessFactory: item.idBusinessFactory}]">
                                    <span class="material-symbols-outlined" aria-hidden="false" aria-label="Editar">
                                        arrow_forward
                                    </span>
                                </a>
                            </div>

                            <div class="tags">
                                <div class="tag-gray">
                                    {{ (item.consultorComission * 100) | number:'1.2-2' }}% de comissão
                                </div>
                            </div>
                        </div>
                    </div>
                }

                @if(_isLastPage == false && _listBusinessFactoryByConsultorStruct.length > 0) {                    
                    <div class="col-12 text-center see-more">
                        <button mat-button class="btn-primary" (click)="nextPage()">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                <span class="material-symbols-outlined">keyboard_arrow_down</span>
                                Ver mais
                            }
                        </button>
                    </div>
                }

                @if( _listBusinessFactoryByConsultorStruct.length == 0){
                    <app-empty-list [title]="'Não encontramos nenhuma confecção conectada'"
                        [subTitle]="'Adicione novas conexões parceiras!'"></app-empty-list>
                }
            </div>            
        }
        @else {
            <div class="row">
                @for (item of _arrayLoadingSearch; track $index) {
                    <div class="col-12 col-sm-6">
                        <div class="card-item">
                            <div class="infos">
                                <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'0'"></app-loading-box>
                                <app-loading-box [height]="'20'" [width]="'250'" [marginTop]="'10'"></app-loading-box>
                                <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
                            </div>
                            <div class="actions">

                            </div>
                            <div class="tags">
                                <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'8'"></app-loading-box>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }        
    </div>
</div>