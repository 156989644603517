<div class="container">
    <h1 class="title-default">Conecte agora a um novo consultor</h1>

    <div class="search-area">
        <form class="form" [formGroup]="model" (ngSubmit)="clickSearch()" class="text-center see-more">
            <div class="row search-area-itens">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Pesquisar consultor</mat-label>
                        <input matInput formControlName="searchText" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field class="mat-form-field-business">
                        <mat-label>Status</mat-label>
                        <mat-select matNativeControl formControlName="statusConection">
                            <mat-option value=""></mat-option>
                            <mat-option value="withoutConection">Sem conexão</mat-option>    
                            <mat-option value="waitingConection">Convite enviado</mat-option>             
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <button type="submit" mat-flat-button class="btn-secundary search-button">
                        @if (isLoading == true) {
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                            <span class="material-symbols-outlined">search</span>
                            Encontrar novos consultores
                        }
                    </button>
                </div>

            </div>
        </form>
    </div>

    <div class="card-list">
        @if(_listBusinessConsultorByFactoryStruct != undefined) {
            <div class="row">
                @for (item of _listBusinessConsultorByFactoryStruct; track $index) {
                    <div class="col-12 col-md-6">
                        <div class="card-item">
                            <div class="infos">
                                <span class="title"> {{item.businessConsultorName}}</span>
                                <span class="title-4"><b>CNPJ: </b> {{item.businessConsultorCnpj}}</span>
                                <span class="title-4"><b>Telefone: </b>{{item.businessConsultorPhone}}
                                    @if(item.businessConsultorPhone &&
                                    item.businessConsultorPhone.trim() != ""){
                                        <img src="assets/images/whatsAppIcon.png" />
                                    }
                                </span>
                            </div>

                            @if (!item.idFactoryConsultor) {
                                <div class="actions">
                                    <button mat-flat-button class="btn-primary btn-search" (click)="openModalToInsertComissionAndSendInvite(item)">
                                        + Conectar ao consultor
                                    </button>
                                </div>
                            }
                            

                            <div class="tags">
                                <div class="tag-gray">
                                    Não conectado
                                </div>
                                @if (item.idFactoryConsultor) {
                                    <div class="tag-gray">
                                       Convite pendente de resposta
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                @if(_isLastPage == false && _listBusinessConsultorByFactoryStruct.length > 0) {                    
                    <div class="col-12 text-center see-more">
                        <button mat-button class="btn-primary" (click)="nextPage()">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                <span class="material-symbols-outlined">keyboard_arrow_down</span>
                                Ver mais
                            }
                        </button>
                    </div>
                }

                @if( _listBusinessConsultorByFactoryStruct.length == 0){
                    <app-empty-list [title]="'Não encontramos nenhum consultor'"
                        [subTitle]="'Adicione novas conexões parceiras!'"></app-empty-list>
                }
            </div>            
        }
        @else {
            <div class="row">
                @for (item of _arrayLoadingSearch; track $index) {
                    <div class="col-12 col-sm-6">
                        <div class="card-item">
                            <div class="infos">
                                <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'0'"></app-loading-box>
                                <app-loading-box [height]="'20'" [width]="'250'" [marginTop]="'10'"></app-loading-box>
                                <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
                            </div>
                            <div class="actions">

                            </div>
                            <div class="tags">
                                <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'8'"></app-loading-box>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }        
    </div>
</div>