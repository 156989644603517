import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-box',
  standalone: true,
  imports: [],
  templateUrl: './loading-box.component.html',
  styleUrl: './loading-box.component.css'
})
export class LoadingBoxComponent {
  @Input() height:string;
  @Input() width:string;
  @Input() marginTop:string;
  @Input() marginLeft:string;
  @Input() display:string;
  @Input() float:string;
  @Input() margin:string;
}
