import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskDirective } from 'ngx-mask';
import { AlertService, AlertType } from '../../../../../shared/services/alert.service';
import { CheckoutDeliveryService } from '../../../../../shared/services/API/checkout/checkout-delivery.service';
import { CheckoutDeliveryRequest } from '../../../../../shared/services/requests/checkout/checkout-delivery.request';
import { ReturnStruct } from '../../../../../shared/services/structs/return.struct';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-modal-delivery',
  standalone: true,
  imports: [ 
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    MatSelectModule,
    MatDatepickerModule],
    providers: [provideNativeDateAdapter()],
  templateUrl: './modal-delivery.component.html',
  styleUrl: './modal-delivery.component.css'
})
export class ModalDeliveryComponent implements OnInit{

  public model: FormGroup;
  public isLoading:boolean = false;
  public idCheckout:number;

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private checkoutDeliveryService:CheckoutDeliveryService,
    private alertService: AlertService,
    
    public dialogRef: MatDialogRef<ModalDeliveryComponent>){
      this.idCheckout = data.idCheckout;
  }
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idDeliveryProvider: ['', [Validators.required]],
      deliveryPrice: ['', [Validators.required]],
      trackingUrl: [''],
      deliveryName: [''],
      trackingCode: [''],
      estimatedDeliveryDateTime: ['', [Validators.required]],
    });
  }

  //getToday() { return new Date('DD-MM-YYYY'); }

  post(){
    if(this.isLoading == true || this.model.invalid)
      return;

    this.isLoading = true;
    let checkoutDeliveryRequest:CheckoutDeliveryRequest = new CheckoutDeliveryRequest();

    checkoutDeliveryRequest.idCheckout = this.idCheckout;
    checkoutDeliveryRequest.deliveryPrice = this.model.get("deliveryPrice")?.value;
    checkoutDeliveryRequest.trackingUrl = this.model.get("trackingUrl")?.value;
    checkoutDeliveryRequest.deliveryName = this.model.get("deliveryName")?.value;
    checkoutDeliveryRequest.trackingCode = this.model.get("trackingCode")?.value;
    checkoutDeliveryRequest.trackingCode = this.model.get("trackingCode")?.value;
    checkoutDeliveryRequest.estimatedDeliveryDateTime = this.model.get("estimatedDeliveryDateTime")?.value;

    this.checkoutDeliveryService.createCheckoutDelivery(checkoutDeliveryRequest).subscribe({
      next:(returnStruct:ReturnStruct)=>{
        this.isLoading = false;

        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }
        
        this.alertService.show("Sucesso", "Transportadora cadastrada com sucesso!", AlertType.success);
        this.dialogRef.close(
          {
            reloadPage:true
          });
      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }
}
