import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { LoadingBoxComponent } from '../../../../shared/component/loading-box/loading-box.component';
import { EmptyListComponent } from '../../../../shared/component/empty-list/empty-list.component';
import { MatSelectModule } from '@angular/material/select';
import { InputConsultorComissionModalComponent } from '../../../../shared/component/input-consultor-comission/input-consultor-comission-modal.component';
import { FactoryConsultorResponse } from '../../../../shared/services/responses/business-user/factory-consultor.response';
import { BusinessConsultorService } from '../../../../shared/services/API/business-user/business-consultor.service';
import { SearchConsultorsByFactoryRequest } from '../../../../shared/services/requests/business-user/search-consultors-by-factory.request';
import { SearchConsultorsByFactoryResponse } from '../../../../shared/services/responses/business-user/search-consultors-by-factory.esponse';
import { BusinessConsultorByFactoryStruct } from '../../../../shared/services/structs/business-user/business-consultor-by-factory.struct';
import { CreateInviteFactoryToConsultorRequest } from '../../../../shared/services/requests/business-user/create-invite-factory-to-consultor.request';

@Component({
  selector: 'app-business-consultor-to-conect-list',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
    MatSelectModule,
  ],
  templateUrl: './business-consultor-to-conect-list.component.html',
  styleUrl: './business-consultor-to-conect-list.component.css'
})

export class BusinessConsultorToConectListComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private businessConsultorService: BusinessConsultorService,
    public dialog: MatDialog) {
  }

  public model: FormGroup;
  public isLoading: boolean = false;
  public _page: number = 0;
  public _listBusinessConsultorByFactoryStruct: BusinessConsultorByFactoryStruct[] = [];
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1];
  public _isLastPage: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      searchText: [''],
      statusConection: [""]
    });  

    this.clickSearch();

  }

  search() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let searchConsultorsByFactoryRequest: SearchConsultorsByFactoryRequest = new SearchConsultorsByFactoryRequest();

    searchConsultorsByFactoryRequest.searchText = this.model.get('searchText')?.value
    searchConsultorsByFactoryRequest.page = this._page;
    searchConsultorsByFactoryRequest.isToGetConsultorsWithoutConectionWithIdBusinessFactory = this.setSearchAttributeIsToGetConsultorsWithoutConectionWithIdBusinessFactory();
    searchConsultorsByFactoryRequest.inviteConectionStatus = this.setSearchAttributeInviteConectionStatus();

    this.businessConsultorService.searchPagedForConsultorsByFactory(searchConsultorsByFactoryRequest).subscribe({
      next: (response: SearchConsultorsByFactoryResponse) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        this._isLastPage = true;

        if (this._page == 0) {
          this._listBusinessConsultorByFactoryStruct = [];
        }        

        if (response.listBusinessConsultorByFactoryStruct?.length > 0) {
          this._listBusinessConsultorByFactoryStruct.push(...response.listBusinessConsultorByFactoryStruct);
          this._isLastPage = false;
        }        

        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  setSearchAttributeIsToGetConsultorsWithoutConectionWithIdBusinessFactory(): boolean {
    let isToGetConsultorsWithoutConectionWithIdBusinessFactory: boolean = false;

    if (this.model.get('statusConection')?.value == "withoutConection" || this.model.get('statusConection')?.value == "")
      isToGetConsultorsWithoutConectionWithIdBusinessFactory = true;

    return isToGetConsultorsWithoutConectionWithIdBusinessFactory;
  }

  setSearchAttributeInviteConectionStatus(): boolean | null {
    let inviteConectionStatus: boolean | null = null;

    if (this.model.get('statusConection')?.value == "waitingConection" || this.model.get('statusConection')?.value == "")
      inviteConectionStatus = false;

    return inviteConectionStatus;
  }

  clickSearch() {
    this._page = 0;
    this.search();
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }

  openModalToInsertComissionAndSendInvite(businessConsultorByFactoryStruct: BusinessConsultorByFactoryStruct): void{
    const dialogRef = this.dialog.open(InputConsultorComissionModalComponent, {
      data: {
        title: "Confirme a ação",
        description: "Insira o valor em porcentagem da comissão do consultor para concluir a solicitação de conexão.",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.sendInviteToConsultor(result.consultorComission, businessConsultorByFactoryStruct);
      }
    });
  }

  sendInviteToConsultor(consultorComissionInDecimal: number, businessConsultorByFactoryStruct: BusinessConsultorByFactoryStruct): void{
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: CreateInviteFactoryToConsultorRequest = new CreateInviteFactoryToConsultorRequest();
    request.idBusinessConsultor = businessConsultorByFactoryStruct.idBusinessConsultor;
    request.consultorComission = consultorComissionInDecimal;

    this.businessConsultorService.createInviteFactoryToConsultor(request).subscribe({
      next: (response: FactoryConsultorResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        businessConsultorByFactoryStruct.idFactoryConsultor = response.factoryConsultor.idFactoryConsultor;
        this.isLoading = false;
        this.alertService.show("Sucesso", "Adicionado com sucesso!", AlertType.success);       

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }
}

