<button type="submit" mat-flat-button class="btn-secundary" (click)="fileUpload.click()" style="width: 200px;">
   
    @if (isLoading == true) {
        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
    }
    @else{
        
        <mat-icon>add_photo_alternate</mat-icon>
        Adicionar Imagem
    }
</button>
<input type="file" class="file-input" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif" (change)="onFileSelected($event)" multiple #fileUpload>

@if(_factoryInventoryProductResponse.listProductImageStruct == null || _factoryInventoryProductResponse.listProductImageStruct.length == 0){
    <div class="text-center empty-list" >
        <img src="/assets/images/no-image.jpg">
        <h1>Ainda não há imagens cadastradas para este produto</h1>
        <h2>Boas imagens te ajudam a vender mais!</h2>
    </div>
}
@else{
    <div class="list-imgs">
        @for (item of _factoryInventoryProductResponse.listProductImageStruct; track $index) {
            <div class="list-imgs-item">
                <img src="{{item.url}}">
                <button mat-fab class="fab-action red" (click)="deleteImg(item)">
                    @if (item.isLoading == true) {
                        <mat-spinner [diameter]="15" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                        <mat-icon>delete</mat-icon>
                    }

                </button>
            </div>                    
        }
    </div>
}