export class Business  {
  public idBusiness:number;
  public idBusinessType:number;
  public businessName:string;
  public businessPhone:string;
  public businessEmail:string;
  public municipalRegistration:string;
  public cnpj:string;
  public cnae:string;
  public ownerName:string;
  public isActive:boolean;
  public isDeleted:boolean;
  public taxationRegime:string;
  public companyCategory:string;
  public lastNfEmmitedNumber:string;
  public lastBatchNfEmmitedNumber:string;
  public cityHallUserName:string;
  public cityHallAccessPassword:string;
  public cityHallTokenAccess:string;
  public nfServiceType:string;
  public digitalCertificateFileName:string;
  public zipcode:string;
  public neighborhood:string;
  public complement:string;
  public latitude:string;
  public longitude:string;
  public addressNumber:string;
  public city:string;
  public state:string;
  public street:string;
  public country:string;
  public datetimeInclusion:Date;
  }
  