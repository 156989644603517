import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBoxComponent } from '../../../../shared/component/loading-box/loading-box.component';
import { EmptyListComponent } from '../../../../shared/component/empty-list/empty-list.component';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { BusinessConsultorService } from '../../../../shared/services/API/business-user/business-consultor.service';
import { SearchConsultorsByFactoryRequest } from '../../../../shared/services/requests/business-user/search-consultors-by-factory.request';
import { SearchConsultorsByFactoryResponse } from '../../../../shared/services/responses/business-user/search-consultors-by-factory.esponse';
import { BusinessConsultorByFactoryStruct } from '../../../../shared/services/structs/business-user/business-consultor-by-factory.struct';

@Component({
  selector: 'app-business-consultor-conected-list',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
  ],
  templateUrl: './business-consultor-conected-list.component.html',
  styleUrl: './business-consultor-conected-list.component.css'
})
export class BusinessConsultorConectedListComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private businessConsultorService: BusinessConsultorService,
    public dialog: MatDialog,) {
  }

  public model: FormGroup;
  public isLoading: boolean = false;
  public _page: number = 0;
  public _listBusinessConsultorByFactoryStruct: BusinessConsultorByFactoryStruct[];
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1];
  public _isLastPage: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      searchText: [''],
    });   

    this.clickSearch();

  }

  search() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let searchConsultorsByFactoryRequest: SearchConsultorsByFactoryRequest = new SearchConsultorsByFactoryRequest();

    searchConsultorsByFactoryRequest.searchText = this.model.get('searchText')?.value
    searchConsultorsByFactoryRequest.page = this._page;
    searchConsultorsByFactoryRequest.inviteConectionStatus = true;
    searchConsultorsByFactoryRequest.isToGetConsultorsWithoutConectionWithIdBusinessFactory = false;
    
    this.businessConsultorService.searchPagedForConsultorsByFactory(searchConsultorsByFactoryRequest).subscribe({
      next: (response: SearchConsultorsByFactoryResponse) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        this._isLastPage = true;

        if (this._page == 0) {
          this._listBusinessConsultorByFactoryStruct = [];
        }        

        if (response.listBusinessConsultorByFactoryStruct?.length > 0) {
          this._listBusinessConsultorByFactoryStruct.push(...response.listBusinessConsultorByFactoryStruct);
          this._isLastPage = false;
        }        

        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickSearch() {
    this._page = 0;
    this.search();
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }
}
