<div class="container">
    <h1 class="title-default">Histórico de pedidos</h1>

    <div class="search-area">
        <form class="form" [formGroup]="model" (ngSubmit)="clickSearch()" class="text-center see-more">
            <div class="row search-area-itens">
                <div class="col-12 col-md-6 col-lg-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Nº do pedido</mat-label>
                        <input matInput formControlName="idCheckout" type="number">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome da loja</mat-label>
                        <input matInput formControlName="businessStoreName" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <mat-form-field class="mat-form-field-business">
                        <mat-label>Status</mat-label>
                        <mat-select matNativeControl formControlName="idBusinessCheckoutStatus">
                            <mat-option [value]="null">Todos os status</mat-option>
                            <mat-option *ngFor="let item of _listBusinessCheckoutStatus"
                                [value]="item.idBusinessCheckoutStatus">{{item.businessCheckoutStatusName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <button type="submit" mat-flat-button class="btn-secundary search-button">
                        @if (isLoading == true) {
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                        <span class="material-symbols-outlined">search</span>
                        Pesquisar pedido
                        }
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="card-list">
        @if(_listBusinessCheckoutToCardListPageStruct != undefined) {
        <div class="row">
            @for (item of _listBusinessCheckoutToCardListPageStruct; track $index) {
            <div class="col-12 col-md-6">
                <div class="card-item">
                    <div class="infos">
                        <span class="title"> #{{item.idCheckout}} {{item.businessStoreName}}</span>
                        <span class="title-4"><b>Comissão: R$ </b> {{ (item.comission * item.totalPrice) |
                            number:'1.2-2' }}</span>
                        <span class="title-4"><b>Valor da venda: R$ </b> {{ (item.totalPrice) | number:'1.2-2' }}</span>
                        <span class="title-4"><b>Status: </b>{{item.businessCheckoutStatusName}}</span>
                    </div>
                    <div class="tags">
                        <div class="tag-gray">Total de {{item.totalItems}} peças</div>
                        @if(item.isApp2OneDelivery){
                        <div class="tag-orange">Logística fashion.app2.one</div>
                        }
                    </div>
                </div>
            </div>
            }

            @if(_isLastPage == false && _listBusinessCheckoutToCardListPageStruct.length > 0) {
            <div class="col-12 text-center see-more">
                <button mat-button class="btn-primary" (click)="nextPage()">
                    @if (isLoading == true) {
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                    <span class="material-symbols-outlined">keyboard_arrow_down</span>
                    Ver mais
                    }
                </button>
            </div>
            }

            @if( _listBusinessCheckoutToCardListPageStruct.length == 0){
            <app-empty-list [title]="'Não encontramos nenhum pedido'"
                [subTitle]="'Crie um novo pedido!'"></app-empty-list>
            }
        </div>
        }
        @else {
        <div class="row">
            @for (item of _arrayLoadingSearch; track $index) {
            <div class="col-12 col-sm-6">
                <div class="card-item">
                    <div class="infos">
                        <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'0'"></app-loading-box>
                        <app-loading-box [height]="'20'" [width]="'250'" [marginTop]="'10'"></app-loading-box>
                        <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
                    </div>
                    <div class="actions">

                    </div>
                    <div class="tags">
                        <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'8'"></app-loading-box>
                    </div>
                </div>
            </div>
            }
        </div>
        }
    </div>
</div>