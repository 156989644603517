<app-menu></app-menu>
<app-search-bar></app-search-bar>

<div class="container">
    <div class="header-infos">
        @if(_businessStoreResponse != undefined){
            <label class="header-infos-business-name">{{_businessStoreResponse.business.businessName}}</label>
            <label class="header-infos-user-name">{{_businessStoreResponse.business.ownerName}}</label>
        }
    </div>

    <div class="row">
        <div class="col-md-8">
            @if(_checkoutResponse != undefined){
                <mat-accordion>
                    @for (businessCheckoutStruct of _checkoutResponse.listBusinessCheckoutStruct; track $index) {
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                            <mat-panel-title> {{businessCheckoutStruct.productBusinessName}} </mat-panel-title>
                            <mat-panel-description> 
                               
                                @if(businessCheckoutStruct.businessCheckoutDiscount){
                                    <span class="text-discount-strike">{{businessCheckoutStruct.totalPrice | currency:'BRL'}}</span>
                                    <span class="text-discount">{{businessCheckoutStruct.totalPrice - businessCheckoutStruct.businessCheckoutDiscount.discountValue | currency:'BRL'}}</span>
                                }
                                @else {
                                    {{businessCheckoutStruct.totalPrice | currency:'BRL'}}
                                }
                            </mat-panel-description>
                            <mat-action-row>
                                <button mat-button class="btn-primary btn-discount" (click)="openModalDiscount(businessCheckoutStruct)">
                                    Aplicar desconto
                                    <span class="material-symbols-outlined">arrow_forward</span>
                                </button>
                            </mat-action-row>
                            </mat-expansion-panel-header>
                            @if(businessCheckoutStruct.businessCheckoutDiscount){
                                <p class="info-discount">
                                    Desconto de {{businessCheckoutStruct.businessCheckoutDiscount.discountValue | currency: 'BRL'}} aplicado
                                    <br />
                                    {{businessCheckoutStruct.businessCheckoutDiscount.discountMotive}}
                                </p>
                            }

                            <p class="info-payment">
                                @for (avaliablePaymentMethod of businessCheckoutStruct.listAvaliablePaymentMethod; track $index) {
                                    {{avaliablePaymentMethod.paymentMethodName}} 
                                    
                                    @if(avaliablePaymentMethod.maxInstallments){
                                        {{avaliablePaymentMethod.maxInstallments}}X
                                        
                                        @if(avaliablePaymentMethod.installmentsFees){
                                            ({{avaliablePaymentMethod.installmentsFees}}% a.m.)
                                        }
                                    }

                                    @if(avaliablePaymentMethod.paymentMethodDiscount){
                                        desconto de {{avaliablePaymentMethod.paymentMethodDiscount | number:'1.2-2'}}% 
                                    }

                                    @if(businessCheckoutStruct.listAvaliablePaymentMethod.length-1 != $index){
                                        - 
                                    }
                                }

                               

                                <!-- Cartão 6x (2,5% a.m.) PIX 6x (5% a.m.) -->
                            </p>
                            
                            
                            @for (productCheckoutStruct of businessCheckoutStruct.listProductCheckoutStruct; track $index) {
                                    <app-checkout-item-resume [productCheckoutStruct]="productCheckoutStruct" [businessFactoryName]="businessCheckoutStruct.productBusinessName" (deleteCheckoutItem)="clickDeleteCheckoutItem($event)"></app-checkout-item-resume>
                            }
                        </mat-expansion-panel>
                    }
                </mat-accordion>
            }
            @else {
                <mat-accordion>
                    @for (item of returnArraySize(5); track $index) {
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>  
                                <app-loading-box [height]="'15'" [width]="'120'"></app-loading-box> 
                                </mat-panel-title>
                            <mat-panel-description>  
                                <app-loading-box [height]="'15'" [width]="'120'"></app-loading-box>
                            </mat-panel-description>
                            <mat-action-row>
                                <app-loading-box [height]="'25'" [width]="'120'"></app-loading-box>
                            </mat-action-row>
                            </mat-expansion-panel-header>
                            <p class="info-payment">
                                @for (avaliablePaymentMethod of item.listAvaliablePaymentMethod; track $index) {
                                    {{avaliablePaymentMethod.paymentMethodName}} 
                                    
                                    @if(avaliablePaymentMethod.maxInstallments){
                                        {{avaliablePaymentMethod.maxInstallments}}X
                                        
                                        @if(avaliablePaymentMethod.installmentsFees){
                                            ({{avaliablePaymentMethod.installmentsFees}}% a.m.)
                                        }
                                    }

                                    @if(avaliablePaymentMethod.paymentMethodDiscount){
                                        desconto de {{avaliablePaymentMethod.paymentMethodDiscount | number:'1.2-2'}}% 
                                    }

                                    @if(item.listAvaliablePaymentMethod.length-1 != $index){
                                        - 
                                    }
                                }
                                <!-- Cartão 6x (2,5% a.m.) PIX 6x (5% a.m.) -->
                            </p>
                        </mat-expansion-panel>
                    }
                </mat-accordion>
                
            }
        </div>
        <div class="col-md-4">
            <div class="infos-resume">
                <h3>Endereço</h3>
                <div class="row">
                    <div class="col-9">
                        @if(_businessStoreResponse != undefined){
                            <label class="bold"> {{_businessStoreResponse.business.businessName}} - {{_businessStoreResponse.business.ownerName}}</label>
                        }
                        @else {
                            <app-loading-box [height]="'15'" [width]="'180'" [marginTop]="'10'"></app-loading-box>
                        }
                        @if(_checkoutResponse != undefined){
                            <label>{{_checkoutResponse.checkout.street}}, {{_checkoutResponse.checkout.addressNumber}} {{_checkoutResponse.checkout.complement? " - " + _checkoutResponse.checkout.complement : ""}}</label>
                            <label>{{_checkoutResponse.checkout.city}} - {{_checkoutResponse.checkout.state}}</label>
                            <label>{{_checkoutResponse.checkout.zipcode}}</label>
                        }@else{ 
                            <app-loading-box [height]="'15'" [width]="'160'" [marginTop]="'10'"></app-loading-box> 
                            <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'10'"></app-loading-box> 
                            <app-loading-box [height]="'15'" [width]="'90'" [marginTop]="'10'"></app-loading-box> 
                            <app-loading-box [height]="'15'" [width]="'110'" [marginTop]="'10'"></app-loading-box> 
                        }
                    </div>
                    <div class="col-3">
                        @if(_checkoutResponse != undefined){
                            <button mat-mini-fab class="fab-action orange" aria-label="Editar essa peça" (click)="openModalAddress()">
                                <mat-icon>edit</mat-icon>
                            </button>
                        }
                    </div>
                </div>
                <div class="row padding-top">
                    <div class="col-9">
                        <label>Usar a logística de distribuição da fashion.app2One para esse pedido?</label>
                        <label class="small">Esse serviço gera um custo de R$ 129,90 que será descontado da sua comissão deste pedido. </label>
                    </div>
                    <div class="col-3 text-center">
                        <mat-slide-toggle></mat-slide-toggle>
                    </div>
                </div>
            </div>

            <div class="infos-resume">
                <h3>Resumo do pedido</h3>
                @if(_checkoutResponse != undefined){
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="infos-resume-label">Subtotal</label>
                        </div>
                        <div class="col-sm-8">
                            <label class="infos-resume-value">{{_checkoutResponse.totalPrice | currency:'BRL'}}</label>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="infos-resume-label">Desconto</label>
                        </div>
                        <div class="col-sm-8">
                            <label class="infos-resume-value">{{_checkoutResponse.totalDiscount | currency:'BRL'}}</label>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="infos-resume-label">Frete</label>
                        </div>
                        <div class="col-sm-8">
                            <label class="infos-resume-value small">Após recolher todos os pedidos nas confecções, o orçamento do frente fica disponível para aprovação</label>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="infos-resume-label total">Total do pedido</label>
                        </div>
                        <div class="col-sm-6">
                            <label class="infos-resume-value total">{{_checkoutResponse.totalPriceWithDiscount | currency:'BRL'}}</label>
                        </div>
                    </div>
                    
                    <button mat-button class="btn-primary btn-send-link" (click)="openModalBusinessStoreOwnerInfos()">
                        Enviar para o lojista
                        <span class="material-symbols-outlined">arrow_forward</span>
                    </button>
                }
                
            </div>
        </div>
    </div>
</div>