<div class="checkout-stats">  
    <div class="checkout-stats-row">
       <div class="row">
            <div class="col-2 col-sm-1">
                <div class="vertical-line"></div>
                <div class="vertical-aling-out">
                     <div class="vertical-aling-inner">
                        <div class="circle" [ngClass]="{'disable': isActive == false, 'current-status': currentStatus == true}">
                            <span class="material-symbols-outlined">check</span>
                        </div>
                     </div>
                </div>
            </div>
            <div class="col-10 col-sm-11">
                <div class="checkout-stats-container">
                    <span class="title">
                        {{statusName}}
                        <div class="date">{{dateTimeInclusionStatus | date:'dd/MM/yyyy'}}</div>
                    </span>
                    @if(isActive){
                        <span class="light">{{statusDetails}}</span>
                    }
                </div>
            </div>
        </div>
    </div>
</div>