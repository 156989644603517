import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { LoadingBoxComponent } from "../../../shared/component/loading-box/loading-box.component";
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from "../../../shared/component/empty-list/empty-list.component";
import { MatDialog } from '@angular/material/dialog';
import { SearchBusinessStoreRequest } from '../../../shared/services/requests/business-user/search-business-store.request';
import { BusinessService } from '../../../shared/services/API/business-user/business.service';
import { SearchBusinessStoreResponse } from '../../../shared/services/responses/business-user/search-business-store.response';

@Component({
  selector: 'app-business-store-list',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
  ],
  templateUrl: './business-store-list.component.html',
  styleUrl: './business-store-list.component.css'
})
export class BusinessStoreListComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private businessService: BusinessService,
    public dialog: MatDialog) {
  }

  public model: FormGroup;
  public isLoading: boolean = false;
  public _page: number = 0;
  public _searchBusinessStoreResponse: SearchBusinessStoreResponse;
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1];
  public _isLastPage: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      searchText: [''],
    });

    this.clickSearch();

  }

  search() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let searchBusinessStoreRequest: SearchBusinessStoreRequest = new SearchBusinessStoreRequest();

    searchBusinessStoreRequest.searchText = this.model.get('searchText')?.value
    searchBusinessStoreRequest.page = this._page;

    this.businessService.SearchConsultorStores(searchBusinessStoreRequest).subscribe({
      next: (searchBusinessStoreResponse: SearchBusinessStoreResponse) => {
        if (searchBusinessStoreResponse.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", searchBusinessStoreResponse.errorDescription, AlertType.error);
          return;
        }

        this._isLastPage = true;

        if (this._page == 0) {
          this._searchBusinessStoreResponse = new SearchBusinessStoreResponse();
        }        

        if (searchBusinessStoreResponse.listBusinessStoreStruct?.length > 0) {
          this._searchBusinessStoreResponse.listBusinessStoreStruct.push(...searchBusinessStoreResponse.listBusinessStoreStruct);
          this._isLastPage = false;
        }        

        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickSearch() {
    this._page = 0;
    this.search();
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }
}
