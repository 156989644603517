import { Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { FactoryOrderSearchRequest } from '../../../../shared/services/requests/orchestator-checkout/factory-order-search.request';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { ContentObserver } from '@angular/cdk/observers';

@Component({
  selector: 'app-oder-filter',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDatepickerModule
  ],
  templateUrl: './oder-filter.component.html',
  styleUrl: './oder-filter.component.css',
  providers: [provideNativeDateAdapter()],
})
export class OderFilterComponent implements OnInit {

  @Input() showFilter:boolean;
  @Input() factoryOrderSearchRequest:FactoryOrderSearchRequest;
  @Output() showFilterFalse =  new EventEmitter<number>()
  @Output() search =  new EventEmitter<number>()

  public model: FormGroup;

  constructor(
    private formBuilder: FormBuilder) {
  }
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idCheckout:[''],
      searchConsultorName: [''],
      searchStoreOwnerName: [''],
      startDate: [''],
      endDate: [''],
    });

  }

  post(){
    if(this.model.get("idCheckout")?.value)
      this.factoryOrderSearchRequest.idCheckout = Number(this.model.get("idCheckout")?.value);
    if(this.model.get("startDate")?.value)
      this.factoryOrderSearchRequest.startDate = this.model.get("startDate")?.value;
    if(this.model.get("endDate")?.value)
      this.factoryOrderSearchRequest.endDate = this.model.get("endDate")?.value;

    this.factoryOrderSearchRequest.searchConsultorName = this.model.get("searchConsultorName")?.value;
    this.factoryOrderSearchRequest.searchStoreOwnerName = this.model.get("searchStoreOwnerName")?.value;

    this.showFilterFalse.emit();
    this.search.emit();
  }

  clear(){
    this.model.get("idCheckout")?.setValue('');
    this.model.get("searchConsultorName")?.setValue('');
    this.model.get("searchStoreOwnerName")?.setValue('');
    this.model.get("startDate")?.setValue('');
    this.model.get("endDate")?.setValue('');
  }

  clickContainerOut(){
    this.showFilterFalse.emit();
  }
}
