import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { LoadingBoxComponent } from "../../../shared/component/loading-box/loading-box.component";
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from "../../../shared/component/empty-list/empty-list.component";
import { MatDialog } from '@angular/material/dialog';
import { Masks, MaskService } from '../../../shared/services/mask.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { UserService } from '../../../shared/services/API/business-user/user.service';
import { UserResponse } from '../../../shared/services/responses/business-user/user.response';
import { CreateAndUpdateUserRequest } from '../../../shared/services/requests/business-user/create-and-update-user.request';
import { ProfileService } from '../../../shared/services/API/business-user/profile.service';
import { GetLookupProfileResponse } from '../../../shared/services/responses/business-user/get-lookup-profile.response';
import { Profile } from '../../../shared/services/models/business-user/profile.model';
import { UserProfile } from '../../../shared/services/models/business-user/user-profile.model';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-user-register',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCheckboxModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
    NgxMaskDirective,
  ],
  providers:[
    provideNgxMask()
  ],
  templateUrl: './user-register.component.html',
  styleUrl: './user-register.component.css'
})
export class UserRegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private dialog: MatDialog,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,) {
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading: boolean = false;
  public isFirstLoading: boolean = true;
  public _idUser: number | null = null;
  public _page: number = 0;
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1, 1];
  public _hidePassword: boolean = true;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    this.createDefaultInstanceOfForm();

    this.tryGetUrlIdUser();

    this.getDataToDisplayInComponent();
  }

  createDefaultInstanceOfForm(): void {
    this.model = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      cpf: ['', Validators.required],
      temporaryPassword: [''],
      profiles: new FormArray([])
    });
  }

  tryGetUrlIdUser(): void {
    let idUserUrl:string = this.activatedRoute.snapshot.params["idUser"];

    if(idUserUrl)
      this._idUser = Number(idUserUrl);    
  }

  async getDataToDisplayInComponent(): Promise<void> {
    let listProfile: Profile[] | null = await this.getListProfile();

    this.populateProfileFormArray(listProfile);    

    if (this.isUpdateUser()) 
      this.getUser();    
    else
      this.setDataToCreateFlow();
  }

  isUpdateUser(): boolean {
    return this._idUser != null;  
  }

  getUser(): void{
    if (!this._idUser) {
      this.setDataToCreateFlow();
      return;
    }      

    this.userService.getUserToRegisterPage(this._idUser).subscribe({
      next:(response:UserResponse)=>{
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        
        this.populateUserForm(response);
        this.isFirstLoading = false;

      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isFirstLoading = false;
      }
    });
  }

  async getListProfile(): Promise<Profile[] | null> {
    return new Promise((resolve) => {  
      this.profileService.getAllProfile().subscribe({
        next:(response: GetLookupProfileResponse)=>{
          if (response.isError) {
            this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
            resolve(null);
          }

          resolve(response.listProfile);

        },
        error: (error)=>{
          this.alertService.show("Erro inesperado", error, AlertType.error);
          resolve(null);
        }
      });
    })
  }

  populateProfileFormArray(listProfile: Profile[] | null): void{
    if (!listProfile)
      return;

    listProfile.forEach((item, index) => {
      (this.model.controls['profiles'] as FormArray).push(
        this.formBuilder.group({
            profileName: [item.profileName],
            profileDescription: [item.profileDescription],
            idProfile: [item.idProfile],
            isChecked: [false],
          })
      )
    });
  }

  setDataToCreateFlow(): void {
    this.model.get("temporaryPassword")?.setValidators(Validators.required);
    this.isFirstLoading = false;
  }

  populateUserForm(response: UserResponse): void {
    if (!response.user)
      return;

    this.model.get('name')?.setValue(response.user.name);
    this.model.get('email')?.setValue(response.user.email);
    this.model.get('phone')?.setValue(response.user.phone);
    this.model.get('cpf')?.setValue(response.user.cpf);

    this.populateProfilesWithUserProfiles(response.listUserProfile);

  }

  populateProfilesWithUserProfiles(listUserProfile: UserProfile[]) {
    if (!listUserProfile)
      return;

    const profilesFormArray = this.model.get('profiles') as FormArray;
  
    listUserProfile.forEach(userProfile => {
      const matchingProfile = profilesFormArray.controls.find(
        (control) => control.get('idProfile')?.value === userProfile.idProfile
      );
  
      if (matchingProfile) {
        matchingProfile.get('isChecked')?.setValue(true);
      }
    });
  }

  submit(): void{
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    let request: CreateAndUpdateUserRequest = this.mapFormToRequest();

    if (this.isUpdateUser()) 
      this.updateUser(request);    
    else 
      this.createUser(request);

  }

  mapFormToRequest(): CreateAndUpdateUserRequest {
    let request: CreateAndUpdateUserRequest = new CreateAndUpdateUserRequest();

    request.name = this.model.get('name')?.value;
    request.cpf = this.model.get('cpf')?.value;
    request.email = this.model.get('email')?.value;
    request.phone = this.model.get('phone')?.value;
    request.password = this.model.get('temporaryPassword')?.value;

    request.listIdUserProfile = this.getSelectedProfiles();

    return request;
  }

  getSelectedProfiles(): number[] {
    const profilesArray = this.model.get('profiles') as FormArray;
    return profilesArray.controls
      .filter((control) => control.get('isChecked')?.value === true)
      .map((control) => Number(control.value?.idProfile))
      .filter((id) => !isNaN(id));
  }

  createUser(request: CreateAndUpdateUserRequest): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.userService.postUser(request).subscribe({
      next:(returnStruct:ReturnStruct)=>{
        if (returnStruct.isError) {
          this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.isLoading = false;
        this.alertService.show("Sucesso", "Salvo com sucesso!", AlertType.success);
        this.backToUserList();

      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  updateUser(request: CreateAndUpdateUserRequest): void {
    if (this.isLoading || !this._idUser) {
      return;
    }

    this.isLoading = true;
    
    request.idUser = this._idUser;

    this.userService.putUser(request).subscribe({
      next:(returnStruct:ReturnStruct)=>{
        if (returnStruct.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
          return;
        }
        
        this.isLoading = false;
        this.alertService.show("Sucesso", "Alterado com sucesso!", AlertType.success);
        this.backToUserList();

      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  openModalToConfirmDelete(): void{
    if (this.isLoading || !this._idUser) {
      this.alertService.show("Alerta", "Não é possível excluir.", AlertType.warning);    
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Alerta",
        description: "Deseja excluir esse usuário? Essa ação será irreversível.",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.deleteUser();
      }
    });
  }

  deleteUser(): void{
    if (this.isLoading || !this._idUser) {
      return;
    }

    this.isLoading = true;

    this.userService.deleteUser(this._idUser).subscribe({
      next: (response:ReturnStruct) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show("Sucesso", "Deletado com sucesso!", AlertType.success);     
        this.backToUserList();    

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  backToUserList(): void{
    if (this.isLoading)
      return;

    this.router.navigate(['/factory/user']);
  }
 
}
