import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FactoryInventoryProductResponse } from '../../../../shared/services/responses/inventory/factory-inventory-product.response';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NewProductGridSizeStruct } from '../../../../shared/services/structs/inventory/new-product-grid-size-Struct';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { LoadingBoxComponent } from '../../../../shared/component/loading-box/loading-box.component';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ProductGridStruct } from '../../../../shared/services/structs/inventory/productgrid.struct';
import { ProductGridService } from '../../../../shared/services/API/inventory/product-grid.service';
import { ProductGridRequest } from '../../../../shared/services/requests/inventory/product-grid.request';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-section-product-grid',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    RouterLink
  ],
  templateUrl: './section-product-grid.component.html',
  styleUrl: './section-product-grid.component.css'
})
export class SectionProductGridComponent implements OnInit{
  @Input() _factoryInventoryProductResponse:FactoryInventoryProductResponse;
  @Output() reloadProduct =  new EventEmitter<any>()

  public model: FormGroup;
  public isLoading:boolean = false;

  constructor(private formBuilder: FormBuilder, 
    private productGridService:ProductGridService,
    private alertService: AlertService,
  ){ 
  }

  ngOnInit(): void {

    this.model = new FormGroup({
      listProductGridStruct: new FormArray([
        this.initListProductGridStruct(null)
      ])
    })
    
    this.populate();
    
  }

  initListProductGridStruct(productGridStruct:ProductGridStruct|null){
    return new FormGroup({
      idProductGridColor: new FormControl(productGridStruct? productGridStruct.idProductGridColor : ''),
      colorName: new FormControl(productGridStruct? productGridStruct.colorName : '',Validators.required),
      colorHex: new FormControl(productGridStruct? productGridStruct.colorHex : '#eadcc5',Validators.required),
      listNewProductGridSizeStruct: new FormArray([
        this.initListNewProductGridSizeStruct(null),
      ])
    });
  }

  initListNewProductGridSizeStruct(newProductGridSizeStruct:NewProductGridSizeStruct|null){
    return new FormGroup({
      idProductGridSize: new FormControl(newProductGridSizeStruct? newProductGridSizeStruct.idProductGridSize : ''),
      idProductGridColor: new FormControl(newProductGridSizeStruct? newProductGridSizeStruct.idProductGridColor : ''),
      sizeName: new FormControl(newProductGridSizeStruct? newProductGridSizeStruct.sizeName : '',Validators.required),
      currentPrice: new FormControl(newProductGridSizeStruct? newProductGridSizeStruct.currentPrice : '',Validators.required),
      currentQuantity: new FormControl(newProductGridSizeStruct? newProductGridSizeStruct.currentQuantity : '',Validators.required)
    });
  }

  addListProductGridStruct(productGridStruct:ProductGridStruct|null, index:number|null) {
    if(index == null)
      (this.model.controls['listProductGridStruct'] as FormArray).push(this.initListProductGridStruct(productGridStruct));
    else
      (this.model.controls['listProductGridStruct'] as FormArray).insert(index+1,this.initListProductGridStruct(productGridStruct));
  }

  addListNewProductGridSizeStruct(i:number, newProductGridSizeStruct:NewProductGridSizeStruct|null, index:number|null) {
    const control = <FormArray>(this.model.get('listProductGridStruct') as FormArray)?.controls[i].get('listNewProductGridSizeStruct');

    if(index == null)
      control.push(this.initListNewProductGridSizeStruct(newProductGridSizeStruct));
    else
      control.insert(index+1, this.initListNewProductGridSizeStruct(newProductGridSizeStruct));
    
  }

  getListProductGridStruct(form:any) {
    //console.log(form.get('sections').controls);
    return form.controls.listProductGridStruct.controls;
  }

  getListNewProductGridSizeStruct(form:any) {
    //console.log(form.controls.questions.controls);
     return form.controls.listNewProductGridSizeStruct.controls;
   }

  getProductGridStructListControls(){
    return (this.model.get("productGridStructList") as FormArray)?.controls as FormGroup[]
  }

  getlistNewProductGridSizeStructControls(index:number){
    return ((this.getProductGridStructListControls()[index]).get("listNewProductGridSizeStruct") as FormArray)?.controls as FormGroup[]
  }

  removeProductGridStructList(i:number){
    (this.model.controls['listProductGridStruct'] as FormArray).removeAt(i);
  }

  removeNewProductGridSizeStruct(i:number, j:number){
    ((this.model.controls['listProductGridStruct'] as FormArray)?.controls[i].get('listNewProductGridSizeStruct') as FormArray).removeAt(j)
 }

 populate(){
  if(this._factoryInventoryProductResponse.listProductGridStruct){
    let index:number = 0;
    this._factoryInventoryProductResponse.listProductGridStruct.forEach((productGridStruct:ProductGridStruct) => {
      this.addListProductGridStruct(productGridStruct, null);
      index++;
      productGridStruct.listNewProductGridSizeStruct.forEach((newProductGridSizeStruct:NewProductGridSizeStruct) => {
        this.addListNewProductGridSizeStruct(index, newProductGridSizeStruct, null);
      });

      if(productGridStruct.listNewProductGridSizeStruct.length > 0)
        this.removeNewProductGridSizeStruct(index, 0);
      
    });
  
    if(this._factoryInventoryProductResponse.listProductGridStruct.length > 0)
      this.removeProductGridStructList(0);
  }
}


  submit(){
    if(this.model.invalid || this.isLoading)
      return;

    let productGridRequest:ProductGridRequest = new ProductGridRequest();
    productGridRequest.idProduct = this._factoryInventoryProductResponse.idProduct;
    productGridRequest.productGridStructList = [];

    
    this.model.get('listProductGridStruct')?.value.forEach((itemColor:any) => {
      let productGridStructList:ProductGridStruct = new ProductGridStruct(); 

      productGridStructList.colorHex = itemColor.colorHex;
      productGridStructList.colorName = itemColor.colorName;
      productGridStructList.idProductGridColor = itemColor.idProductGridColor? Number(itemColor.idProductGridColor) : null;
      productGridStructList.listNewProductGridSizeStruct = [];

      itemColor.listNewProductGridSizeStruct.forEach((itemSize:any) => {
        let newProductGridSizeStruct:NewProductGridSizeStruct = new NewProductGridSizeStruct();

        newProductGridSizeStruct.currentPrice = itemSize.currentPrice;
        newProductGridSizeStruct.currentQuantity = itemSize.currentQuantity;
        newProductGridSizeStruct.idProductGridColor = itemSize.idProductGridColor? Number(itemSize.idProductGridColor) : null;
        newProductGridSizeStruct.idProductGridSize = itemSize.idProductGridSize? Number(itemSize.idProductGridSize) : null;
        newProductGridSizeStruct.sizeName = itemSize.sizeName;

        productGridStructList.listNewProductGridSizeStruct.push(newProductGridSizeStruct);
      });

      productGridRequest.productGridStructList.push(productGridStructList);

    });


    this.isLoading = true;
    this.productGridService.post(productGridRequest).subscribe({
      next: (returnStruct:ReturnStruct)=>{
        this.isLoading = false;

        if (returnStruct.isError) {
          this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Grade atualizda com sucesso", AlertType.success);        
        this.reloadProduct.emit();
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
    }})
  }
}
