import { Routes } from '@angular/router';
import { AuthComponent } from './auth/pages/auth/auth.component';
import { HomeComponent } from './consult/pages/home/home.component';
import { AuthGuard } from './auth-guard.service';
import { SearchComponent } from './consult/pages/search/search.component';
import { SearchResumeCheckoutComponent } from './consult/pages/search-resume-checkout/search-resume-checkout.component';
import { PasswordRecoveryComponent } from './auth/pages/password-recovery/password-recovery.component';
import { PassordChangeComponent } from './auth/pages/passord-change/passord-change.component';
import { SearchCheckoutDoneComponent } from './consult/pages/search-checkout-done/search-checkout-done.component';
import { CheckoutListComponent } from './consult/pages/checkout-list/checkout-list.component';
import { CheckoutDetailsComponent } from './consult/pages/checkout-details/checkout-details.component';
import { DashboardOrdersComponent } from './factory/pages/dashboard-orders/dashboard-orders.component';
import { BusinessStoreListComponent } from './consult/pages/business-store-list/business-store-list.component';
import { BusinessStoreRegisterComponent } from './consult/pages/business-store-register/business-store-register.component';
import { BusinessFactoryListComponent } from './consult/pages/business-factory-list/business-factory-list.component';
import { BusinessFactoryRegisterComponent } from './consult/pages/business-factory-register/business-factory-register.component';
import { BusinessConsultorListComponent } from './factory/pages/business-consultor-list/business-consultor-list.component';
import { BusinessConsultorRegisterComponent } from './factory/pages/business-consultor-register/business-consultor-register.component';
import { UserListComponent } from './factory/pages/user-list/user-list.component';
import { UserRegisterComponent } from './factory/pages/user-register/user-register.component';
import { InventoryListComponent } from './factory/pages/inventory-list/inventory-list.component';
import { InventoryRegisterComponent } from './factory/pages/inventory-register/inventory-register.component';
import { CheckoutHistoryListComponent } from './store/pages/checkout-history-list/checkout-history-list.component';
import { FirstLoginToUserStoreComponent } from './auth/pages/first-login-to-user-store/first-login-to-user-store.component';
import { CheckoutDetailsToStoreComponent } from './store/pages/checkout-details-to-store/checkout-details-to-store.component';

export const routes: Routes = [
    { path: '', component: AuthComponent, pathMatch: 'full' },
    { path: 'password-recovery', component: PasswordRecoveryComponent },
    { path: 'first-login-to-user-store', component: FirstLoginToUserStoreComponent },
    { path: 'change-password/:token', component: PassordChangeComponent },
    { path: 'consult/home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'consult/search', component: SearchComponent, canActivate: [AuthGuard] },
    { path: 'consult/search/resume', component: SearchResumeCheckoutComponent, canActivate: [AuthGuard] },
    { path: 'consult/search/done/:idCheckout', component: SearchCheckoutDoneComponent, canActivate: [AuthGuard] },
    { path: 'consult/checkout', component: CheckoutListComponent, canActivate: [AuthGuard] },
    { path: 'consult/checkout-details/:idCheckout', component: CheckoutDetailsComponent, canActivate: [AuthGuard] },
    { path: 'consult/business-store', component: BusinessStoreListComponent, canActivate: [AuthGuard] },
    { path: 'consult/business-store/register', component: BusinessStoreRegisterComponent, canActivate: [AuthGuard] },
    { path: 'consult/business-factory', component: BusinessFactoryListComponent, canActivate: [AuthGuard] },
    { path: 'consult/business-factory/register', component: BusinessFactoryRegisterComponent, canActivate: [AuthGuard] },
    { path: 'factory/home', component: DashboardOrdersComponent, canActivate: [AuthGuard] },
    { path: 'factory/business-consultor', component: BusinessConsultorListComponent, canActivate: [AuthGuard] },
    { path: 'factory/business-consultor/register', component: BusinessConsultorRegisterComponent, canActivate: [AuthGuard] },
    { path: 'factory/user', component: UserListComponent, canActivate: [AuthGuard] },
    { path: 'factory/user/register', component: UserRegisterComponent, canActivate: [AuthGuard] },
    { path: 'factory/inventory', component: InventoryListComponent, canActivate: [AuthGuard] },
    { path: 'factory/inventory/register', component: InventoryRegisterComponent, canActivate: [AuthGuard] },
    { path: 'store/checkout', component: CheckoutHistoryListComponent, canActivate: [AuthGuard] },
    { path: 'store/checkout-details/:idCheckout', component: CheckoutDetailsToStoreComponent, canActivate: [AuthGuard] },
];
