import { Component } from '@angular/core';

@Component({
  selector: 'app-empty-col',
  standalone: true,
  imports: [],
  templateUrl: './empty-col.component.html',
  styleUrl: './empty-col.component.css'
})
export class EmptyColComponent {

}
