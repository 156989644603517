import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ProductSearchLookupResponse } from '../../responses/inventory/product-search-lookup.response';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from '../../structs/return.struct';
import { CheckoutDiscountRequest } from '../../requests/checkout/checkout-discount.request';
import { SendToClientRequest } from '../../requests/checkout/send-to-client.request';
import { SearchCheckoutPaginatedForStoreResponse } from '../../responses/checkout/search-checkout-paginated-for-store.response';
import { SearchCheckoutPaginatedForStoreRequest } from '../../requests/checkout/search-checkout-paginated-for-store.request';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public discount(checkoutDiscountRequest:CheckoutDiscountRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ProductSearchLookupResponse>(environment.urlApiCheckout + "Checkout/Discount", checkoutDiscountRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public sendToClient(sendToClientRequest:SendToClientRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ProductSearchLookupResponse>(environment.urlApiCheckout + "Checkout/SendToClient", sendToClientRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public searchCheckoutPaginatedForStore(request: SearchCheckoutPaginatedForStoreRequest): Observable<SearchCheckoutPaginatedForStoreResponse> {
    return this.httpClient.post<SearchCheckoutPaginatedForStoreResponse>(environment.urlApiCheckout + "Checkout/SearchCheckoutPaginatedForStore", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}
