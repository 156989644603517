import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from '../../structs/return.struct';
import { UserForFirstLoginUpdateRequest } from '../../requests/business-user/user-for-first-login-update.request';
import { UserForFirstLoginResponse } from '../../responses/business-user/user-for-first-login.response';

@Injectable({
  providedIn: 'root'
})
export class UserUnauthorizedService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public getUserForFirstLogin(idUser:number): Observable<UserForFirstLoginResponse> {
    return this.httpClient.get<UserForFirstLoginResponse>(environment.urlApiBusinessUser + "UserUnauthorized/getUserForFirstLogin/idUser/" + idUser)
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateUserForFirstLogin(request: UserForFirstLoginUpdateRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessUser + "UserUnauthorized/updateUserForFirstLogin", request)
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
