import { Component, OnInit } from '@angular/core';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { FactoryInventoryProductService } from '../../../shared/services/API/inventory/factory-inventory-product.service.';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { LoadingBoxComponent } from '../../../shared/component/loading-box/loading-box.component';
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from '../../../shared/component/empty-list/empty-list.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { InventoryItemComponent } from '../inventory-list/inventory-item/inventory-item.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Editor, NgxEditorModule, Toolbar, Validators } from 'ngx-editor';
import { MatSelectModule } from '@angular/material/select';
import { FactoryInventoryProductResponse } from '../../../shared/services/responses/inventory/factory-inventory-product.response';
import { ProductBaseService } from '../../../shared/services/API/inventory/product-base.service.';
import { ProductBaseRequest } from '../../../shared/services/requests/inventory/product-base.request';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { ProductBaseResponse } from '../../../shared/services/responses/inventory/product-base.response';
import { ProductSearchService } from '../../../shared/services/API/inventory/product-search.service';
import { ProductSearchLookupResponse } from '../../../shared/services/responses/inventory/product-search-lookup.response';
import { Collection } from '../../../shared/services/models/inventory/collection.model';
import { Category } from '../../../shared/services/models/inventory/category.model';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SectionProductImagesComponent } from "./section-product-images/section-product-images.component";
import { SectionProductGridComponent } from './section-product-grid/section-product-grid.component';

@Component({
  selector: 'app-inventory-register',
  standalone: true,
  imports: [
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    RouterLink,
    InventoryItemComponent,
    MatSlideToggleModule,
    NgxEditorModule,
    MatSelectModule,
    SectionProductImagesComponent,
    SectionProductGridComponent
],
  templateUrl: './inventory-register.component.html',
  styleUrl: './inventory-register.component.css'
})
export class InventoryRegisterComponent implements OnInit {

  constructor(
    private router: Router,
    public factoryInventoryProductService:FactoryInventoryProductService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private productBaseService:ProductBaseService,
    private productSearchService:ProductSearchService,
    public dialog: MatDialog){

      let idProductUrl:string = this.activatedRoute.snapshot.params["idProduct"];
      if(idProductUrl){
        this._idProduct = Number(idProductUrl);    
        this.loadProduct();
      }
      else{
        this._factoryInventoryProductResponse = new FactoryInventoryProductResponse();
      }
        
  }

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];  
  
  public isLoading:boolean = false;
  public model: FormGroup;
  public _idProduct:number;
  public _factoryInventoryProductResponse:FactoryInventoryProductResponse;
  public _listCategoryModel: Category[] = [];
  public _listCollectionModel: Collection[] = [];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      productName:['', [Validators.required]],
      productCode:[''],
      htmlDescription:['', [Validators.required]],
      isImmediateDelivery:[true],
      idCollection:[''],
      idCategory:[''],
    });

    this.editor = new Editor();

    this.populate(); 
  }

  populate(){
    this.productSearchService.lookup().subscribe({
      next: (productSearchLookupResponse:ProductSearchLookupResponse)=>{
        if (productSearchLookupResponse.isError) {
          this.alertService.show("Erro inesperado", productSearchLookupResponse.errorDescription, AlertType.error);
          return;
        }

        this._listCategoryModel = productSearchLookupResponse.listCategory;
        this._listCollectionModel = productSearchLookupResponse.listCollection;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
    }})
  }

  loadProduct(){
    this.factoryInventoryProductService.get(this._idProduct).subscribe({
      next:(factoryInventoryProductResponse:FactoryInventoryProductResponse)=>{
        this.isLoading = false;
        if (factoryInventoryProductResponse.isError) { 
          this.alertService.show("Erro inesperado", factoryInventoryProductResponse.errorDescription, AlertType.error);
          return;
        }

        this._factoryInventoryProductResponse = factoryInventoryProductResponse;

        this.model.get('productName')?.setValue(factoryInventoryProductResponse.productName)
        this.model.get('productCode')?.setValue(factoryInventoryProductResponse.productCode)
        this.model.get('isImmediateDelivery')?.setValue(factoryInventoryProductResponse.isImmediateDelivery)
        this.model.get('htmlDescription')?.setValue(factoryInventoryProductResponse.htmlDescription)
        this.model.get('idCollection')?.setValue(factoryInventoryProductResponse.idCollection)
        this.model.get('idCategory')?.setValue(factoryInventoryProductResponse.idCategory)
      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
    
  }

  saveProductBase(){

    let productBaseRequest:ProductBaseRequest = new ProductBaseRequest();
    
    if(this._idProduct)
      productBaseRequest.idProduct = this._idProduct;

    productBaseRequest.productName =  this.model.get('productName')?.value;
    productBaseRequest.productCode =  this.model.get('productCode')?.value;
    productBaseRequest.isImmediateDelivery =  this.model.get('isImmediateDelivery')?.value;
    productBaseRequest.htmlDescription =  this.model.get('htmlDescription')?.value;
    productBaseRequest.idCollection =  this.model.get('idCollection')?.value? Number(this.model.get('idCollection')?.value) : null;
    productBaseRequest.idCategory =  this.model.get('idCategory')?.value? Number(this.model.get('idCategory')?.value) : null;

    this.isLoading = true;

    this.productBaseService.post(productBaseRequest).subscribe({
      next:(productBaseResponse:ProductBaseResponse)=>{
        this.isLoading = false;
        if (productBaseResponse.isError) { 
          this.alertService.show("Erro inesperado", productBaseResponse.errorDescription, AlertType.error);
          return;
        }
       
        if(this._idProduct){
          this.alertService.show("Sucesso", "Dados base do produto foi alterado com sucesso", AlertType.success);
        }
        else{
          this.alertService.show("Sucesso", "Produto criado com sucesso", AlertType.success);
          this.router.navigate(['/factory/inventory/register',{ idProduct: productBaseResponse.idProduct }]);
          this._idProduct = productBaseResponse.idProduct;  
          this.loadProduct();
        }
          
      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  


}
