
@if(checkoutResponse == undefined){
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>

    <div class="checkout-itens" [ngClass]="{'checkout-itens-show': showCheckoutItens}" >
        <div class="business">
            <div class="business-header">
                <app-loading-box [height]="'30'" [width]="'230'"></app-loading-box>
                <app-loading-box [height]="'20'" [width]="'40'" [marginTop]="'10'" [display]="'inline-block'" ></app-loading-box>
                <app-loading-box [height]="'20'" [width]="'60'" [marginTop]="'10'" [display]="'inline-block'" [float]="'right'"></app-loading-box>
                <app-loading-box [height]="'30'" [display]="'block'"></app-loading-box>
            </div>
            <div class="business-itens">                      
                <app-checkout-item></app-checkout-item>
                <app-checkout-item></app-checkout-item>
                <app-checkout-item></app-checkout-item>
                <app-checkout-item></app-checkout-item>
            </div>
        </div> 
    </div>

}

@if(checkoutResponse != undefined && (checkoutResponse.checkout == null || checkoutResponse.listBusinessCheckoutStruct.length == 0)){
    <div class="empty">
        <p>Seu pedido ainda está vazio</p>  
        <img src="assets/images/empty-box.jpg" />
        <p>Adicone o primeiro produto e comece a criar um pedido incrível para seu lojista</p>
    </div>
    
}
@else if(checkoutResponse != undefined && checkoutResponse.checkout != null ){
    <div class="checkout">
        <div class="resume">
            <label class="resume-price">{{checkoutResponse.totalPrice | currency:'BRL'}}</label>
            <label class="resume-itens">{{checkoutResponse.totalQuantity}} peças</label>

            <button mat-flat-button type="button" class="btn-primary btn-buy" (click)="cliclCloseCheckout()">
                Finalizar pedido
            </button>
        </div>

        <div class="resume-mobile">
            <button mat-flat-button type="button" class="btn-primary btn-buy" (click)="cliclCloseCheckout()">
                Finalizar pedido <span class="btn-itens"> - {{checkoutResponse.totalQuantity}} peças</span>
            </button>
            <label class="resume-price-mobile">{{checkoutResponse.totalPrice | currency:'BRL'}}</label>
            <button mat-button class="btn-show-checkout-itens" (click)="clickShowCheckoutItens()">
                @if(showCheckoutItens){
                    <span class="material-symbols-outlined">
                        visibility_off
                    </span>
                    
                }
                @if(!showCheckoutItens){
                    <span class="material-symbols-outlined">
                        visibility
                    </span>
                }
                
                {{showCheckoutItens?"Esconder o pedido":"Ver o pedido"}}
            </button>
        </div>

        <div class="checkout-itens" [ngClass]="{'checkout-itens-show': showCheckoutItens}" >
            @if(checkoutResponse.listBusinessCheckoutStruct != null && checkoutResponse.listBusinessCheckoutStruct != undefined){
                @for (businessFactory of checkoutResponse.listBusinessCheckoutStruct; track $index) {
                    <div class="business">
                        <div class="business-header">
                            <label class="business-header-name">{{businessFactory.productBusinessName}}</label>
                            <label class="business-header-itens">{{businessFactory.totalQuantity}} peças</label>
                            <label class="business-header-price">{{businessFactory.totalPrice | currency: 'BRL'}}</label>
                        </div>
                        <div class="business-itens">
                            @for (product of businessFactory.listProductCheckoutStruct; track $index) {
                                <app-checkout-item [productCheckoutStruct]="product" [businessFactoryName]="businessFactory.productBusinessName" (deleteCheckoutItem)="clickDeleteCheckoutItem($event)" (editCheckoutItem)="clickEditCheckoutItem($event)"></app-checkout-item>
                            }
                        </div>
                    </div>
                }
               
            }
        </div>
    </div>
}