import { Component, Input } from '@angular/core';
import { LoadingBoxComponent } from '../../../../shared/component/loading-box/loading-box.component';

@Component({
  selector: 'app-order-status-bullet',
  standalone: true,
  imports: [
    LoadingBoxComponent
  ],
  templateUrl: './order-status-bullet.component.html',
  styleUrl: './order-status-bullet.component.css'
})
export class OrderStatusBulletComponent {
  @Input() checkoutStatusName:string;
  @Input() idCheckoutStatus:number;

  constructor(){

  }
}
