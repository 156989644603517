import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.responde';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OrderCardComponent } from "./order-card/order-card.component";
import { EmptyColComponent } from "./empty-col/empty-col.component";
import { OderFilterComponent } from "./oder-filter/oder-filter.component";
import { FactoryOrderSearchRequest } from '../../../shared/services/requests/orchestator-checkout/factory-order-search.request';
import { MatDialog } from '@angular/material/dialog';
import { ModalOrderComponent } from './modal-order/modal-order.component';
import { FactoryOrderService } from '../../../shared/services/API/orchestator-checkout/factory-order.service';
import { FactoryOrderSearchResponse } from '../../../shared/services/requests/orchestator-checkout/factory-order-search.response';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { FactoryOrderSearchItemStruct } from '../../../shared/services/structs/orchestrator-checkout/factory-order-search-item.struct';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dashboard-orders',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    MatButtonModule,
    MatIconModule,
    OrderCardComponent,
    EmptyColComponent,
    OderFilterComponent,
    MatIconModule,
    CdkDropListGroup, 
    CdkDropList, 
    CdkDrag,
    CommonModule
  ],
  templateUrl: './dashboard-orders.component.html',
  styleUrl: './dashboard-orders.component.css'
})
export class DashboardOrdersComponent implements OnInit{
  
  public userLoginResponse:UserLoginResponse;
  public factoryOrderSearchRequest:FactoryOrderSearchRequest;
  public showFilter:boolean = false;
  public _factoryOrderSearchResponse:FactoryOrderSearchResponse | undefined;
  
  constructor(public authService:AuthService,
    public dialog: MatDialog,
    public factoryOrderService:FactoryOrderService,
    private alertService: AlertService,
  ){
  }

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();
    this.factoryOrderSearchRequest = new FactoryOrderSearchRequest();

    this.search();
    
  }

  search(){
    this._factoryOrderSearchResponse = undefined;
    this.factoryOrderService.get(this.factoryOrderSearchRequest).subscribe({

      next:(factoryOrderSearchResponse:FactoryOrderSearchResponse)=>{
        if (factoryOrderSearchResponse.isError) {
          this.alertService.show("Erro inesperado", factoryOrderSearchResponse.errorDescription, AlertType.error);
          return;
        }
        
        this._factoryOrderSearchResponse = factoryOrderSearchResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickShowFilter():void{
    this.showFilter = true;
  }

  clickOpenModalCheckout(idBusinessCheckout:number){
    const dialogRef = this.dialog.open(ModalOrderComponent, {      
      data: {
        idBusinessCheckout: idBusinessCheckout,
      },
      panelClass: 'dialog-add-item'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.reload)  
        this.search();
    });
    
  }

  dropItem(event: CdkDragDrop<FactoryOrderSearchItemStruct[]>){ 
    let factoryOrderSearchItemStruct:FactoryOrderSearchItemStruct = event.container.data[event.currentIndex]; 
    
    this.clickOpenModalCheckout(factoryOrderSearchItemStruct.idBusinessCheckout);
  }
}
