

<div class="checkout-item">
    @if(factoryOrderDetailsItemStruct != undefined){
        <div class="row">
            <div class="col-3">
                <img class="image-item" src="{{factoryOrderDetailsItemStruct.productImageUrl}}" />
            </div>
            <div class="col-6">
                <label class="business-iten-name">{{factoryOrderDetailsItemStruct.productName}}</label>
                <label class="business-iten-count">{{factoryOrderDetailsItemStruct.selectedTotalQuantity}} {{factoryOrderDetailsItemStruct.selectedTotalQuantity == 1?'peça solicitada' : 'peças solicitadas'}}</label>
                <label class="business-iten-count-description">
                    {{factoryOrderDetailsItemStruct.quantColor}} 
                    {{factoryOrderDetailsItemStruct.quantColor == 1? 'cor escolhida' : 'cores escolhidas'}}
                    em 
                    {{factoryOrderDetailsItemStruct.quantSize}} 
                    {{factoryOrderDetailsItemStruct.quantSize == 1? 'grade':'grades'}} 
                    de tamanho</label>
            </div>
            <div class="col-3 text-left">
                <label class="business-iten-price">{{factoryOrderDetailsItemStruct.totalProductPrice | currency:'BRL' }}</label>
            </div>
        </div>
        <div class="grid-itens">
            @for (gridItem of _listGridItem; track $index) {
                <div class="row">
                    <div class="col-1">
                        <h3>{{gridItem.productGridSizeName}}</h3>
                    </div>
                    <div class="col-11">
                        @for (gridItemColor of gridItem.listGridItemColor; track $index) {
                            <div class="tag-grid">{{gridItemColor.productGridColorName}}: <span>{{gridItemColor.quant}}</span></div>
                        }
                    </div>
                </div>
            }            
        </div>
    }@else {
        <div class="row">
            <div class="col-3">
                <app-loading-box [height]="'180'" [width]="'180'"></app-loading-box>
            </div>
            <div class="col-6">
                <label class="business-iten-name"> <app-loading-box [height]="'20'" [width]="'250'"></app-loading-box></label>
                <label class="business-iten-count"><app-loading-box [height]="'15'" [width]="'100'" [marginTop]="'15'"/></label>
                <label class="business-iten-count-description"><app-loading-box [height]="'12'" [width]="'220'" [marginTop]="'15'"/></label>
            </div>
            <div class="col-3 text-left">
                <label class="business-iten-price"><app-loading-box [height]="'20'" [width]="'120'"/></label>
            </div>
        </div>
        <div class="grid-itens">
            <div class="row">
                <div class="col-1">
                    <h3><app-loading-box [height]="'31'" [width]="'50'"/></h3>
                </div>
                <div class="col-11">
                    <app-loading-box [height]="'31'" [width]="'100'" [display]="'inline-block'"/>
                    <app-loading-box [height]="'31'" [width]="'100'" [display]="'inline-block'" [marginLeft]="'15'"/>
                    <app-loading-box [height]="'31'" [width]="'100'" [display]="'inline-block'" [marginLeft]="'15'"/>
                </div>
            </div>
    
            <div class="row">
                <div class="col-1">
                    <h3><app-loading-box [height]="'31'" [width]="'50'"/></h3>
                </div>
                <div class="col-11">
                    <app-loading-box [height]="'31'" [width]="'100'" [display]="'inline-block'"/>
                    <app-loading-box [height]="'31'" [width]="'100'" [display]="'inline-block'" [marginLeft]="'15'"/>
                </div>
            </div>
            
        </div>
    }
    
</div>

