<app-menu></app-menu>
<app-search-bar></app-search-bar>
<div class="container">
    <div class="row">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">       
            <div class="row">            
                <h1 class="col-12 col-md-6 title-default">Lojista</h1>                
                <div class = "col-12 col-md-6 title-buttons">   
                    @if (isFirstLoading == false) {
                        <button type="submit" mat-flat-button class="btn-primary">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Salvar lojista
                            }
                        </button>
                        <button type="button" mat-flat-button class="btn-secundary" (click)="openModalToConfirmDelete()">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Excluir lojista
                            }
                        </button>
                        <button type="button" mat-flat-button class="btn-secundary" (click)="backToBusinessStoreList()">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Voltar
                            }
                        </button>
                    }                    
                </div>                
            </div>
            @if (isFirstLoading == false) {
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome da loja</mat-label>
                            <input matInput formControlName="storeName" type="text">
                            @if (model.get('storeName')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }  
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>CNPJ</mat-label>
                            <input matInput type="text" formControlName="cnpj" [mask]="masks.cnpj">
                            @if (model.get('cnpj')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }                        
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do lojista</mat-label>
                            <input matInput formControlName="ownerName" type="text">
                            @if (model.get('ownerName')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }  
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                            @if (model.get('phone')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }                        
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email">
                            @if (model.get('email')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }                        
                        </mat-form-field>
                    </div>
                </div>
            }
            @else {
                <div class="row">
                    @for (item of _arrayLoadingSearch; track $index) {
                        <div class="col-12 col-sm-6">
                            <app-loading-box [height]="'25'" [width]="'100%'" [marginTop]="'0'"></app-loading-box>
                        </div>    
                    }                                    
                </div>
            }
            <div class="row">
                <h1 class="col-12 col-md-6 title-default">Endereço</h1> 
            </div>
            @if (isFirstLoading == false) {
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>CEP</mat-label>
                            <input matInput formControlName="zipcode" type="text"
                            maxlength="8" oninput="this.value = this.value.replace(/[\n\r]/g, ''); this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            @if (model.get('zipcode')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>País</mat-label>
                            <input matInput formControlName="country" type="text">
                            @if (model.get('country')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput formControlName="state" type="text">
                            @if (model.get('state')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput formControlName="city" type="text">
                            @if (model.get('city')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input matInput formControlName="neighborhood" type="text">
                            @if (model.get('neighborhood')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Logradouro</mat-label>
                            <input matInput formControlName="street" type="text">
                            @if (model.get('street')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Número</mat-label>
                            <input matInput formControlName="addressNumber" type="text">
                            @if (model.get('addressNumber')?.invalid) {
                                <mat-error>Preenchimento incorreto</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput formControlName="complement" type="text">
                        </mat-form-field>
                    </div>                    
                </div>
            }
            @else {
                <div class="row">
                    @for (item of _arrayLoadingSearch; track $index) {
                        <div class="col-12 col-sm-6">
                            <app-loading-box [height]="'25'" [width]="'100%'" [marginTop]="'0'"></app-loading-box>
                        </div>    
                    }                                    
                </div>
            }            
        </form>
    </div>    
    <div class="row">
        <h1 class="col-12 title-default">Confecções ligadas ao lojista</h1>
        <div class="card-list">
            @if(_listBusinessStoreFactoryStruct != undefined) {
                <div class="row">
                    @for (item of _listBusinessStoreFactoryStruct; track $index) {
                        <div class="col-12 col-md-6">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="title"> {{item.businessName}}</span>
                                    <span class="title-4"><b>CNPJ: </b> {{item.cnpj}}</span>
                                    <span class="title-4"><b>Telefone: </b>{{item.businessPhone}}
                                        @if(item.businessPhone &&
                                        item.businessPhone.trim() != ""){
                                            <img src="assets/images/whatsAppIcon.png" />
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
    
                    @if( _listBusinessStoreFactoryStruct.length == 0){
                        <app-empty-list [title]="'Não encontramos nenhum pedido.'"
                            [subTitle]="'Clique em novo pedido e comece a criar um pedido de sucesso para seu lojista'"></app-empty-list>
                    }
                </div>            
            }
            @else {
                <div class="row">
                    @for (item of _arrayLoadingSearch; track $index) {
                        <div class="col-12 col-sm-6">
                            <div class="card-item">
                                <div class="infos">
                                    <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'0'"></app-loading-box>
                                    <app-loading-box [height]="'20'" [width]="'250'" [marginTop]="'10'"></app-loading-box>
                                    <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }        
        </div>
    </div>    
</div>