import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { LoadingBoxComponent } from "../../../shared/component/loading-box/loading-box.component";
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from "../../../shared/component/empty-list/empty-list.component";
import { MatDialog } from '@angular/material/dialog';
import { Masks, MaskService } from '../../../shared/services/mask.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { BusinessStoreService } from '../../../shared/services/API/business-user/business-store.service';
import { BusinessResponse } from '../../../shared/services/responses/business-user/business.response';
import { Business } from '../../../shared/services/models/business-user/business.model';
import { BusinessStoreFactoryResponse } from '../../../shared/services/responses/business-user/business-store-factory.response';
import { BusinessStoreFactoryStruct } from '../../../shared/services/structs/business-user/business-store-factory.struct';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-business-store-register',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
    NgxMaskDirective
  ],
  providers:[
    provideNgxMask()
  ],
  templateUrl: './business-store-register.component.html',
  styleUrl: './business-store-register.component.css'
})
export class BusinessStoreRegisterComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private businessStoreService: BusinessStoreService,
    private dialog: MatDialog,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,) {
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading: boolean = false;
  public isFirstLoading: boolean = true;
  public _idBusinessStore: number | null = null;
  public _page: number = 0;
  public _listBusinessStoreFactoryStruct: BusinessStoreFactoryStruct[];
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1, 1];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    this.createDefaultInstanceOfForm();

    this.tryGetUrlIdBusinessStore();

    this.getDataToDisplayInComponent();

  }

  createDefaultInstanceOfForm(): void {
    this.model = this.formBuilder.group({
      storeName: ['', Validators.required],
      cnpj: ['', Validators.required],
      ownerName: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      zipcode: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      neighborhood: ['', Validators.required],
      street: ['', Validators.required],
      addressNumber: ['', Validators.required],
      complement: [''],
    });
  }

  tryGetUrlIdBusinessStore(): void {
    let idBusinessStoreUrl:string = this.activatedRoute.snapshot.params["idBusinessStore"];

    if(idBusinessStoreUrl)
      this._idBusinessStore = Number(idBusinessStoreUrl);    
  }

  getDataToDisplayInComponent(): void {
    if (this.isUpdateBusinessStore()) {
      this.getBusinessStore();
      this.getStoreFactories();
    }
    else
      this.setDataToCreateFlow();
  }

  isUpdateBusinessStore(): boolean {
    return this._idBusinessStore != null;  
  }

  getBusinessStore(): void{
    if (!this._idBusinessStore) {
      this.setDataToCreateFlow();
      return;
    }      

    this.businessStoreService.GetBusinessStore(this._idBusinessStore).subscribe({
      next:(businessResponse:BusinessResponse)=>{
        if (businessResponse.isError) {
          this.alertService.show("Erro inesperado", businessResponse.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        
        this.populateBusinessStoreForm(businessResponse.business);
        this.isFirstLoading = false;

      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isFirstLoading = false;
      }
    });
  }

  getStoreFactories(): void{
    if (!this._idBusinessStore) {
      this.setDataToCreateFlow();
      return;
    }  

    this.businessStoreService.GetStoreFactories(this._idBusinessStore).subscribe({
      next:(businessStoreFactoryResponse:BusinessStoreFactoryResponse)=>{
        if (businessStoreFactoryResponse.isError) {
          this.alertService.show("Erro inesperado", businessStoreFactoryResponse.errorDescription, AlertType.error);
          return;
        }

        this.populateStoreFactories(businessStoreFactoryResponse.listBusinessStoreFactoryStruct);
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  setDataToCreateFlow(): void {
    this._listBusinessStoreFactoryStruct = [];
    this.isFirstLoading = false;
  }

  populateBusinessStoreForm(business: Business): void {
    if (!business)
      return;

    this.model.get('storeName')?.setValue(business.businessName);
    this.model.get('cnpj')?.setValue(business.cnpj);
    this.model.get('ownerName')?.setValue(business.ownerName);
    this.model.get('phone')?.setValue(business.businessPhone);
    this.model.get('email')?.setValue(business.businessEmail);
    this.model.get('zipcode')?.setValue(business.zipcode);
    this.model.get('country')?.setValue(business.country);
    this.model.get('state')?.setValue(business.state);
    this.model.get('city')?.setValue(business.city);
    this.model.get('neighborhood')?.setValue(business.neighborhood);
    this.model.get('street')?.setValue(business.street);
    this.model.get('addressNumber')?.setValue(business.addressNumber);
    this.model.get('complement')?.setValue(business.complement);

  }

  populateStoreFactories(listBusinessStoreFactoryStruct: BusinessStoreFactoryStruct[]): void {
    this._listBusinessStoreFactoryStruct = [];

    if (listBusinessStoreFactoryStruct && listBusinessStoreFactoryStruct.length > 0)
      this._listBusinessStoreFactoryStruct = [...listBusinessStoreFactoryStruct];

  }  

  submit(): void{
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    let business: Business = this.mapFormToBusiness();

    if (this.isUpdateBusinessStore()) 
      this.updateBusinessStore(business);    
    else 
      this.createBusinessStore(business);

  }

  mapFormToBusiness(): Business {
    let business: Business = new Business();

    business.businessName = this.model.get('storeName')?.value;
    business.cnpj = this.model.get('cnpj')?.value;
    business.ownerName = this.model.get('ownerName')?.value;
    business.businessPhone = this.model.get('phone')?.value;
    business.businessEmail = this.model.get('email')?.value;
    business.zipcode = this.model.get('zipcode')?.value;
    business.country = this.model.get('country')?.value;
    business.state = this.model.get('state')?.value;
    business.city = this.model.get('city')?.value;
    business.neighborhood = this.model.get('neighborhood')?.value;
    business.street = this.model.get('street')?.value;
    business.addressNumber = this.model.get('addressNumber')?.value;
    business.complement = this.model.get('complement')?.value;

    return business;
  }

  createBusinessStore(request: Business): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.businessStoreService.PostBusinessStore(request).subscribe({
      next:(returnStruct:ReturnStruct)=>{
        if (returnStruct.isError) {
          this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.isLoading = false;
        this.alertService.show("Sucesso", "Salvo com sucesso!", AlertType.success);
        this.backToBusinessStoreList();

      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  updateBusinessStore(request: Business): void {
    if (this.isLoading || !this._idBusinessStore) {
      return;
    }

    this.isLoading = true;
    
    request.idBusiness = this._idBusinessStore;

    this.businessStoreService.PutBusinessStore(request).subscribe({
      next:(returnStruct:ReturnStruct)=>{
        if (returnStruct.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
          return;
        }
        
        this.isLoading = false;
        this.alertService.show("Sucesso", "Alterado com sucesso!", AlertType.success);
        this.backToBusinessStoreList();

      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  openModalToConfirmDelete(): void{
    if (this.isLoading || !this._idBusinessStore) {
      this.alertService.show("Alerta", "Não é possível excluir lojista pois o mesmo não foi criado.", AlertType.warning);    
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Alerta",
        description: "Deseja excluir esse lojista? Essa ação será irreversível.",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.deleteBusinessStore();
      }
    });
  }

  deleteBusinessStore(): void{
    if (this.isLoading || !this._idBusinessStore) {
      return;
    }

    this.isLoading = true;

    this.businessStoreService.DeleteBusinessStore(this._idBusinessStore).subscribe({
      next: (response:ReturnStruct) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show("Sucesso", "Deletado com sucesso!", AlertType.success);     
        this.backToBusinessStoreList();    

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  backToBusinessStoreList(): void{
    if (this.isLoading)
      return;

    this.router.navigate(['/consult/business-store']);
  }
 
}
