import { Component, EventEmitter, Input, OnInit, Output, output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { LoadingBoxComponent } from "../../../../../shared/component/loading-box/loading-box.component";
import { FactoryOrderDetailsItemStruct } from '../../../../../shared/services/structs/orchestrator-checkout/factory-order-details-item.struct';
import { FactoryOrderDetailsItemGridStruct } from '../../../../../shared/services/structs/orchestrator-checkout/factory-order-details-item-grid.struct';
import { ConnectableObservable } from 'rxjs';

@Component({
  selector: 'app-modal-order-checkout-item-resume',
  standalone: true,
  imports: [MatProgressSpinnerModule, MatIconModule, MatButtonModule, CommonModule, LoadingBoxComponent],
  templateUrl: './modal-order-checkout-item-resume.component.html',
  styleUrl: './modal-order-checkout-item-resume.component.css'
})
export class CheckoutItemResumeComponent implements OnInit {
  @Input() factoryOrderDetailsItemStruct:FactoryOrderDetailsItemStruct;
  public listGrid:any = [];
  public _allSizesGrid:string[];
  public _listGridItem:GridItem[] = [];

  ngOnInit():void{
    this._allSizesGrid = Array.from(new Set(this.factoryOrderDetailsItemStruct.listFactoryOrderDetailsItemGridStruct.map(c=> c.productGridSizeName)));
    
    this._allSizesGrid.forEach(productGridSizeName => {
      let listFactoryOrderDetailsItemGridStruct:Array<FactoryOrderDetailsItemGridStruct>|undefined = this.factoryOrderDetailsItemStruct.listFactoryOrderDetailsItemGridStruct.filter(c=> c.productGridSizeName == productGridSizeName);

      if(!listFactoryOrderDetailsItemGridStruct)
        return;

      let gridItem:GridItem = new GridItem();
      gridItem.productGridSizeName = productGridSizeName;
      gridItem.listGridItemColor = [];

      listFactoryOrderDetailsItemGridStruct.forEach(x => {
        let gridItemColor:GridItemColor = new GridItemColor();
        gridItemColor.productGridColorName = x.productGridColorName;
        gridItemColor.quant = x.quant;
        gridItem.listGridItemColor.push(gridItemColor);
      });

      this._listGridItem.push(gridItem);
    });
  }
}

export class GridItem{
  public productGridSizeName:string;
  public listGridItemColor:GridItemColor[]
}

export class GridItemColor{
  public quant:number;
  public productGridColorName:string;
  public colorCode:string;
}
