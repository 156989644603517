import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { BusinessConsultorRegistrationDataComponent } from './business-consultor-registration-data/business-consultor-registration-data.component';
import { BusinessConsultorCheckoutHistoryComponent } from './business-consultor-checkout-history/business-consultor-checkout-history.component';

@Component({
  selector: 'app-business-consultor-register',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    BusinessConsultorRegistrationDataComponent,
    BusinessConsultorCheckoutHistoryComponent
  ],
  templateUrl: './business-consultor-register.component.html',
  styleUrl: './business-consultor-register.component.css'
})
export class BusinessConsultorRegisterComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {    

  }

}
