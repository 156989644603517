import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AlertService, AlertType } from '../../services/alert.service';

@Component({
  selector: 'app-input-consultor-comission-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './input-consultor-comission-modal.component.html',
  styleUrl: './input-consultor-comission-modal.component.css'
})
export class InputConsultorComissionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InputConsultorComissionModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,) {
  }

  public _title: string;
  public _description: string;
  public _buttonConfirm: string;
  public _buttonClose: string;
  public _model: FormGroup;

  ngOnInit(): void {
    this._title = this.data.title
    this._description = this.data.description
    this._buttonConfirm = this.data.buttonConfirm
    this._buttonClose = this.data.buttonClose

    this._model = this.formBuilder.group({
      consultorComission: [null, Validators.required],
    });

  }

  close() {
    this.dialogRef.close({
      confirm: false
    });
  }

  confirm() {
    if (!this._model.valid) {
      this.alertService.show('Erro', "Preencha o valor da comissão.", AlertType.error);
      return;
    }

    let consultorComission: number = this._model.get("consultorComission")?.value / 100;

    this.dialogRef.close({
      confirm: true,
      consultorComission: consultorComission
    });
  }  
}
