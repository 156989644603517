import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FactoryOrderSearchItemStruct } from '../../../../shared/services/structs/orchestrator-checkout/factory-order-search-item.struct';
import { CommonModule } from '@angular/common';
import { LoadingBoxComponent } from "../../../../shared/component/loading-box/loading-box.component";
import { OrderStatusBulletComponent } from '../order-status-bullet/order-status-bullet.component';

@Component({
  selector: 'app-order-card',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule,
    LoadingBoxComponent,
    OrderStatusBulletComponent
],
  templateUrl: './order-card.component.html',
  styleUrl: './order-card.component.css'
})
export class OrderCardComponent {
  @Output() clickOpenModalCheckout =  new EventEmitter<number>()
  @Input() factoryOrderSearchItemStruct:FactoryOrderSearchItemStruct|undefined;

  constructor(){
  }

  openModalCheckout(){
    this.clickOpenModalCheckout.emit(this.factoryOrderSearchItemStruct?.idBusinessCheckout);
  }
}
