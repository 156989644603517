import { Component, OnInit } from '@angular/core';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { CheckoutResponse } from '../../../shared/services/responses/orchestrator-checkout/checkout.response';
import { CheckoutService } from '../../../shared/services/API/orchestator-checkout/checkout.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { LoadingBoxComponent } from '../../../shared/component/loading-box/loading-box.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CheckoutItemResumeComponent } from "../search-resume-checkout/checkout-item-resume/checkout-item-resume.component";
import { CheckoutHistoryService } from '../../../shared/services/API/checkout/checkout-history.service';
import { CheckoutHistoryResponse } from '../../../shared/services/responses/checkout/checkout-history.response';
import { CheckoutStatusComponent } from "./checkout-status/checkout-status.component";
import { CheckoutHistoryStruct } from '../../../shared/services/structs/checkout/checkout-history.struct';
import { ModalAddItemComponent } from '../search/modal-add-item/modal-add-item.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-checkout-details',
  standalone: true,
  imports: [
    MenuComponent,
    SearchBarComponent,
    MatExpansionModule,
    MatButtonModule,
    LoadingBoxComponent,
    CommonModule,
    MatIconModule,
    MatSlideToggleModule,
    CheckoutItemResumeComponent,
    CheckoutStatusComponent
],
  templateUrl: './checkout-details.component.html',
  styleUrl: './checkout-details.component.css'
})
export class CheckoutDetailsComponent implements OnInit{
  constructor(  
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    public checkoutService:CheckoutService,
    private activatedRoute:ActivatedRoute,
    private checkoutHistoryService:CheckoutHistoryService,
    public dialog: MatDialog){
  }
  
  public model: FormGroup;
  public isLoading:boolean = false;
  public _checkoutResponse: CheckoutResponse | undefined = undefined;
  public _idCheckout:number;
  public _checkoutHistoryResponse:CheckoutHistoryResponse;

  public statusCheckout1:CheckoutHistoryStruct | null;
  public statusCheckout2:CheckoutHistoryStruct | null;
  public statusCheckout3:CheckoutHistoryStruct | null;
  public statusCheckout4:CheckoutHistoryStruct | null;
  public statusCheckout5:CheckoutHistoryStruct | null;
  public statusCheckout6:CheckoutHistoryStruct | null;
  public statusCheckout7:CheckoutHistoryStruct | null;
  public statusCheckout8:CheckoutHistoryStruct | null;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idCheckout: [''],
      storeName: [''],
      storeOwnerName: [''],
      idCheckoutStatus: [''],
    });

    this._idCheckout = Number(this.activatedRoute.snapshot.paramMap.get('idCheckout'));
    
    this.search();
  }

  search(){
    this.populateListCheckout();
    this.populateCheckoutHistory();
  }

  populateListCheckout(){
    let checkid = Number(this.activatedRoute.snapshot.paramMap.get('idCheckout'));
    console.log(checkid)
    this.checkoutService.listCheckoutByIdCheckout(checkid).subscribe({

      next:(checkoutResponse:CheckoutResponse)=>{
        if (checkoutResponse.isError) {
          this.alertService.show("Erro", checkoutResponse.errorDescription, AlertType.error);
          return;
        }
        console.log(checkoutResponse)
        this._checkoutResponse = checkoutResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  populateCheckoutHistory(){
    this.checkoutHistoryService.get(this._idCheckout).subscribe({

      next:(checkoutHistoryResponse:CheckoutHistoryResponse)=>{
        if (checkoutHistoryResponse.isError) {
          this.alertService.show("Erro", checkoutHistoryResponse.errorDescription, AlertType.error);
          return;
        }

        this._checkoutHistoryResponse = checkoutHistoryResponse;

        this.statusCheckout1 = this._checkoutHistoryResponse.listCheckoutHistory.filter(c=> c.idCheckoutStatus == 1)[0];
        this.statusCheckout2 = this._checkoutHistoryResponse.listCheckoutHistory.filter(c=> c.idCheckoutStatus == 2)[0];
        this.statusCheckout3 = this._checkoutHistoryResponse.listCheckoutHistory.filter(c=> c.idCheckoutStatus == 3)[0];
        this.statusCheckout4 = this._checkoutHistoryResponse.listCheckoutHistory.filter(c=> c.idCheckoutStatus == 4)[0];
        this.statusCheckout5 = this._checkoutHistoryResponse.listCheckoutHistory.filter(c=> c.idCheckoutStatus == 5)[0];
        this.statusCheckout6 = this._checkoutHistoryResponse.listCheckoutHistory.filter(c=> c.idCheckoutStatus == 6)[0];
        this.statusCheckout7 = this._checkoutHistoryResponse.listCheckoutHistory.filter(c=> c.idCheckoutStatus == 7)[0];
        this.statusCheckout8 = this._checkoutHistoryResponse.listCheckoutHistory.filter(c=> c.idCheckoutStatus == 8)[0];
        
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }


  clickDeleteCheckoutItem(event:any){

  }

  returnArraySize(size:number){
    return Array(size).fill(4);
  }

  openDalogDetails(idProduct:number){
    const dialogRef = this.dialog.open(ModalAddItemComponent, {
      data: {
        idProduct: idProduct,
        idBusinessStore: this._checkoutResponse?.checkout.idBusinessStore,
        idCheckout: this._checkoutResponse?.checkout.idCheckout,
        disable:true
      },
      panelClass: 'dialog-add-item'
    });
  }
}
