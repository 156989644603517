import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { FactoryInventoryProductResponse } from '../../responses/inventory/factory-inventory-product.response';
import { ReturnStruct } from '../../structs/return.struct';
import { ProductBaseRequest } from '../../requests/inventory/product-base.request';
import { NewProductImageRequest } from '../../requests/inventory/new-product-image-request';

@Injectable({
  providedIn: 'root'
})
export class ProductImageService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public post(newProductImageRequest:NewProductImageRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiInventory + "ProductImage", newProductImageRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public delete(idProductImage:number): Observable<ReturnStruct> {
    return this.httpClient.delete<ReturnStruct>(environment.urlApiInventory + "ProductImage/idProductImage/" + idProductImage, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
