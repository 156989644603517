import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { FactoryBusinessService } from '../../../../shared/services/API/business-user/factory-business.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { BusinessFactoryByConsultorStruct } from '../../../../shared/services/structs/business-user/business-factory-by-consultor.struct';
import { SearchFactoriesByConsultorRequest } from '../../../../shared/services/requests/business-user/search-factories-by-consultor.request';
import { SearchFactoriesByConsultorResponse } from '../../../../shared/services/responses/business-user/search-factories-by-consultor.response';
import { LoadingBoxComponent } from '../../../../shared/component/loading-box/loading-box.component';
import { EmptyListComponent } from '../../../../shared/component/empty-list/empty-list.component';
import { MatSelectModule } from '@angular/material/select';
import { InputConsultorComissionModalComponent } from '../../../../shared/component/input-consultor-comission/input-consultor-comission-modal.component';
import { FactoryConsultorResponse } from '../../../../shared/services/responses/business-user/factory-consultor.response';
import { CreateInviteConsultorToFactoryRequest } from '../../../../shared/services/requests/business-user/create-invite-consultor-to-factory.request';

@Component({
  selector: 'app-business-factory-to-conect-list',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
    MatSelectModule,
  ],
  templateUrl: './business-factory-to-conect-list.component.html',
  styleUrl: './business-factory-to-conect-list.component.css'
})

export class BusinessFactoryToConectListComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private factoryBusinessService: FactoryBusinessService,
    public dialog: MatDialog,
    public authService: AuthService) {
  }

  public model: FormGroup;
  public isLoading: boolean = false;
  public _page: number = 0;
  public _listBusinessFactoryByConsultorStruct: BusinessFactoryByConsultorStruct[];
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1];
  public _isLastPage: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      searchText: [''],
      statusConection: [""]
    });  

    this.clickSearch();

  }

  search() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let searchFactoriesByConsultorRequest: SearchFactoriesByConsultorRequest = new SearchFactoriesByConsultorRequest();

    searchFactoriesByConsultorRequest.searchText = this.model.get('searchText')?.value
    searchFactoriesByConsultorRequest.page = this._page;
    searchFactoriesByConsultorRequest.isToGetFactoriesWithoutConectionWithIdBusinessConsultor = this.setSearchAttributeIsToGetFactoriesWithoutConectionWithIdBusinessConsultor();
    searchFactoriesByConsultorRequest.inviteConectionStatus = this.setSearchAttributeInviteConectionStatus();

    this.factoryBusinessService.searchPagedForFactoriesByConsultor(searchFactoriesByConsultorRequest).subscribe({
      next: (searchFactoriesByConsultorResponse: SearchFactoriesByConsultorResponse) => {
        if (searchFactoriesByConsultorResponse.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", searchFactoriesByConsultorResponse.errorDescription, AlertType.error);
          return;
        }

        this._isLastPage = true;

        if (this._page == 0) {
          this._listBusinessFactoryByConsultorStruct = [];
        }        

        if (searchFactoriesByConsultorResponse.listBusinessFactoryByConsultorStruct?.length > 0) {
          this._listBusinessFactoryByConsultorStruct.push(...searchFactoriesByConsultorResponse.listBusinessFactoryByConsultorStruct);
          this._isLastPage = false;
        }        

        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  setSearchAttributeIsToGetFactoriesWithoutConectionWithIdBusinessConsultor(): boolean {
    let isToGetFactoriesWithoutConectionWithIdBusinessConsultor: boolean = false;

    if (this.model.get('statusConection')?.value == "withoutConection" || this.model.get('statusConection')?.value == "")
      isToGetFactoriesWithoutConectionWithIdBusinessConsultor = true;

    return isToGetFactoriesWithoutConectionWithIdBusinessConsultor;
  }

  setSearchAttributeInviteConectionStatus(): boolean | null {
    let inviteConectionStatus: boolean | null = null;

    if (this.model.get('statusConection')?.value == "waitingConection" || this.model.get('statusConection')?.value == "")
      inviteConectionStatus = false;

    return inviteConectionStatus;
  }

  clickSearch() {
    this._page = 0;
    this.search();
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }

  openModalToInsertComissionAndSendInvite(businessFactoryByConsultorStruct: BusinessFactoryByConsultorStruct): void{
    const dialogRef = this.dialog.open(InputConsultorComissionModalComponent, {
      data: {
        title: "Confirme a ação",
        description: "Insira o valor em porcentagem da sua comissão para concluir a solicitação de conexão.",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.sendInviteToFactory(result.consultorComission, businessFactoryByConsultorStruct);
      }
    });
  }

  sendInviteToFactory(consultorComissionInDecimal: number, businessFactoryByConsultorStruct: BusinessFactoryByConsultorStruct): void{
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: CreateInviteConsultorToFactoryRequest = new CreateInviteConsultorToFactoryRequest();
    request.idBusinessFactory = businessFactoryByConsultorStruct.idBusinessFactory;
    request.consultorComission = consultorComissionInDecimal;

    this.factoryBusinessService.createInviteConsultorToFactory(request).subscribe({
      next: (response: FactoryConsultorResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        businessFactoryByConsultorStruct.idFactoryConsultor = response.factoryConsultor.idFactoryConsultor;
        this.isLoading = false;
        this.alertService.show("Sucesso", "Adicionado com sucesso!", AlertType.success);       

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }
}

