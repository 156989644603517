import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ProductSearchLookupResponse } from '../../responses/inventory/product-search-lookup.response';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ProductSearchRequest } from '../../requests/inventory/product-search.request';
import { ProductSearchResponse } from '../../responses/inventory/product-search.response';
import { FactoryInventorySearchRequest } from '../../requests/inventory/factory-inventory-search.request';
import { FactoryInventorySearchResponse } from '../../responses/inventory/factory-inventory-search.response';

@Injectable({
  providedIn: 'root'
})
export class FactoryInventorySearchService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public search(factoryInventorySearchRequest:FactoryInventorySearchRequest): Observable<FactoryInventorySearchResponse> {
    return this.httpClient.post<FactoryInventorySearchResponse>(environment.urlApiInventory + "FactoryInventorySearch", factoryInventorySearchRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
