import { Component, OnInit } from '@angular/core';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { BusinessService } from '../../../shared/services/API/business-user/business.service';
import { BusinessResponse } from '../../../shared/services/responses/business-user/business.response';
import { CheckoutService } from '../../../shared/services/API/orchestator-checkout/checkout.service';
import { CheckoutResponse } from '../../../shared/services/responses/orchestrator-checkout/checkout.response';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { CheckoutItemResumeComponent } from "./checkout-item-resume/checkout-item-resume.component";
import { LoadingBoxComponent } from "../../../shared/component/loading-box/loading-box.component";
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ModalAddressComponent } from './modal-address/modal-address.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalDiscountComponent } from './modal-discount/modal-discount.component';
import { BusinessCheckoutStruct } from '../../../shared/services/structs/orchestrator-checkout/business-checkout.struct';
import { ModalBusinessStoreOwnerInfosComponent } from './modal-business-store-owner-infos/modal-business-store-owner-infos';
import { CheckoutAddressRequest } from '../../../shared/services/requests/orchestator-checkout/checkout-address.request';

@Component({
  selector: 'app-search-resume-checkout',
  standalone: true,
  imports: [
    MenuComponent, 
    SearchBarComponent, 
    MatExpansionModule, 
    MatButtonModule,
    CheckoutItemResumeComponent, 
    LoadingBoxComponent, 
    CommonModule, 
    MatIconModule,
    MatSlideToggleModule],
  templateUrl: './search-resume-checkout.component.html',
  styleUrl: './search-resume-checkout.component.css'
})
export class SearchResumeCheckoutComponent implements OnInit  {
  constructor(private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public businessService:BusinessService,
    private checkoutService:CheckoutService,
  ){
    
  }

  public _idBusinessStore:number = -1;
  public _businessStoreResponse:BusinessResponse;
  public _checkoutResponse:CheckoutResponse;

  ngOnInit(): void {
    let idBusinessStoreUrl:string = this.activatedRoute.snapshot.params["idBusinessStore"];

    if(idBusinessStoreUrl){
      this._idBusinessStore = Number(idBusinessStoreUrl);
      this.populateUserBusinessStore();
      this.populateListCheckout()
    }
  }

  populateUserBusinessStore(){
    this.businessService.GetBusiness(this._idBusinessStore).subscribe({

      next:(businessResponse:BusinessResponse)=>{
        if (businessResponse.isError) {
          this.alertService.show("Erro inesperado", businessResponse.errorDescription, AlertType.error);
          return;
        }
        
        this._businessStoreResponse = businessResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  populateListCheckout(){
    this.checkoutService.listCheckout(this._idBusinessStore).subscribe({

      next:(checkoutResponse:CheckoutResponse)=>{
        if (checkoutResponse.isError) {
          this.alertService.show("Erro inesperado", checkoutResponse.errorDescription, AlertType.error);
          return;
        }

        this._checkoutResponse = checkoutResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  returnArraySize(size:number){
    return Array(size).fill(4);
  }

  openModalAddress(){
    const dialogRef = this.dialog.open(ModalAddressComponent, {
      data: {
        checkout: this._checkoutResponse.checkout
      },
    });

    dialogRef.afterClosed().subscribe(result => {      
      if (result && result.address)
       this.mapCheckoutAddressRequestToCheckoutAddress(result.address);        
      
    });
  }

  mapCheckoutAddressRequestToCheckoutAddress(address:CheckoutAddressRequest):void {
    this._checkoutResponse.checkout.zipcode = address.zipcode;
    this._checkoutResponse.checkout.country = address.country;
    this._checkoutResponse.checkout.state = address.state;
    this._checkoutResponse.checkout.city = address.city;
    this._checkoutResponse.checkout.street = address.street;
    this._checkoutResponse.checkout.addressNumber = address.addressNumber;
    this._checkoutResponse.checkout.neighborhood = address.neighborhood;
    this._checkoutResponse.checkout.complement = address.complement;
    this._checkoutResponse.checkout.latitude = address.latitude;
    this._checkoutResponse.checkout.longitude = address.longitude;
  }

  openModalDiscount(businessCheckoutStruct:BusinessCheckoutStruct){
    const dialogRef = this.dialog.open(ModalDiscountComponent, {
      data: {
        businessCheckoutStruct: businessCheckoutStruct,
        idCheckout: this._checkoutResponse.checkout.idCheckout,
      },
    });

    dialogRef.afterClosed().subscribe(result => {      
      this.populateListCheckout();
    });
  }

  openModalBusinessStoreOwnerInfos(){
    const dialogRef = this.dialog.open(ModalBusinessStoreOwnerInfosComponent, {
      data: {
        checkout: this._checkoutResponse.checkout
      },
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {      
      if (result !== undefined) {
        this._checkoutResponse = result.checkoutResponse;
      }
    });
  }

  clickDeleteCheckoutItem(event:any){
    this.checkoutService.delteItemCheckout(event.idProductCheckout, event.idCheckout).subscribe({
      next:(checkoutResponse:CheckoutResponse)=>{
        if (checkoutResponse.isError) {
          this.alertService.show("Erro inesperado", checkoutResponse.errorDescription, AlertType.error);
          return;
        }

        this._checkoutResponse = checkoutResponse;
        this.alertService.show("Sucesso", 'Produto removido do seu pedido com sucesso', AlertType.success);
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

}