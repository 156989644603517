import { CommonModule } from '@angular/common';
import { Component, Input, input, OnInit } from '@angular/core';
import { CheckoutDeliveryService } from '../../../../shared/services/API/checkout/checkout-delivery.service';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { CheckoutDeliveryResponse } from '../../../../shared/services/responses/checkout/checkout-delivery.response';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { ModalDeliveryComponent } from './modal-delivery/modal-delivery.component';
import { CheckoutResponse } from '../../../../shared/services/responses/orchestrator-checkout/checkout.response';

@Component({
  selector: 'app-checkout-status',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule
  ],
  templateUrl: './checkout-status.component.html',
  styleUrl: './checkout-status.component.css'
})
export class CheckoutStatusComponent  implements OnInit{
  @Input() statusName:string;
  @Input() statusDetails:string;
  @Input() idStatusCheckoout:number;
  @Input() dateTimeInclusionStatus:Date | undefined;
  @Input() isActive:boolean;
  @Input() currentStatus:boolean;
  @Input() checkoutResponse:CheckoutResponse;
  

  public _checkoutDeliveryResponse:CheckoutDeliveryResponse;

  constructor(private checkoutDeliveryService:CheckoutDeliveryService,
    private router: Router,
    private alertService: AlertService, 
    public dialog: MatDialog){
    
  }
  
  ngOnInit(): void {
    if(this.currentStatus == true && this.idStatusCheckoout == 4){
      this.populateDelivaryInfo();
    }
  }

  populateDelivaryInfo(){
    this.checkoutDeliveryService.get(this.idStatusCheckoout).subscribe({

      next:(checkoutDeliveryResponse:CheckoutDeliveryResponse)=>{
        if (checkoutDeliveryResponse.isError) {
          this.alertService.show("Erro", checkoutDeliveryResponse.errorDescription, AlertType.error);
          return;
        }

        this._checkoutDeliveryResponse = checkoutDeliveryResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  aprove(){
    this.checkoutDeliveryService.approveDelivery(this.idStatusCheckoout).subscribe({

      next:(checkoutDeliveryResponse:CheckoutDeliveryResponse)=>{
        if (checkoutDeliveryResponse.isError) {
          this.alertService.show("Erro", checkoutDeliveryResponse.errorDescription, AlertType.error);
          return;
        }

        this._checkoutDeliveryResponse = checkoutDeliveryResponse;
        
        this.alertService.show("Sucesso", "Orçamento aprovado", AlertType.success);
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  openModalDelivery(){
    const dialogRef = this.dialog.open(ModalDeliveryComponent, {
      data: {
        idCheckout: this.checkoutResponse.checkout.idCheckout
      },
    });

    dialogRef.afterClosed().subscribe(result => {      
      // if (result !== undefined) {
      //   this._checkoutResponse = result.checkoutResponse;
      // }
    });
  }
}
