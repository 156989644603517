import { Component, OnInit } from '@angular/core';
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { LoadingBoxComponent } from "../../../shared/component/loading-box/loading-box.component";
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from "../../../shared/component/empty-list/empty-list.component";
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../shared/services/API/business-user/user.service';
import { UserToPagedCardListStruct } from '../../../shared/services/structs/business-user/user-to-paged-card-list.struct';
import { SearchUserStructToPageListRequest } from '../../../shared/services/requests/business-user/search-user-struct-to-page-list.request';
import { UserToPageListResponse } from '../../../shared/services/responses/business-user/user-to-page-list.response';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    SearchBarComponent,
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.css'
})
export class UserListComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    public dialog: MatDialog) {
  }

  public model: FormGroup;
  public isLoading: boolean = false;
  public _page: number = 0;
  public _listUserToPagedCardListStruct: UserToPagedCardListStruct[];
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1];
  public _isLastPage: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      searchText: [''],
    });

    this.clickSearch();

  }

  search() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: SearchUserStructToPageListRequest = new SearchUserStructToPageListRequest();

    request.searchText = this.model.get('searchText')?.value
    request.page = this._page;

    this.userService.searchUsersToPagedCardList(request).subscribe({
      next: (response: UserToPageListResponse) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        this._isLastPage = true;

        if (this._page == 0) 
          this._listUserToPagedCardListStruct = [];
                

        if (response.listUserToPagedCardListStruct?.length > 0) {
          this._listUserToPagedCardListStruct.push(...response.listUserToPagedCardListStruct);
          this._isLastPage = false;
        }        

        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickSearch() {
    this._page = 0;
    this.search();
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }
}
