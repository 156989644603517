import { Component, OnInit } from '@angular/core';
import { ActionUserComponent } from "./action-user/action-user.component";
import { ActionNavComponent } from './action-nav/action-nav.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActionInvitesComponent } from './action-invites/action-invites.component';
import {MatBadgeModule} from '@angular/material/badge';
import { AuthService } from '../../services/auth.service';
import { UserLoginResponse } from '../../services/responses/auth/user-login.responde';
import { BusinessTypeEnum } from '../../enum/business-type.enum';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    ActionUserComponent,
    ActionNavComponent,
    ActionInvitesComponent,
    CommonModule,
    RouterModule,
    MatBadgeModule,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent implements OnInit {
  public showActionUser:boolean = false;
  public showActionNav:boolean = false;
  public showActionInvite:boolean = false;
  public areThereNotifications:boolean = false;
  public isFactory:boolean = false;
  public isConsult:boolean = false;
  public isStore:boolean = false;

  constructor(private authService:AuthService){

  }

  ngOnInit(): void {
    let userLoginResponse = this.authService.getUserInfos();

    this.isFactory = userLoginResponse.idBusinessType == BusinessTypeEnum.Confeccao;
    this.isConsult = userLoginResponse.idBusinessType == BusinessTypeEnum.Consultor;
    this.isStore = userLoginResponse.idBusinessType == BusinessTypeEnum.Lojista;
  }

  clickShowActionUser(action:any){
    if(action == null){
      this.showActionUser = !this.showActionUser;
      return;
    }

    this.showActionUser = action;     
  }

  clickShowActionNav(action:any){
    if(action == null){
      this.showActionNav = !this.showActionNav;
      return;
    }

    this.showActionNav = action;     
  } 

  clickShowActionInvites(action:any){
    if(action == null){
      this.showActionInvite = !this.showActionInvite;
      return;
    }

    this.showActionInvite = action;     
  } 

  setAreThereNotifications(areThereNotifications: boolean){
    this.areThereNotifications = areThereNotifications;     
  } 
}
