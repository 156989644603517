<app-menu></app-menu>
<div class="container">

    <h1 class="title-default">Estoque</h1>
    <a mat-button class="btn-primary" [routerLink]="['/factory/inventory/register']">
        <span class="material-symbols-outlined">add</span>
        Novo peça no estoque
    </a>

    <div class="search-area">
        <form class="form" [formGroup]="model" (ngSubmit)="clickSearch()" class="text-center see-more">
            <div class="row search-area-itens">
                <div class="col-12 col-sm-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Pesquisar uma peça</mat-label>
                        <input matInput formControlName="productName" type="text">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Código da peça</mat-label>
                        <input matInput formControlName="productCode" type="text">
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-4">
                    <button type="submit" mat-flat-button class="btn-secundary">
                        @if (isLoading == true) {
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                            <span class="material-symbols-outlined">search</span>
                            Encontrar peça
                        }
                    </button>
                </div>

            </div>
        </form>
    </div>


    <div class="container-itens">
        @if(_factoryInventorySearchResponse != undefined){
        
            @if(_factoryInventorySearchResponse.listFactoryInventoryProductStruct != null && _factoryInventorySearchResponse.listFactoryInventoryProductStruct != undefined && _factoryInventorySearchResponse.listFactoryInventoryProductStruct.length > 0){
                @for (item of _factoryInventorySearchResponse.listFactoryInventoryProductStruct; track $index) {
                    <app-inventory-item [factoryInventoryProductStruct]="item"></app-inventory-item>
                }

                @if(_isLastPage == false){
                    <button mat-stroked-button class="btn-see-more" (click)="nextPage()">
                        Ver mais  
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                }
                
            } 
            @else{
                <app-empty-list [title]="'Não encontramos nenhum produto'" [subTitle]="'Adicione um novo produto e venda como nunca antes!'"></app-empty-list>
            }
           
        }
        @else{
            <div class="loading">
                <app-inventory-item></app-inventory-item>
                <app-inventory-item></app-inventory-item>
                <app-inventory-item></app-inventory-item>
                <app-inventory-item></app-inventory-item>
                <app-inventory-item></app-inventory-item>
                <app-inventory-item></app-inventory-item>
                <app-inventory-item></app-inventory-item>
                <app-inventory-item></app-inventory-item>
            </div>
        }
    </div>

</div>