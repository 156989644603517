import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { InviteBusinessStoreRequest } from '../../requests/business-user/invite-business-store.request';
import { InviteBusinessStoreResponse } from '../../responses/business-user/invite-business-store.response';
import { SearchUserStructToPageListRequest } from '../../requests/business-user/search-user-struct-to-page-list.request';
import { UserToPageListResponse } from '../../responses/business-user/user-to-page-list.response';
import { UserResponse } from '../../responses/business-user/user.response';
import { ReturnStruct } from '../../structs/return.struct';
import { CreateAndUpdateUserRequest } from '../../requests/business-user/create-and-update-user.request';
import { GetLookupProfileResponse } from '../../responses/business-user/get-lookup-profile.response';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public getAllProfile(): Observable<GetLookupProfileResponse> {
    return this.httpClient.get<GetLookupProfileResponse>(environment.urlApiBusinessUser + "profile/GetAllProfile", this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
