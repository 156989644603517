import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { BusinessNameResponse } from '../../responses/business-user/business-name.response';
import { FactoryConsultorResponse } from '../../responses/business-user/factory-consultor.response';
import { SearchFactoriesByConsultorResponse } from '../../responses/business-user/search-factories-by-consultor.response';
import { SearchFactoriesByConsultorRequest } from '../../requests/business-user/search-factories-by-consultor.request';
import { CreateInviteConsultorToFactoryRequest } from '../../requests/business-user/create-invite-consultor-to-factory.request';
import { ReturnStruct } from '../../structs/return.struct';
import { InviteFactoryConsultorConnectionResponse } from '../../responses/business-user/invite-factory-consultor-connection.response';
import { CreateAndUpdateUserRequest } from '../../requests/business-user/create-and-update-user.request';
import { ResponseToInviteFactoryConsultorConnectionRequest } from '../../requests/business-user/response-to-invite-factory-consultor-connection.request';

@Injectable({
  providedIn: 'root'
})
export class FactoryBusinessService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(): Observable<BusinessNameResponse> {
    return this.httpClient.get<BusinessNameResponse>(environment.urlApiBusinessUser + "FactoryBusiness/FactoryBusinessName", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getFactoryConsultor(idFactoryConsultor: number): Observable<FactoryConsultorResponse> {
    let url: string = `FactoryBusiness/GetFactoryConsultor/idFactoryConsultor/${idFactoryConsultor}`;

    return this.httpClient.get<FactoryConsultorResponse>(environment.urlApiBusinessUser + url, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAllInvitesReceivedByFactory(): Observable<InviteFactoryConsultorConnectionResponse> {
    let url: string = `FactoryBusiness/GetAllInvitesReceivedByFactory`;

    return this.httpClient.get<InviteFactoryConsultorConnectionResponse>(environment.urlApiBusinessUser + url, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchPagedForFactoriesByConsultor(request: SearchFactoriesByConsultorRequest): Observable<SearchFactoriesByConsultorResponse> {
    return this.httpClient.post<SearchFactoriesByConsultorResponse>(environment.urlApiBusinessUser + "FactoryBusiness/SearchPagedForFactoriesByConsultor", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public sendFactoryResponseToInviteFactoryConsultor(request: ResponseToInviteFactoryConsultorConnectionRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessUser + "FactoryBusiness/SendFactoryResponseToInviteFactoryConsultor", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createInviteConsultorToFactory(request: CreateInviteConsultorToFactoryRequest): Observable<FactoryConsultorResponse> {
    return this.httpClient.post<FactoryConsultorResponse>(environment.urlApiBusinessUser + "FactoryBusiness/CreateInviteConsultorToFactory", request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteFactoryConsultor(idFactoryConsultor: number): Observable<ReturnStruct> {
    let url: string = `FactoryBusiness/DeleteFactoryConsultor/idFactoryConsultor/${idFactoryConsultor}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBusinessUser + url, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}
