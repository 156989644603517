import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { UserUnauthorizedService } from '../../../shared/services/API/business-user/user-unauthorized.service';
import { UserForFirstLoginResponse } from '../../../shared/services/responses/business-user/user-for-first-login.response';
import { UserForFirstLoginUpdateRequest } from '../../../shared/services/requests/business-user/user-for-first-login-update.request';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';

@Component({
  selector: 'first-login-to-user-store',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  templateUrl: './first-login-to-user-store.component.html',
  styleUrl: './first-login-to-user-store.component.css'
})
export class FirstLoginToUserStoreComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private userUnauthorizedService: UserUnauthorizedService,
    private activatedRoute: ActivatedRoute,) { }

  public model: FormGroup;
  public isLoading: boolean = false;
  public _idUser: number;

  hidePassword = signal(true);
  hidePasswordConfirm = signal(true);

  clickEventHidePassword(event: MouseEvent) {
    this.hidePassword.set(!this.hidePassword());
    event.stopPropagation();
  }

  clickEventHidePasswordConfirm(event: MouseEvent) {
    this.hidePasswordConfirm.set(!this.hidePasswordConfirm());
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.createDefaultInstanceOfForm();

    this.tryGetUrlIdUser();

    this.getUserForFirstLogin();
  }

  createDefaultInstanceOfForm(): void {
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      businessStoreName: [{ value: '', disabled: true }],
      email: [{ value: '', disabled: true }],
      phone: ['', [Validators.required]],
      password: ['', [Validators.required]],
      passwordConfirm: ['', [Validators.required]],
    });
  }

  tryGetUrlIdUser(): void {
    let idUserUrl: string = this.activatedRoute.snapshot.params["idUser"];

    if (idUserUrl)
      this._idUser = Number(idUserUrl);
  }

  getUserForFirstLogin(): void {
    if (!this._idUser) {
      this.backToAuthLoginPage();
      return;
    }

    this.userUnauthorizedService.getUserForFirstLogin(this._idUser).subscribe({
      next: (response: UserForFirstLoginResponse) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.populateUserDataForm(response);
        this.isLoading = false;

      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  populateUserDataForm(response: UserForFirstLoginResponse): void {
    this.model.get('name')?.setValue(response.name);
    this.model.get('businessStoreName')?.setValue(response.businessStoreName);
    this.model.get('email')?.setValue(response.email);
    this.model.get('phone')?.setValue(response.phone);
  }

  updateUserForFirstLogin() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show("Erro", "Preencha todos os campos em vermelho", AlertType.error);
      return;
    }

    if(this.model.get('password')?.value != this.model.get('passwordConfirm')?.value){
      this.alertService.show("Erro", "As duas senhas precisam ser identicas", AlertType.error);
      return;
    }

    let request: UserForFirstLoginUpdateRequest = new UserForFirstLoginUpdateRequest();
    request.idUser = this._idUser;
    request.name = this.model.get('name')?.value;
    request.email = this.model.get('email')?.value;
    request.phone = this.model.get('phone')?.value;
    request.password = this.model.get('password')?.value;

    this.isLoading = true;
    this.userUnauthorizedService.updateUserForFirstLogin(request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show("Sucesso", "Conta criada com sucesso.", AlertType.success);
        this.isLoading = false;
        this.backToAuthLoginPage();
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  backToAuthLoginPage(): void {
    if (this.isLoading)
      return;

    this.router.navigate(['/']);
  }


}
