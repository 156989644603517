import { Component, Input } from '@angular/core';
import { FactoryInventoryProductStruct } from '../../../../shared/services/structs/inventory/factory-inventory-product.struct';
import { LoadingBoxComponent } from "../../../../shared/component/loading-box/loading-box.component";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-inventory-item',
  standalone: true,
  imports: [
    LoadingBoxComponent,
    CommonModule,
    MatButtonModule,
    RouterLink
  ],
  templateUrl: './inventory-item.component.html',
  styleUrl: './inventory-item.component.css'
})
export class InventoryItemComponent {
  @Input() factoryInventoryProductStruct:FactoryInventoryProductStruct
}
